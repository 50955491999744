<template>
<div v-if="form">
    <v-toolbar dense flat class="light-blue darken-4" dark>
        <v-btn text class="white" color="black" :to="{name:'Laboratorios paciente'}">
            <v-icon>mdi-chevron-left</v-icon>
            regresar
        </v-btn>
        <div class="flex-grow-1"></div>
        <v-toolbar-title>Panel de laboratorios</v-toolbar-title>
    </v-toolbar>
    <v-card class="pa-2" outlined tile>
        <v-container>

            <v-row class="caption" no-gutters justify="center">
                <v-col cols="12" sm="12" md="2">
                    <label class="primary--text">FECHA: </label>

                    <label class="font-weight-black">{{ form.cifaFecha}}</label>
                </v-col>
                <v-col cols="12" sm="12" md="2" v-if="form.tblCitaCita">
                    <label class="primary--text">PACIENTE: </label>

                    <label class="font-weight-black" v-if="form.tblCitaCita.tblConfPersonaspacientes.length > 0">{{ form.tblCitaCita.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnNombre1 + " " +form.tblCitaCita.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido1 }}</label>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                    <v-btn outlined class="mx-2" small dark color="info" v-if="DEFAULT != 'patologia'" @click="pdf(null)">
                        <v-icon dark>mdi-file-pdf-box</v-icon> Pdf
                    </v-btn>
                </v-col>
            </v-row>

        </v-container>
    </v-card>
    <v-card class="pa-2 " color="grey lighten-2" outlined tile>
        <v-container>
            <v-row justify="space-around">
                <v-col cols="12" sm="12" md="6" style="min-heigth:80px" v-for="(item, i) in form.tblCitaOrdenes" :key="i">
                    <v-sheet min-height="100%" width="100%">
                        <v-container fluid class="pa-0">
                            <v-row align="center" no-gutters class="text-center" style="min-height:80px">

                                <v-col cols="12" sm="12" md="1">
                                    <span>
                                        <v-icon>mdi-format-color-fill</v-icon>
                                    </span>
                                </v-col>
                                <v-divider light class="mx-1" vertical></v-divider>
                                <v-col cols="12" sm="12" md="8">
                                    <span>{{item.tblConfCups[0].cocuNombre}}</span>
                                </v-col>
                                <v-divider light class="mx-1" vertical v-if="DEFAULT == 'patologia'"></v-divider>
                                <v-col cols="12" sm="12" md="2" v-if="DEFAULT == 'patologia'">
                                    <v-btn icon class="mx-2" x-small dark color="info"  @click="pdf(item)">
                                        <v-icon dark>mdi-file-pdf-box</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
    <v-snackbar v-model="notificacion.show" color="warnning" :multi-line="true">
        {{ notificacion.mensaje }}
    </v-snackbar>
</div>
</template>

<script>
import {
    documentos
}
from "../../utils/documentos";

import jsPDF from 'jspdf';
import 'jspdf-autotable';


export default {
    name: 'Entregapanel',
    components: {

    },
    data() {
        return {
            tab: "tab-1",
            dialog: false,
            url: "cita/citas/",
            facturado: false,
            token: this.$cookies.get("token"),
            
            user: this.$cookies.get("user"),
            form: null,
            DEFAULT: this.$cookies.get("DEFAULT"),
            menu: false,
            actual: 0,
            cantidad: 0,
            cireId: null,
            cantidadPendiente: 0,
            meses: ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"],
            notificacion: {
                show: false,
                mensaje: ''
            },
            secciones: [],
            campos: [],
            diagnosticos: [],
            Historia: [],
            historial: [],

        }
    },

    created: async function () {
        
        

        if (this.$route.params.id) {

            if (this.$cookies.get('user').role == 'PACIENTE') {
                this.$store.commit('setCargaDatos', true);
                this.form = await this.$apiService.index("cita/facturaciones/pdf/" + this.$route.params.id).then(data => data);

                this.isNew = false;

                this.$store.commit('setCargaDatos', false);
            }

        }

    },

    methods: {
        allowedDates: val => new Date(val.replace(/-+/g, '/')).getDay() != 0 && new Date(val.replace(/-+/g, '/')).getDay() != 6,
        currentDate(date) {
            const fecha = date;
            if (this.allowedDates(fecha.yyyymmdd())) {
                return fecha.yyyymmdd()
            } else {
                fecha.setDate(fecha.getDate() + 1);
                return this.currentDate(fecha);

            }
        },

        async pdf(item) {

            await this.dibujarH(item);

        },
        async dibujarH(item) {
            this.Historia = [];
            this.secciones = [];
            this.campos = [];
            this.diagnosticos = [];
            let datosPaciente = null;
            if (this.DEFAULT == 'patologia') {
                 datosPaciente = await this.$apiService.index("cita/citas/view/factura/" + this.form.citaId).then(data => data);
                this.diagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + this.form.cifaId + "/diagnostico").then(data => data);
                this.secciones = await this.$apiService.index("sistema/formulariosecciones/secciones/" + item.cocuId + "?LABORATORIO").then(data => data);
                this.Historia = await this.$apiService.index("sistema/formulariosfacturaciones/index/" + item.ciorId + "?tipo=orden").then(data => data);
            } else {
                 datosPaciente = await this.$apiService.index("cita/citas/view/factura/" + this.form.citaId).then(data => data);
                this.diagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + this.form.cifaId + "/diagnostico").then(data => data);

                for (let index = 0; index < datosPaciente.tblCitaFacturaciones[0].tblCitaOrdenes.length; index++) {
                    let orden = datosPaciente.tblCitaFacturaciones[0].tblCitaOrdenes[index];
                    let s = await this.$apiService.index("sistema/formulariosecciones/secciones/" + orden.cocuId + "?LABORATORIO").then(data => data);
                    this.secciones.push({ ciorId: orden.ciorId, secciones: s });
                    let h = await this.$apiService.index("sistema/formulariosfacturaciones/index/" + orden.ciorId + "?tipo=orden").then(data => data);
                    this.Historia.push({ ciorId: orden.ciorId, Historia: h });

                }
            }

            if (this.Historia.length > 0) {
                if (this.DEFAULT == 'patologia') {
                    this.secciones.forEach((seccion) => {
                        if (seccion.campos.length > 0) {
                            seccion.campos.sort((a, b) => a.foseOrden - b.foseOrden);
                            seccion.campos.forEach(campo => {
                                if (this.Historia.find(e => e.foseId == campo.foseId) == undefined) {

                                    this.Historia.push({
                                        ciffId: null,

                                        ciffValor: null,

                                        foseId: campo.foseId
                                    });
                                }
                            });
                        }

                    });
                }
                if (typeof window !== 'undefined') {
                    let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("DEFAULT"));
                    let pdf = new jsPDF();
                    if (this.DEFAULT != 'patologia') {
                        documento.getLaboratorio(pdf, datosPaciente, this.Historia, this.secciones, this.diagnosticos);
                    } else {
                        documento.getDocumento(pdf, datosPaciente, this.Historia, this.secciones, this.diagnosticos);
                    }

                }

            } else {
                this.$swal.fire({
                    title: "Historia no tramitada!",
                    text: "primero debe ser llenado la historia",
                    icon: "error"
                });
            }

        },
       

    }
}
</script>
