<style lang="css" scoped>
</style>
<template>
    <div>
         <v-data-table :headers="Headers" :items-per-page="10"  :items="lista" item-key="copoId" :single-expand="true" show-expand sort-by="copoNombre"  class="elevation-1">
            <template v-slot:top>
                <Dfiltros :onBuscar="buscar" v-bind:filtros="filtros" />
            </template>  
            <template v-slot:expanded-item="{ headers,item }">
                    <td :colspan="headers.length">
                        <template v-for="items in item.tblConfPaquetescups ">
                             <v-row no-gutters class="grey lighten-3 mb-5 my-2 py-2 px-2" v-bind:key="items.tblConfCup.cocuId">
                                    <v-col cols="12" sm="12" md="12" lg="12">
                                        CODIGO <span class="primary--text">
                                            {{ items.tblConfCup.cocuCups }}</span> ,
                                        NOMBRE <span class="primary--text">
                                            {{ items.tblConfCup.cocuNombre}}
                                        </span>
                                    </v-col>
                                </v-row>
                        </template>
                    </td>
                </template>   
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn @click="onEscojerPaquete(item,tipo)"  outlined color="primary"  class="mx-2" small>
                    <v-icon>mdi-check</v-icon>
                </v-btn>
            </template>
        </v-data-table>
    </div>
</template>
<script>
export default {
    name: 'Paquetes',
    props:{
        onEscojerPaquete: Function,
        tipo: String,
    },
    data() {
        return {
            url: "sistema/paquetesordenes/",
            user: this.$cookies.get("user"),
            token: this.$cookies.get("token"),  
            filtros: [{
                    text: "Nombre",
                    value: null,
                    tipo: "text",
                    search: true,
                    isLoading: false
                    /*si es tipo lista descomentar esta parte*/
                    /* lista: [],
                          listatext: "nombre del campo que se mostrara",
                          listavalue: "nombre del valor que se mandara"*/
                    //para select y items personalizados
                    /*  default: true,
                          defaulttext: "tblConfPersonasnaturales",
                          defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
                          defaultsubtext: "copeIdentificacion",*/
                },
                {
                    text: "Tipo",
                    value: null,
                    tipo: "lista",
                    /*si es tipo lista descomentar esta parte*/
                    lista: [],
                    listatext: "comaNombrelargo",
                    listavalue: "comaId"
                    //para select y items personalizados
                    /*  default: true,
                          defaulttext: "tblConfPersonasnaturales",
                          defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
                          defaultsubtext: "copeIdentificacion",*/
                }
            ], 
            lista: [],
            listaCopia: [],
            Headers:[
                {
                    text: 'Nombre',
                    value: 'copoNombre',
                },
                {
                    text: 'Tipo',
                    value: 'tipo.comaNombrelargo',
                },
                { text: 'Opciones', value: 'actions', sortable: false },
            ],
            tipos:[]
        };
    },
    created: async function () { 
            this.filtros[1].lista = await this.$apiService.index("sistema/maestra/index/TBL_TIPOPAQUETES").then(data => data);
            await this.buscar(this.filtros);
    },  
    methods: {
        async search(searchP) {
            this.$store.commit("setCargaDatos", true);
            const data = await this.$apiService.index(`${this.url}index?${searchP}`).then(data => data);
            if (data) {
                this.lista = this.listaCopia = data;
            }
            this.$store.commit("setCargaDatos", false);
        },
        async buscar(filtros) {
            this.filtros = filtros;
            this.lista = this.listaCopia;
            var search = `&`;
            if (
                this.filtros[0].value != null &&
                this.filtros[0].value.toString().replace(/ /g, "") != ""
            ) {
                search = search + `copoNombre=${this.filtros[0].value}&`;
            }   
            if (
                this.filtros[1].value != null &&
                this.filtros[1].value.toString().replace(/ /g, "") != ""
            ) {
                search = search + `copoTipoPaquete=${this.filtros[1].value}&`;
            } 
            if (search != "") {
                await this.search(search);
            }
        }
    }
}
</script>
