<template>
    <div>
        <v-container>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="12" md="12">
                    <v-alert text prominent dense type="info" v-if="mensaje">
                        {{mensaje}}
                    </v-alert>
                    <v-card class="text-center" v-else>
                        <v-col cols="12" sm="12" md="12" class="text-center">
                            <span class="headline green--text">GRAFICAS</span>
                        </v-col>
                        <template v-if="edad <= 3650">
                            <h3>PESO PARA LA EDAD DE {{ tituloPesoEdad }}</h3>
                            <div id="GraficaPesoPorEdad">
                            </div>
                        </template>
                        <template v-if="edad <= 1825">
                            <h3>PESO PARA LA TALLA/ALTURA DE 0 A 5 AÑOS</h3>
                            <div id="GraficaPesoPorAltura">
                            </div>
                        </template>
                        <template v-if="edad <= 6569">
                            <h3>TALLA PARA LA EDAD DE {{ tituloTallaEdad }}</h3>
                            <div id="GraficaAlturaPorEdad">
                            </div>
                        </template>
                        <template v-if="edad <= 6569">
                            <h3>IMC PARA LA EDAD {{ tituloIMC }}</h3>
                            <div id="GraficaBMIPorEdad">
                            </div>
                        </template>
                        <template v-if="edad <= 1825">
                            <h3>CIRCUFERENCIA DE LA CABEZA PARA LA EDAD DE {{tituloCircu}}</h3>
                            <div id="GraficaCircuferenciaCabezaPorEdad">
                            </div>
                        </template>
                        <template v-if="factura.tblCitaCita.tblConfContratosxservicios[0].tblConfServicios[0].coseNombre=='ATENCIÓN PRENATAL'">
                            <h3>IMC GESTANTES</h3>
                            <div id="GraficaIMCPorGestacion">
                            </div>
                            <h3>ALTURA UTERINA</h3>
                            <div id="GraficaIAlturaUterina">
                            </div>
                        </template>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { graficas } from '../../utils/graficas';
export default {
  name: 'Graficas',
  props: {
    factura: Object
  },
  data: function() {
    return {
      mensaje: null,
      edad: null,
      user: this.$cookies.get("user"),
      token: this.$cookies.get("token"),
      graficas:null,
      tituloPesoEdad:"",
      tituloTallaEdad:"",
      tituloIMC:"",
      tituloCircu:"",
    }
  },
  mounted: async function() {
    this.graficas = new graficas();
    let reportes = await this.$apiService.index('reporte/reportes/index?offset=0&coreNombre=HISTORIAL_PRIMERA_INFANCIA').then(data => data);
    this.edad = (new Date(this.factura.cifaFecha.replace(/-+/g, '/')).getTime() - new Date(this.factura.tblCitaCita.tblConfPersonaspacientes[0].coppFechanacimiento.replace(/-+/g, '/')).getTime()) / 86400000;
    if (reportes) {
      let r = reportes.rows[0];
      r.tblConfReportesparametros.forEach(async o => {
        o.Valor = this.factura.tblCitaCita.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion;
      });
      let pesos = await this.$apiService.create("reporte/reportes/ejecutar/", r).then(data => data);
      pesos = pesos.filter(e => new Date(e.fecha.replace(/-+/g, '/')).getTime() <= new Date(this.factura.cifaFecha.replace(/-+/g, '/')).getTime());
      pesos.sort((a, b) => new Date(b.fecha.replace(/-+/g, '/')).getTime() - new Date(a.fecha.replace(/-+/g, '/')).getTime());
      let sexo = this.factura.tblCitaCita.tblConfPersonaspacientes[0].sexo.comaNombrecorto;
      let dataSource = await this.$apiService.index(`sistema/historiagraficas/index?sexo=${sexo}`).then(data => data);
      dataSource = dataSource.filter(e => e.cogpEdadMin <= this.edad && e.cogpEdadMax >= this.edad );
      dataSource = dataSource.map((item) =>{  return {...item , data:JSON.parse(item.cogpData)}});
      if (this.edad <= 6569) {
        if (this.edad <= 3650) {
          let pesoEdadCondicion = this.edad < 731 ? (e)=> e.data.mes <= 24 :(e)=> e.data.mes >= 24;
          let semanaomes = this.edad < 92 ? "semana":"mes";
          let pesoEdadCodigo = `PESO_EDAD_${this.sexo=='M'?'BOYS':'GIRLS'}_${this.edad < 92 ? "_0_TO_13S":this.edad < 731 ?"0_TO_5":"5_TO_10"}`;
          this.tituloPesoEdad=this.edad < 92 ? "0 A 13 semanas":this.edad < 731 ? "0 A 2 AÑOS":this.edad < 3650 ?"2 A 5 AÑOS":"5 A 10 AÑOS";
          let dataPeso02 = dataSource.filter(e => e.cogpCodigo == pesoEdadCodigo && pesoEdadCondicion(e)).map(item => item.data);
          let optionsPesoEdad = {
              id:"GraficaPesoPorEdad",
              titleX:"EDAD(MES)",
              titleY:"PESO(KG)",
              dataSource:dataPeso02,
              data:pesos,
              lines:[
                  {label:"3",color:"black",x:semanaomes,y:"3"},
                  {label:"2",color:"red",x:semanaomes,y:"2"},
                  {label:"1",color:"yellow",x:semanaomes,y:"1"},
                  {label:"0",color:"green",x:semanaomes,y:"0"},
                  {label:"-1",color:"yellow",x:semanaomes,y:"-1"},
                  {label:"-2",color:"red",x:semanaomes,y:"-2"},
                  {label:"-3",color:"black",x:semanaomes,y:"-3"},
                  {label:"peso",color:"purple",x:semanaomes,y:"peso",lineadata:true},
              ]
          }
          await this.graficas.getGraficaLines(null, optionsPesoEdad);
        }

          
        if (this.edad <= 1825) {
          let dataPesoAltura05 = dataSource.filter(e => e.cogpCodigo == `PESO_TALLA_${sexo=='M'?'BOYS':'GIRLS'}_0_TO_2` || e.cogpCodigo == `PESO_ALTURA_${sexo=='M'?'BOYS':'GIRLS'}_2_TO_5`).map(item => item.data);
          let options = {
              id:"GraficaPesoPorAltura",
              titleX:"TALLA/ALTURA(CM)",
              titleY:"PESO(KG)",
              dataSource:dataPesoAltura05,
              data:pesos,
              lines:[
                  {label:"3",color:"black",x:"altura",y:"3"},
                  {label:"2",color:"red",x:"altura",y:"2"},
                  {label:"1",color:"yellow",x:"altura",y:"1"},
                  {label:"0",color:"green",x:"altura",y:"0"},
                  {label:"-1",color:"yellow",x:"altura",y:"-1"},
                  {label:"-2",color:"red",x:"altura",y:"-2"},
                  {label:"-3",color:"black",x:"altura",y:"-3"},
                  {label:"peso",color:"purple",x:"talla",y:"peso",lineadata:true},
              ]
          } 
          await this.graficas.getGraficaLines(null, options);
          let circuCondicion = this.edad < 731 ? (e)=> e.data.mes <= 24 :(e)=> e.data.mes <= 60;
          semanaomes = this.edad < 92 ? "semana":"mes";
          let circuCodigo = `CIRCUFERENCIA_EDAD_${sexo=='M'?'BOYS':'GIRLS'}_${this.edad < 92 ? "_0_TO_13S":"0_TO_5"}` ;
          this.tituloCircu=this.edad < 92 ? "0 A 13 semanas":this.edad < 731 ? "0 A 2 AÑOS":"0 A 5 AÑOS";
          let dataCircu = dataSource.filter(e => e.cogpCodigo == circuCodigo && circuCondicion(e)).map(item => item.data);
          let optionsCircu = {
              id:"GraficaCircuferenciaCabezaPorEdad",
              titleX:"EDAD(MES)",
              titleY:"CIRCUFERENCIA CABEZA(CM)",
              dataSource:dataCircu,
              data:pesos,
              lines:[
                  {label:"3",color:"black",x:semanaomes,y:"3"},
                  {label:"2",color:"red",x:semanaomes,y:"2"},
                  {label:"1",color:"yellow",x:semanaomes,y:"1"},
                  {label:"0",color:"green",x:semanaomes,y:"0"},
                  {label:"-1",color:"yellow",x:semanaomes,y:"-1"},
                  {label:"-2",color:"red",x:semanaomes,y:"-2"},
                  {label:"-3",color:"black",x:semanaomes,y:"-3"},
                  {label:"circuferencia cabeza",color:"purple",x:semanaomes,y:"perimetrocefalico",lineadata:true},
              ]
          }
          await this.graficas.getGraficaLines(null, optionsCircu);
        }
    
        let tallaEdadCodigo = `TALLA_EDAD_${sexo=='M'?'BOYS':'GIRLS'}_${this.edad < 731 ? "0_TO_2":this.edad < 1825 ?"2_TO_5":"5_TO_19"}` ;
        this.tituloTallaEdad=this.edad < 731 ? "0 A 2 AÑOS":this.edad < 1825 ?"2 A 5 AÑOS":"5 A 19 AÑOS";
        let dataTallaEdad02 = dataSource.filter(e => e.cogpCodigo == tallaEdadCodigo).map(item => item.data);
        let optionsTallaEdad = {
            id:"GraficaAlturaPorEdad",
            titleX:"EDAD(MES)",
            titleY:"TALLA(CM)",
            dataSource:dataTallaEdad02,
            data:pesos,
            lines:[
                {label:"3",color:"black",x:"mes",y:"3"},
                {label:"2",color:"red",x:"mes",y:"2"},
                {label:"1",color:"yellow",x:"mes",y:"1"},
                {label:"0",color:"green",x:"mes",y:"0"},
                {label:"-1",color:"yellow",x:"mes",y:"-1"},
                {label:"-2",color:"red",x:"mes",y:"-2"},
                {label:"-3",color:"black",x:"mes",y:"-3"},
                {label:"talla",color:"purple",x:"mes",y:"talla",lineadata:true},
            ]
        }
        await this.graficas.getGraficaLines(null, optionsTallaEdad);
        this.tituloIMC = this.edad < 92 ? "DE 0 A 13 SEMANAS":this.edad < 731 ? "DE 0 A 2 AÑOS":this.edad < 1825 ?"DE 2 A 5 AÑOS":"DE 5 A 19 AÑOS";
        let semanaomesCodigo = `BMI_EDAD_${sexo=='M'?'BOYS':'GIRLS'}_${this.edad < 92 ? "0_TO_13S":this.edad < 731 ? "0_TO_2":this.edad < 1825 ?"2_TO_5":"5_TO_19"}` ;
        let dataIMCEdad = dataSource.filter(e => e.cogpCodigo == semanaomesCodigo).map(item => item.data);
        let semanaomes = this.edad < 92 ? "semana":"mes";
        let options = {
            id:"GraficaBMIPorEdad",
            titleX:"EDAD(MES)",
            titleY:"IMC(KG/M^2)",
            dataSource:dataIMCEdad,
            data:pesos,
            lines:[
                {label:"3",color:"black",x:semanaomes,y:"3"},
                {label:"2",color:"red",x:semanaomes,y:"2"},
                {label:"1",color:"yellow",x:semanaomes,y:"1"},
                {label:"0",color:"green",x:semanaomes,y:"0"},
                {label:"-1",color:"yellow",x:semanaomes,y:"-1"},
                {label:"-2",color:"red",x:semanaomes,y:"-2"},
                {label:"-3",color:"black",x:semanaomes,y:"-3"},
                {label:"imc",color:"purple",x:semanaomes,y:"imc",lineadata:true},
            ]
        }
        await this.graficas.getGraficaLines(null, options);
      }
      if (this.factura.tblCitaCita.tblConfContratosxservicios[0].tblConfServicios[0].coseNombre == 'ATENCIÓN PRENATAL') {
        let dataIMCGestacion = dataSource.filter(e => e.cogpCodigo == "IMC_GESTACIONAL").map(item => item.data);
        let optionsIMCGestacion = {
            id:"GraficaIMCPorGestacion",
            titleX:"SEMANAS DE GESTACION",
            titleY:"IMC(KG/M^2)",
            dataSource:dataIMCGestacion,
            data:pesos.filter(e => e.SERVICIO == 'ATENCIÓN PRENATAL'),
            lines:[
                
                {label:"OBESIDAD",color:"orange",x:"semana",y:"OBESIDAD",tipo:"area"},
                {label:"SOBREPESO",color:"yellow",x:"semana",y:"SOBREPESO",tipo:"area"},
                {label:"ADECUADO",color:"green",x:"semana",y:"ADECUADO",tipo:"area"},
                {label:"BAJO PESO",color:"pink",x:"semana",y:"BAJO PESO",tipo:"area"},
                {label:"imc",color:"purple",x:"semana",y:"imc",lineadata:true},
            ]
        }
        await this.graficas.getGraficaArea(null, optionsIMCGestacion);

        let dataAlturaUterina = dataSource.filter(e => e.cogpCodigo == 'ALTURA_UTERINA_GIRLS_24_TO_42S').map(item => item.data);
            let semanaomes =  "semana";
            let optionsAltU = {
                id:"GraficaIAlturaUterina",
                titleX:"EDAD GESTACIONAL(SEMANAS)",
                titleY:"ALTURA UTERINA(CM)",
                dataSource:dataAlturaUterina,
                data:pesos.filter(e => e.SERVICIO == 'ATENCIÓN PRENATAL'),
                lines:[
                    {label:"p90",color:"red",x:semanaomes,y:"p90"},
                    {label:"p50",color:"black",x:semanaomes,y:"p50"},
                    {label:"p10",color:"blue",x:semanaomes,y:"p10"},
                    {label:"alturau",color:"purple",x:semanaomes,y:"alturau",lineadata:true},
                ]
            }
            await this.graficas.getGraficaLines(null, optionsAltU);
      }
    }
  }
}

</script>

<style>
</style>
