<style>
</style>
<template>
<v-container lighten-5>
    <v-row>
        <v-col cols="12" sm="12" md="12">
            <span class="mx-2 secondary--text display-2 float-left">Atenciones historico</span>
        </v-col>
        <v-col cols="12" sm="12" md="12">
            <v-btn class="mx-2 float-right" fab small dark color="accent" @click="verFiltros=!verFiltros">
                <v-icon dark>filter_list</v-icon>
            </v-btn>
        </v-col>
        <v-col cols="12" sm="12" md="12" v-if="tabla">
        <v-card elevation="0" class="rounded-xl">
            <v-data-iterator :items="lista" :page.sync="page" :loading="loadingTable" :item-key="tabla.itemKey" :items-per-page="10" single-expand hide-default-footer :sort-by="tabla.orderBy" :sort-desc="true">
                <template v-slot:header>
                    <Dfiltros v-if="verFiltros" :onBuscar="search" v-bind:filtros="filtros" />
                    <v-toolbar dark class="mb-1">
                    </v-toolbar>
                </template>
                <template v-slot:default="{ items, isExpanded, expand }">
                    <v-container class="pt-0">
                        <v-row align="center" justify="center" :class="($vuetify.theme.dark)?'grey darken-4':'grey lighten-3'">
                            <v-col v-for="item in items" :key="item.comaId" cols="12" sm="12" md="10" lg="10">
                                <v-card elevation="0" class="rounded-xl">
                                    <v-card-title :class="`${($vuetify.breakpoint.mdAndUp)?'subtitle-1':'subtitle-2'}  font-weight-bold`">
                                        <v-row no-gutters>
                                            <v-col cols="10" sm="10" md="10" lg="10">
                                                <v-row no-gutters>
                                                    <template v-for="(p,i) in tabla.primarios">
                                                        <v-col v-if="p.show(item)" :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                            {{p.titulo}} <span :class="p.class">
                                                                {{ p.valor(item) }}
                                                            </span>
                                                        </v-col>
                                                    </template>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="2" sm="2" md="2" lg="2">
                                                <v-menu bottom left>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn icon outlined v-bind="attrs" v-on="on">
                                                            <v-icon class="material-icons-two-tone">more_vert</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <v-list>
                                                        <template v-for="(m, i) in tabla.menu">
                                                            <template v-if="m.show(item)">
                                                                <v-list-item v-if="m.tipeAction=='link'" :key="i" :to="m.action(item)">
                                                                    <v-list-item-icon>
                                                                        <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                    </v-list-item-icon>
                                                                    <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item v-else :key="i" @click="m.action(item)">
                                                                    <v-list-item-icon>
                                                                        <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                    </v-list-item-icon>
                                                                    <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                </v-list-item>
                                                            </template>
                                                        </template>
                                                    </v-list>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-text>
                                        <v-row no-gutters>
                                            <template v-for="(p,i) in tabla.segundarios">
                                                <v-col v-if="p.show(item)" :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                    {{p.titulo}} <span :class="p.class">
                                                        {{ p.valor(item) }}
                                                    </span>
                                                </v-col>
                                            </template>
                                            <v-col cols="12" sm="12" md="12" lg="12" v-if="tabla.expandible">
                                                <v-row>
                                                    <v-col cols="2" sm="2" md="1" lg="1">
                                                        <v-btn color="grey" icon outlined @click="() => expand(item, !isExpanded(item))">
                                                            <v-icon v-if="!isExpanded(item)">mdi-chevron-down</v-icon>
                                                            <v-icon v-else>mdi-chevron-up</v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col cols="10" sm="10" md="11" lg="11">
                                                        <span class="headline primary--text">{{tabla.expandibleTitle(item)}}</span>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <template v-if="tabla.expandible">
                                        <v-divider v-if="isExpanded(item)"></v-divider>
                                        <v-card-text v-if="isExpanded(item)">
                                            <v-container>
                                                <template v-for="items in tabla.expandibleItems(item) ">
                                                    <v-sheet min-height="100%" width="100%" :key="items[tabla.expandibleKey]">
                                                        <v-row no-gutters :class="`${($vuetify.theme.dark)?'grey darken-4':'grey lighten-3'} mb-5 py-2 px-2`" >
                                                            <template v-for="(p,i) in tabla.expanItem">
                                                                <v-col :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                                    {{p.titulo}} <span :class="p.class">
                                                                        {{ p.valor(items) }}
                                                                    </span>
                                                                </v-col>
                                                            </template>
                                                            <v-col cols="2" sm="2" md="2" lg="2" v-if="tabla.expanMenu.length > 0">
                                                                <v-menu bottom left>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn icon outlined v-bind="attrs" v-on="on">
                                                                            <v-icon class="material-icons-two-tone">more_vert</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <v-list>
                                                                        <template v-for="(m, i) in tabla.expanMenu">
                                                                            <template v-if="m.show(items)">
                                                                                <v-list-item v-if="m.tipeAction=='link'" :key="i" :to="m.action(items)">
                                                                                    <v-list-item-icon>
                                                                                        <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                                    </v-list-item-icon>
                                                                                    <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                                </v-list-item>
                                                                                <v-list-item v-else :key="i" @click="m.action(items)">
                                                                                    <v-list-item-icon>
                                                                                        <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                                    </v-list-item-icon>
                                                                                    <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                                </v-list-item>
                                                                            </template>
                                                                        </template>
                                                                    </v-list>
                                                                </v-menu>
                                                            </v-col>
                                                        </v-row>
                                                    </v-sheet>
                                                </template>
                                            </v-container>
                                        </v-card-text>
                                    </template>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </template>
                <template v-slot:no-data>
                    <v-row class="mt-2" align="center" justify="center">
                        <v-col cols="10" sm="10" md="10" lg="10">
                            <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                                No se encontro nada.
                            </v-alert>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:loading>
                    <v-row class="mt-2" align="center" justify="center">
                        <v-col cols="12" sm="12" md="12" lg="12">
                            <Loader />
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:footer>
                    <Paginacion v-on:cambiarPagina="page = $event" :onSearch="search" v-bind:propiedades="{filtros,page,pageCount,Count}" />
                </template>
            </v-data-iterator>
        </v-card>
        </v-col>
        <v-dialog v-model="dialogInasistencia" width="800px" max-width="800px">
            <v-card>
            <v-card-title class="text-h5 green lighten-2">CITA NO ATENDIDA</v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <v-textarea outlined  label="Motivos" :readonly="citaMotivoNoate.estado == 'NO_ATENDIDA'" v-model="citaMotivoNoate.citaMotivoNoate" ></v-textarea> 
                    </v-col>
                </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="citaInasistida()" v-if="citaMotivoNoate.estado != 'NO_ATENDIDA'">guardar</v-btn>
                <v-btn color="primary" text @click="dialogInasistencia = false;citaMotivoNoate={cifaId:null,citaMotivoNoate:null,estado:null}"> Cacelar </v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</v-container>
</template>

<script>
import generales from '../../mixins/generales.js';
export default {
    mixins: [generales],
    props:{
        coppId:Number,
    },
    data() {
        return {
            menu: false,
            isLoading: false,
            searchP: null,
            cancreate: false,
            VERSION:this.$cookies.get("ENTIDAD"),
            url: "cita/asistenciales/",
            dialogInasistencia:false,
            Peliminar: false,
            empleados: [],
            pacientes: [],
            servicios: [],
            servicios2: [],
            tipojornada: [],
            tipoturno: [],
            citaMotivoNoate:{
                cifaId:null,
                citaMotivoNoate:null,
                estado:null
            },
            tabla: {
                itemKey: 'citaId',
                orderBy: 'tblCitaFacturaciones[0].cifaFecha',
                expandible: false,
                expandibleKey: 'citaId',
                expandibleTitle: () => { return `` },
                expandibleItems: () => { return [] },
                primarios: [{
                        titulo: 'PACIENTE',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '12',
                            lg: '12'
                        },
                        class: 'primary--text',
                        valor: (item) => { return `${item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnNombre1} ${item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido1}` },
                        show: (item) => { return item ? true : false }
                    }, {
                        titulo: 'FECHA',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'green--text',
                        valor: (item) => { return item.citaFecha },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'IDENT.',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'EDAD',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return this.calcularEdad(item.tblConfPersonaspacientes[0].coppFechanacimiento) },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'ESTADO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.tblCitaFacturaciones[0].estado? item.tblCitaFacturaciones[0].estado.comaNombrecorto :  ''},
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'TELEFONO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeTelefono },
                        show: (item) => { return item ? true : false }
                    },

                ],
                segundarios: [
                    {
                        titulo: 'SERVICIO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '6',
                            lg: '6'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.tblConfContratosxservicios?item.tblConfContratosxservicios[0].tblConfServicios[0].coseNombre:'' },
                        show: (item) => { return item ? true : false }
                    },
                     {
                        titulo: 'EMPLEADO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '6',
                            lg: '6'
                        },
                        class: 'primary--text',
                        valor: (item) => {
                           return `${item.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnNombre1 } ${item.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnApellido1 } - ${item.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion}` 
                        },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'TELEMEDICINA',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '3',
                            lg: '3'
                        },
                        class: 'primary--text',
                        valor: (item) => {
                           return `${item.tblCitaFacturaciones[0].cifaTelemedicina?'Si':'No'}` 
                        },
                        show: (item) => { return item ? item.tblCitaFacturaciones[0].cifaTelemedicina : false }
                    },
                     {
                        titulo: 'JORNADA',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return (item.jornada) ? item.jornada.comaNombrecorto : '' },
                        show: (item) => { return item.jornada ? true : false }
                    },
                    {
                        titulo: 'TURNO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return (item.turno) ? item.cifaTipoTurno : '' },
                        show: (item) => { return item.turno ? true : false }
                    },
                    {
                        titulo: 'HORA',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return (item.citaHora) ? item.citaHora : '' },
                        show: (item) => { return item.citaHora ? true : false }
                    }
                ],
                expanItem: [],
                expanMenu: [],
                menu: [
                    {
                        title: 'Ver',
                        icon: 'mdi-desktop-mac-dashboard',
                        color: 'success',
                        tipeAction: 'link',
                        action: (item) => { return { name: 'Atencionespanel',params: { id: item.citaId} } },
                        show: (item) => { return item ? (item.tblCitaFacturaciones[0].estado.comaNombrecorto != 'NO_ATENDIDA') : false }
                    },
                  {
                        title: 'Tramitar',
                        icon: 'mdi-ballot-outline',
                        color: 'primary',
                        tipeAction: 'link',
                        action: (item) => { return { name: 'Createhistoria',params: { id: item.tblCitaFacturaciones[0].cifaId,servicio:item.tblConfContratosxservicios[0].coseId } } },
                        show: (item) => { return item ? (item.tblCitaFacturaciones[0].estado.comaNombrecorto != 'NO_ATENDIDA' && (this.cancreate || this.user.role.indexOf('ADMIN') > -1 || this.user.role.indexOf('ENFERMERA') > -1)) : false }
                    },{
                        title: 'Asistir sin tramitar',
                        icon: 'mdi-layers-outline',
                        color: 'success',
                        tipeAction: 'funcion',
                        action: (item) => {
                            this.citaAsistida(item);
                        },
                        show: (item) => { return item ? (item.tblCitaFacturaciones[0].estado.comaNombrecorto != 'NO_ATENDIDA' && item.tblCitaFacturaciones[0].estado.comaNombrecorto != 'CERRADA' && (( this.cancreate ) || this.user.role.indexOf('ADMIN') > -1 || this.user.role.indexOf('ENFERMERA') > -1)) : false }
                    },
                    {
                        title: 'Inasistir',
                        icon: 'mdi-layers-off-outline',
                        color: 'error',
                        tipeAction: 'funcion',
                        action: (item) => {
                            this.dialogInasistencia = true;
                            this.citaMotivoNoate.cifaId = item.tblCitaFacturaciones[0].cifaId;
                            this.citaMotivoNoate.estado = item.tblCitaFacturaciones[0].estado.comaNombrecorto;
                            this.citaMotivoNoate.citaMotivoNoate = item.citaMotivoNoate;
                        },
                        show: (item) => { return item ? (item.tblCitaFacturaciones[0].estado.comaNombrecorto != 'CERRADA' && (( this.cancreate ) || this.user.role.indexOf('ADMIN') > -1 || this.user.role.indexOf('ENFERMERA') > -1)) : false }
                    },
                    {
                        title: 'Vaciar',
                        icon: 'delete',
                        color: 'error',
                        tipeAction: 'funcion',
                        action: (item) => { this.eliminar(item.tblCitaFacturaciones[0].cifaId) },
                        show: (item) => { return item ? this.Peliminar : false }
                    },
                ]
            },
           filtros: [{
                    campo:"citaFecha",
                    text: "Fecha",
                    value: null,
                    tipo: "fecha",
                    menu: false,
                    searchNormal: true,
                    isLoading: false,
                    /*si es tipo lista descomentar esta parte*/
                    /* lista: [],
                    listatext: "nombre del campo que se mostrara",
                    listavalue: "nombre del valor que se mandara"*/
                },

                {
                    campo:"citaTipoEstado",
                    text: "Estado",
                    value: null,
                    tipo: "lista",
                    /*si es tipo lista descomentar esta parte*/
                    searchNormal: true,
                    isLoading: false,
                    lista: [],
                    listatext: "comaNombrelargo",
                    listavalue: "comaId"
                }, {
                    campo:"coppId",
                    text: "Paciente",
                    value: null,
                    tipo: "lista",
                    /*si es tipo lista descomentar esta parte*/
                    lista: [],
                    listatext: "tblConfPersonasnaturales[0].copnNombre1",
                    listavalue: "tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId",
                    buscar: 'paciente',
                    isLoading: false,
                    search: null,
                    //filter: (item, queryText, itemText) => (item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + item.tblConfPersonasnaturales[0].copnApellido2).indexOf(queryText.toUpperCase()) > -1 || item.copeIdentificacion.indexOf(queryText) > -1,
                    default: true,
                    defaultselect: (data) => {
                        return data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2
                    },
                    defaultbody: (data) => {
                        return {
                            title: data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2,
                            subtitle: data.item.copeIdentificacion
                        }
                    },
                },

                {
                    campo:"coprId",
                    text: "Empleado",
                    value: null,
                    tipo: "lista",
                    /*si es tipo lista descomentar esta parte*/
                    searchNormal: true,
                    isLoading: false,
                    lista: [],
                    listatext: "tblConfPersonasnaturales[0].copnNombre1",
                    listavalue: "tblConfPersonasnaturales[0].tblConfPersonasempleados[0].coprId",
                    filter: (item, queryText) => (item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + item.tblConfPersonasnaturales[0].copnApellido2).indexOf(queryText.toUpperCase()) > -1 || item.copeIdentificacion.indexOf(queryText) > -1,
                    default: true,
                    defaultselect: (data) => {
                        return data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2
                    },
                    defaultbody: (data) => {
                        return {
                            title: data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2,
                            subtitle: data.item.copeIdentificacion
                        }
                    },
                }, {
                    campo:"coseId",
                    text: "Servicio",
                    value: null,
                    tipo: "lista",
                    /*si es tipo lista descomentar esta parte*/
                    lista: [],
                    listatext: "coseNombre",
                    listavalue: "coseId",
                },
            ],
            tipoestado: [],
            tipoestadoH: [],
        };
    },
    computed: {
        formTitle() {
            return this.isNew === true ? "Nuevo" : "Actualizar";
        }
    },
    watch: {
        dialog(val) {
            val || this.close();
        }
    },
    created: async function () {
        if (this.getPermiso( "verHistorias")) {
            this.tipojornada = await this.$apiService.index("sistema/maestra/index/TBL_TIPOJORNADA").then(data => data);
            this.tipoturno = await this.$apiService.index("sistema/maestra/index/TBL_TIPOTURNO").then(data => data);
            this.tipoestadoH = await this.$apiService.index("sistema/maestra/index/ESTADOS_HISTORIAS").then(data => data);
            this.filtros[1].lista = this.tipoestado = await this.$apiService.index("sistema/maestra/index/ESTADOS_CITAS").then(data => data);
            this.filtros[3].lista = this.empleados = await this.$apiService.index("sistema/personas/index/empleados").then(data => data);
            this.filtros[4].lista = await this.$apiService.index("sistema/servicios/index/").then(data => data);
            await this.search(this.filtros);
        }
        if (this.getPermiso("eliminarHistorias")) {
            this.Peliminar = true;
        }
        if (this.getPermiso("crearHistorias")) {
            this.cancreate = true;
        }
        if (this.getPermiso("editarHistorias")) {
            this.cancreate = true;
        }
    },

    methods: {
        async search(filtros) {
            this.lista = this.listaCopia = [];
            this.loadingTable = true;
            if (!(this.user.role.indexOf('ADMIN') > -1 || this.user.role.indexOf('Administrador (IPS)') > -1 ||( this.$cookies.get("user").role.indexOf('ENFERMERA FULL') > -1) || this.VERSION == 'union')) {
                filtros[3].value = this.user.empleado;
            }
            if(this.coppId){
              filtros[2].value = this.coppId;
            }
            const data = await this.$apiService.index(`cita/asistenciales/buscar/0?${this.filtrar(filtros,this.page)}`).then(data => data);
            if (data) {
                this.lista = this.listaCopia = data.rows;
                this.Count = data.count;
                this.pageCount = Math.ceil((data.count) / 10);
            }
            this.loadingTable = false;
        },
        async citaInasistida() {
                try {
                        if (this.cancreate) {
                            let data = await this.$apiService.update("cita/citas/citaInasistida/" + this.citaMotivoNoate.cifaId + "/",{citaMotivoNoate:this.citaMotivoNoate.citaMotivoNoate}).then(data => data);
                            if (data) {
                                if(this.lista.find( e => e.tblCitaFacturaciones[0].cifaId == this.citaMotivoNoate.cifaId )){
                                     this.lista.find( e => e.tblCitaFacturaciones[0].cifaId == this.citaMotivoNoate.cifaId ).tblCitaFacturaciones[0].estado.comaNombrecorto = 'NO_ATENDIDA';
                                     this.lista.find( e => e.tblCitaFacturaciones[0].cifaId == this.citaMotivoNoate.cifaId ).tblCitaFacturaciones[0].estado.comaNombrelargo = 'HISTORIA NO ATENDIDA';
                                     this.listaCopia = this.lista;
                                }
                                this.dialogInasistencia = false;
                                this.$swal.fire({
                                    title: "Completado!",
                                    text: "Registro exitoso",
                                    icon: "success",
                                    confirmButtonText: "Ok"
                                });
                                this.citaMotivoNoate={
                                    cifaId:null,
                                    citaMotivoNoate:null,
                                    estado:null
                                };
                            }
                        }

                } catch (error) {
                    console.log(error);
                    this.$swal.fire({
                        title: "Algo sucedio!",
                        text: "contacta con el administrador",
                        icon: "error"
                    });
                }
        },
        async citaAsistida(item) {
                try {
                        if (this.cancreate) {
                            let data = await this.$apiService.update("cita/citas/citaAsistida/" + item.tblCitaFacturaciones[0].cifaId ).then(data => data);
                            if (data) {
                                await this.search(this.filtros);
                                this.$swal.fire({
                                    title: "Completado!",
                                    text: "Registro exitoso",
                                    icon: "success",
                                    confirmButtonText: "Ok"
                                });
                            }
                        }

                } catch (error) {
                    console.log(error);
                    this.$swal.fire({
                        title: "Algo sucedio!",
                        text: "contacta con el administrador",
                        icon: "error"
                    });
                }
        },
        async eliminar(id) {

            try {
                if (this.getPermiso("eliminarHistorias")) {
                    var result = await this.$swal.fire({
                        title: "Estas seguro?",
                        text: "No podras revertir esto!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Si, eliminar!"
                    }).then(result => result.value);
                    if (result) {
                        let data = await this.$apiService.delete("sistema/historiasfacturaciones/delete/" + id + "/").then(data => data);
                        if (data) {
                            this.$apiService.delete("cita/asistenciales/atender/delete/" + id + "/orden");
                            this.$apiService.delete("cita/asistenciales/atender/delete/" + id + "/diagnostico");
                            this.$apiService.delete("cita/asistenciales/atender/delete/" + id + "/recetario");
                            this.$apiService.delete("cita/asistenciales/atender/delete/" + id + "/remision");
                            this.$apiService.delete("cita/asistenciales/atender/delete/" + id + "/incapacidadcertificado?tipo=CERTIFICADO");
                            this.$apiService.delete("cita/asistenciales/atender/delete/" + id + "/incapacidadcertificado?tipo=INCAPACIDAD");
                            this.listaCopia = this.lista = this.lista.filter(
                                e => e.tblCitaFacturaciones[0].cifaId != id
                            );
                            this.$swal.fire(
                                "Eliminado!",
                                "ha sido eliminado con exito.",
                                "success"
                            );
                        }

                    }
                }
            } catch (error) {
                console.log(error);
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }

        },

    }
};
</script>
