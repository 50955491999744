<style>

</style>

<template>
<div>
    <v-container lighten-5>
        <v-data-iterator :items="lista" :page.sync="page" :items-per-page="10" hide-default-footer sort-desc sort-by="cifaFecha" class="elevation-1">
            <template v-slot:header>
                <Dfiltros :onBuscar="buscar" v-bind:filtros="filtros" />
                <v-toolbar dark color="primary darken-3" class="mb-1">
                    <v-toolbar-title class="white--text">Laboratorios</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <div class="flex-grow-1"></div>
                </v-toolbar>
            </template>
            <template v-slot:default="props">
                <v-row align="center" justify="center">
                    <v-col v-for="item in props.items" :key="item.citaId" cols="12" sm="12" md="10" lg="10">
                        <v-card>
                            <v-card-title class="subtitle-1 font-weight-bold">
                                <v-row no-gutters>

                                    <v-col cols="9" sm="9" md="9" lg="9">
                                        <v-row no-gutters>

                                            <v-col cols="12" sm="5" md="5" lg="5">
                                                PACIENTE <span class="primary--text">
                                                    {{ item.tblCitaCita.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnNombre1 + " " +item.tblCitaCita.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido1 }}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" sm="7" md="7" lg="7">
                                                FECHA <span class="green--text"> {{item.cifaFecha}}</span>
                                            </v-col>

                                            <v-col cols="12" sm="5" md="5" lg="5">
                                                IDENT. <span class="primary--text">
                                                    {{item.tblCitaCita.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion}}

                                                </span>
                                            </v-col>
                                            <v-col cols="12" sm="7" md="7" lg="7">
                                                EDAD <span class="primary--text">{{ calcularEdad(item.tblCitaCita.tblConfPersonaspacientes[0].coppFechanacimiento)}}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" sm="5" md="5" lg="5">
                                                ESTADO <span :class="(item.cifaEstadoLab != 'NO TRAMITADO')?'green--text':'red--text'">{{item.cifaEstadoLab}}</span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="3" sm="3" md="3" lg="3">
                                        <v-tooltip top v-if="(version != 'patologia' || item.tblCitaCita.citaTipo == 0) && item.cifaEstadoLab == 'TRAMITADO'">
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined color="info" class="mr-2" small v-on="on" @click="dibujarH(item)">
                                                    <v-icon>mdi-file-pdf-box</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Panel</span>
                                        </v-tooltip>
                                        <v-tooltip top v-if="(getPermiso('editarLaboratorios') || getPermiso('crearLaboratorios') || getPermiso('verLaboratorios'))">
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined color="success" class="mr-2" small v-on="on" :to="{ name: 'Ordenes',params: { id: item.cifaId} }">
                                                    <v-icon>mdi-desktop-mac-dashboard</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Panel</span>
                                        </v-tooltip>
                                         <v-tooltip top v-if="(item.cifaCopagoLab == 0 || user.role.indexOf('ADMIN') > -1 )  && (getPermiso('verLaboratorios'))">
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined color="purple" class="mr-2" small v-on="on" @click="orden = item; dialog = true; copago = item.cifaCopagoLab">
                                                    <v-icon>mdi-currency-usd</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Copago</span>
                                        </v-tooltip>
                                        <v-tooltip top v-if="item.cifaEstadoLab == 'NO TRAMITADO' && (getPermiso('editarLaboratorios') || getPermiso('crearLaboratorios'))">
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined color="success" class="mr-2" small v-on="on" @click="cambiarEstado(item)">
                                                    <v-icon>mdi-package-variant-closed-check</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Cerrar</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-row no-gutters>
                                    <v-col cols="12" sm="12" md="3" lg="3">
                                            EPS <span class="primary--text">
                                                <template v-if="item.tblConfBasedatospacientes.length > 0">
                                                    {{ item.tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemNombre }}
                                                </template>
                                                <template v-else-if="item.cocoId">
                                                    {{ item.tblConfContrato.tblConfPersonasempresas[0].coemNombre }}
                                                </template>
                                            </span>
                                        </v-col>
                                    <v-col cols="12" sm="12" md="4" lg="4" v-if="item.tblCitaCita.tblConfPersonasempleados[0]">
                                        EMPLEADO
                                        <span class="primary--text"> {{ item.tblCitaCita.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnNombre1 + " " +item.tblCitaCita.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnApellido1 }} </span>
                                        <span class="">{{item.tblCitaCita.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion}}</span>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="3" lg="3">
                                        COPAGO <label class="primary--text" dark >{{item.cifaCopagoLab}}</label>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="3" lg="3">
                                        TELEMEDICINA <label class="green--text" dark v-if="item.cifaTelemedicina">Si</label>
                                        <label class="red--text" dark v-else>No</label>
                                    </v-col>
                                     <v-col cols="12" sm="12" md="5" lg="5" v-if="item.tblCitaCita && item.tblCitaCita.citaTipo == 0">
                                        SERVICIO <span class="primary--text"> {{ item.tblCitaCita.tblConfContratosxservicios[0].tblConfServicios[0].coseNombre}}</span>
                                    </v-col> 
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:no-data>
                <v-row class="mt-2" align="center" justify="center">
                    <v-col cols="10" sm="10" md="10" lg="10">
                        <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                            No se encontro nada.
                        </v-alert>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:footer>
                <v-row class="mt-2 pb-5" align="center" justify="center">
                    <span class="mr-4 grey--text">
                        Pagina {{ page }} de {{ pageCount }}, Total resultados: {{(Count)}}
                    </span>
                    <v-pagination circle v-model="page" @input="buscar(filtros)" @next="buscar(filtros)" @previous="buscar(filtros)" :total-visible="10" :length="pageCount"></v-pagination>

                </v-row>
            </template>
        </v-data-iterator>
        <v-dialog v-model="dialog" width="800px" max-width="800px" persistent>
            <v-card>
            <v-card-title class="text-h5 cyan"> Copago </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <v-text-field outlined  label="Copago" v-model="copago" ></v-text-field>
                    </v-col>
                </v-row>
                </v-container>           
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary"  text @click="guardarCopago()">Guardar</v-btn>
                <v-btn color="primary" text @click="orden = null;dialog = false;">Cancelar</v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</div>
</template>

<script>
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import QRCode from 'qrcode'
import {
    documentos
}
from "../../utils/documentos";
import generales from '../../mixins/generales.js';
export default {
    props:{
        coppId:Number,
    },
    components: {
        
    },
    mixins: [generales],
    data() {
        return {
            menu: false,
            dialog: false,
            singleExpand: false,
            version: this.$cookies.get("TIPO"),
            isNew: true,
            Count: 0,
            page: 1,
            pageCount: 0,
            url: "cita/facturaciones/",
            user: this.$cookies.get("user"),
            token: this.$cookies.get("token"),
            copago:0,
            orden:null,
            filtros: [{
                    text: "Fecha",
                    value: null,
                    tipo: "fecha",
                    menu: false,
                    searchNormal: true,
                    isLoading: false,
                    /*si es tipo lista descomentar esta parte*/
                    /* lista: [],
                    listatext: "nombre del campo que se mostrara",
                    listavalue: "nombre del valor que se mandara"*/
                }, {
                    text: "Paciente",
                    value: null,
                    tipo: "lista",
                    /*si es tipo lista descomentar esta parte*/
                    lista: [],
                    listatext: "tblConfPersonasnaturales[0].copnNombre1",
                    listavalue: "tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId",
                    buscar: 'paciente',
                    isLoading: false,
                    search: null,
                    //filter: (item, queryText, itemText) => (item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + item.tblConfPersonasnaturales[0].copnApellido2).indexOf(queryText.toUpperCase()) > -1 || item.copeIdentificacion.indexOf(queryText) > -1,
                    default: true,
                    defaultselect: (data) => {
                        return data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2
                    },
                    defaultbody: (data) => {
                        return {
                            title: data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2,
                            subtitle: data.item.copeIdentificacion
                        }
                    },
                },

                {
                    text: "Empleado",
                    value: null,
                    tipo: "lista",
                    /*si es tipo lista descomentar esta parte*/
                    searchNormal: true,
                    isLoading: false,
                    lista: [],
                    listatext: "tblConfPersonasnaturales[0].copnNombre1",
                    listavalue: "tblConfPersonasnaturales[0].tblConfPersonasempleados[0].coprId",
                    filter: (item, queryText) => (item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + item.tblConfPersonasnaturales[0].copnApellido2).indexOf(queryText.toUpperCase()) > -1 || item.copeIdentificacion.indexOf(queryText) > -1,
                    default: true,
                    defaultselect: (data) => {
                        return data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2
                    },
                    defaultbody: (data) => {
                        return {
                            title: data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2,
                            subtitle: data.item.copeIdentificacion
                        }
                    },
                },
                {
                    campo: '',
                    text: "Estado",
                    value: null,
                    tipo: "lista",
                    /*si es tipo lista descomentar esta parte*/
                    lista: [{ comaNombrelargo: 'TRAMITADO', comaId: 'TRAMITADO' }, { comaNombrelargo: 'NO TRAMITADO', comaId: 'NO TRAMITADO' } , { comaNombrelargo: 'CERRADO SIN TRAMITAR', comaId: 'CERRADO SIN TRAMITAR' }],
                    listatext: "comaNombrelargo",
                    listavalue: "comaId"
                },
                {
                    campo:'',
                    text: "Rango de fecha",
                    value: [],
                    tipo: "rango",
                    menu: false,
                    searchNormal: true,
                    isLoading: false,
                    /*si es tipo lista descomentar esta parte*/
                    /* lista: [],
                    listatext: "nombre del campo que se mostrara",
                    listavalue: "nombre del valor que se mandara"*/
                },
                {
                    text: "Empresa",
                    value: null,
                    tipo: "lista",
                    lista: [],
                    listatext: "tblConfPersonasempresas[0].coemNombre",
                    listavalue: "tblConfPersonasempresas[0].coemId"
                    /*si es tipo lista descomentar esta parte*/
                    /* lista: [],
                            listatext: "nombre del campo que se mostrara",
                            listavalue: "nombre del valor que se mandara"*/
                    //para select y items personalizados
                    /*  default: true,
                            defaulttext: "tblConfPersonasnaturales",
                            defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
                            defaultsubtext: "copeIdentificacion",*/
                }
            ],

            lista: [],
            listaCopia: [],
            empleados: [],
            secciones: [],
            campos: [],
            diagnosticos: [],
            Historia: [],
            historial: [],
        };
    },

    computed: {

        formTitle() {
            return this.isNew === true ? "Nuevo" : "Actualizar";
        }
    },
    created: async function () {
        this.$store.commit('setCargaDatos', true);
        await this.buscar(this.filtros);
        this.filtros[2].lista = this.empleados = await this.$apiService.index("sistema/personas/index/empleados").then(data => data);
        this.filtros[5].lista = await this.$apiService.index("sistema/personas/index/empresas").then(data => data);
        this.$store.commit('setCargaDatos', false);
    },

    methods: {
        calcularEdad(fecha) {
            // Si la fecha es correcta, calculamos la edad
            var values = fecha.split("-");
            var dia = parseInt(values[2]);
            var mes = parseInt(values[1]);
            var ano = parseInt(values[0]);

            // cogemos los valores actuales

            var fecha_hoy = new Date();
            var ahora_ano = fecha_hoy.getFullYear();
            var ahora_mes = fecha_hoy.getMonth() + 1;
            var ahora_dia = fecha_hoy.getDate();

            // realizamos el calculo
            var edad = (ahora_ano) - ano;
            if (ahora_mes < mes) {
                edad--;
            }
            if ((mes == ahora_mes) && (ahora_dia < dia)) {
                edad--;
            }
            if (edad > 1900) {
                edad -= 1900;
            }

            // calculamos los meses
            var meses = 0;

            if (ahora_mes > mes && dia > ahora_dia)
                meses = ahora_mes - mes - 1;
            else if (ahora_mes > mes)
                meses = ahora_mes - mes
            if (ahora_mes < mes && dia < ahora_dia)
                meses = 12 - (mes - ahora_mes);
            else if (ahora_mes < mes)
                meses = 12 - (mes - ahora_mes + 1);
            if (ahora_mes == mes && dia > ahora_dia)
                meses = 11;

            // calculamos los dias
            var dias = 0;
            if (ahora_dia > dia)
                dias = ahora_dia - dia;
            if (ahora_dia < dia) {
                var ultimoDiaMes = new Date(ahora_ano, ahora_mes - 1, 0);
                dias = ultimoDiaMes.getDate() - (dia - ahora_dia);
            }
            return edad + " años, " + meses + " meses y " + dias + " días";
        },
        async search(searchP) {
            this.$store.commit('setCargaDatos', true);
            const data = await this.$apiService.index(`cita/facturaciones/laboratorio/?${searchP}`).then(data => data);
            if (data) {
                this.lista = this.listaCopia = data.rows;
                this.Count = data.count;
                this.pageCount = Math.ceil((data.count) / 10);
            }
            this.$store.commit('setCargaDatos', false);
        },
        async buscar(filtros) {
            this.filtros = filtros;
            this.lista = this.listaCopia;
            var search = `offset=${(this.page-1)*10}&`;
            if(this.$cookies.get("user").role.indexOf('EPS') > -1) {
                this.filtros[5].value = this.$cookies.get("user").empresa;
                this.filtros[3].value = 'TRAMITADO';
            }
            if(this.coppId){
                this.filtros[1].value = this.coppId;
            }
            if (this.filtros[0].value != null && this.filtros[0].value.toString().replace(/ /g, "") != "") {
                search = search + `cifaFecha=${this.filtros[0].value}&`;
            }

            if (this.filtros[1].value != null && this.filtros[1].value.toString().replace(/ /g, "") != "") {
                search = search + `coppId=${this.filtros[1].value}&`;

            }
            if (this.filtros[2].value != null && this.filtros[2].value.toString().replace(/ /g, "") != "") {
                search = search + `coprId=${this.filtros[2].value}&`;

            }
            if (this.filtros[3].value != null && this.filtros[3].value.toString().replace(/ /g, "") != "") {
                search = search + `cifaEstadoLab=${this.filtros[3].value}&`;

            }
            if (this.filtros[4].value != null && this.filtros[4].value.length > 1) {
                search = search + `cifaFecharango=${this.filtros[4].value[0]},${this.filtros[4].value[1]}&`;
                this.filtros[4].value = [];
            }
            
            if (this.filtros[5].value != null && this.filtros[5].value.toString().replace(/ /g, "") != "") {
                search = search + `coemId=${this.filtros[5].value}&`;
            }
            if (search != "") {
                await this.search(search);
            }

        },
        async dibujarH(item) {
            this.form = await this.$apiService.index("cita/facturaciones/pdf/" + item.cifaId).then(data => data);
            this.Historia = [];
            this.secciones = [];
            this.campos = [];
            this.diagnosticos = [];

            var datosPaciente = await this.$apiService.index("cita/citas/view/factura/" + this.form.citaId).then(data => data);
            this.diagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + this.form.cifaId + "/diagnostico").then(data => data);
            datosPaciente.tblCitaFacturaciones[0].tblCitaOrdenes = datosPaciente.tblCitaFacturaciones[0].tblCitaOrdenes.filter(e => e.orden.comaNombrecorto == 'TP_ORD_LAB');
            for (let index = 0; index < datosPaciente.tblCitaFacturaciones[0].tblCitaOrdenes.length; index++) {
                let orden = datosPaciente.tblCitaFacturaciones[0].tblCitaOrdenes[index];
                let s = await this.$apiService.index("sistema/formulariosecciones/secciones/" + orden.cocuId + "?LABORATORIO").then(data => data);
                this.secciones.push({ ciorId: orden.ciorId, secciones: s });
                let h = await this.$apiService.index("sistema/formulariosfacturaciones/index/" + orden.ciorId + "?tipo=orden").then(data => data);
                this.Historia.push({ ciorId: orden.ciorId, Historia: h });
            }
            if (this.Historia.length > 0) {
                let StrucsDataPaciente = await this.$apiService.index("sistema/maestra/index/TBL_DATOSPACIENTE").then(data => data);
                let StrucDataPaciente =  StrucsDataPaciente.find(e => e.comaNombrecorto == 'DP_LABORATORIO').comaValor;
                datosPaciente.StrucDataPaciente = StrucDataPaciente;
                let tipoconfig = await this.$apiService.index("sistema/maestra/index/TBL_TIPOCOFIG",this.token).then(data => data);
      
                let confOrdenes = '{}'; 
                if(tipoconfig.some(e => e.comaNombrecorto == 'TBL_CONFORDENES')){
                confOrdenes = tipoconfig.find(e => e.comaNombrecorto == 'TBL_CONFORDENES').comaValor;
                }
                datosPaciente.confOrdenes = confOrdenes;
                if (typeof window !== 'undefined') {
                    let firma = '';
                    if(this.Historia.find(e => e.Historia.length).Historia[0].empleado){
                        let datafirma = await this.$apiService.index(`sistema/personas/getFirmaPaciente?copeIdentificacion=${this.Historia.find(e => e.Historia.length).Historia[0].empleado.tblConfPersonasnaturale.tblConfPersona.copeId}`).then(data => data);
                        if (datafirma.mensaje == undefined && datafirma.mensaje != 'No data') {
                            firma = 'data:image/png;base64,' + datafirma;
                        } else {
                            firma =  (this.$cookies.get("ENTIDAD") != 'talatshi' ? ('/' + this.Historia.find(e => e.Historia.length).Historia[0].empleado.tblConfPersonasnaturale.tblConfPersona.copeFirma) : '/firmas/talatshi/RM 441178.png');
                            console.log(firma);
                        }
                    }else if(this.$cookies.get("ENTIDAD") == 'talatshi'){
                        firma =  '/firmas/talatshi/RM 441178.png';
                    }
                    
                    let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("ENTIDAD"));
                    let pdf = new jsPDF('p', 'pt', 'letter');
                    QRCode.toDataURL("http://149.56.134.140:8004/laboratorio-certificado/" + this.$cookies.get("ENTIDAD") +"/"+item.cifaId).then(url => {
                        if (this.$cookies.get("TIPO") != 'patologia' || datosPaciente.citaTipo == 0) {
                            documento.getLaboratorio(pdf, datosPaciente, this.Historia, this.secciones, this.diagnosticos,url,firma);
                        }
                    })
                    .catch(err => {
                        console.error(err)
                    });
                }

            } else {
                this.$swal.fire({
                    title: "Historia no tramitada!",
                    text: "primero debe ser llenado la historia",
                    icon: "error"
                });
            }

        },
        async guardarCopago() {
             try {
                this.$apiService.update(`cita/asistenciales/atender/cambiarEstadoOrd/${this.orden.cifaId}?tipo=TP_ORD_LAB`, { ciorCopago: this.copago},this.token).then(data => data);
                this.dialog = false;
                this.orden = null;
                await this.buscar(this.filtros);
            } catch (error) {
                this.loading = false;
                console.log(error);
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }
        },
        async cambiarEstado(item) {
             try {
                this.$apiService.update(`cita/asistenciales/atender/cambiarEstadoOrd/${item.cifaId}?tipo=TP_ORD_LAB`, { ciorEstado: 'CERRADO SIN TRAMITAR'},this.token).then(data => data);
                await this.buscar(this.filtros);            
            } catch (error) {
                this.loading = false;
                console.log(error);
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }
        },

    }
};
</script>
