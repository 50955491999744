<template>
<div>
    <v-alert text prominent dense type="error" v-if="mensaje">
        {{mensaje}}
    </v-alert>
    <v-card class="pa-2 " color="grey lighten-2" outlined tile v-else>
        <v-container>
            <v-row justify="space-around">
                <v-col cols="12" sm="12" md="12" style="min-heigth:80px" v-for="(item, i) in remisiones" :key="i">
                    <v-sheet min-height="100%" width="100%">
                        <v-container fluid class="pa-0">
                            <v-row align="center" no-gutters class="text-center" style="min-height:80px">

                                <v-col cols="12" sm="12" md="4">
                                    <label>
                                        {{item.tblConfCup.cocuNombre}}
                                    </label>
                                </v-col>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <v-col cols="12" sm="12" md="6">

                                    <label> {{item.cirmObservacion}}</label>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</div>
</template>

<script>
export default {
    props: {
        remisiones: Array,
    },
    data() {
        return {
            mensaje: null,
        };
    },
    created: async function () {
        if (this.remisiones.length == 0) {
            this.mensaje = "No se ah registrado ningun recetario"
        }
    },
};
</script>

<style>

</style>
