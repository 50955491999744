<style>

</style>

<template>
<div>
    <v-container lighten-5>
        <v-data-iterator :items="lista" :page.sync="page" :items-per-page="10" hide-default-footer sort-desc sort-by="cifaFecha" class="elevation-1">
            <template v-slot:header>
                <Dfiltros :onBuscar="buscar" v-bind:filtros="filtros" v-if="DEFAULT!='talatshi'" />
                <v-toolbar dark color="primary darken-3" class="mb-1">
                    <v-toolbar-title class="white--text">Laboratorios</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <div class="flex-grow-1"></div>
                </v-toolbar>

            </template>

            <template v-slot:default="props">
                <v-row align="center" justify="center">
                    <v-col v-for="item in props.items" :key="item.citaId" cols="12" sm="12" md="10" lg="10">
                        <v-card>
                            <v-card-title class="subtitle-1 font-weight-bold">
                                <v-row no-gutters>

                                    <v-col cols="9" sm="9" md="9" lg="9">
                                        <v-row no-gutters>

                                            <v-col cols="12" sm="5" md="5" lg="5">
                                                PACIENTE <span class="primary--text">
                                                    {{ item.tblCitaCita.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnNombre1 + " " +item.tblCitaCita.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido1 }}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" sm="7" md="7" lg="7">
                                                FECHA <span class="green--text"> {{item.cifaFecha}}</span>
                                            </v-col>

                                            <v-col cols="12" sm="5" md="5" lg="5">
                                                IDENT. <span class="primary--text">
                                                    {{item.tblCitaCita.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion}}

                                                </span>
                                            </v-col>
                                            <v-col cols="12" sm="7" md="7" lg="7">
                                                EDAD <span class="primary--text">{{ new Date().calcularEdad(item.tblCitaCita.tblConfPersonaspacientes[0].coppFechanacimiento)}}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" sm="5" md="5" lg="5">
                                                ESTADO <span :class="(item.estadoTramite > 0)?'green--text':'red--text'">{{(item.estadoTramite > 0)?'TRAMITADO':'NO TRAMITADO'}}</span>

                                            </v-col>

                                        </v-row>
                                    </v-col>
                                    <v-col cols="3" sm="3" md="3" lg="3">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined color="info" :loading="pdfLoading" class="mr-2" v-if="DEFAULT != 'patologia'" small v-on="on" @click="pdf(item)">
                                                    <v-icon>mdi-file-pdf-box</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>PDF</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined color="success" class="mr-2" small v-on="on" :to="{ name: 'Laboratoriopanelpacientes',params: { id: item.cifaId} }">
                                                    <v-icon>mdi-desktop-mac-dashboard</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Panel</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-row no-gutters>
                                    <v-col cols="12" sm="12" md="4" lg="4" v-if="item.tblCitaCita.tblConfPersonasempleados[0]">
                                        EMPLEADO
                                        <span class="primary--text"> {{ item.tblCitaCita.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnNombre1 + " " +item.tblCitaCita.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnApellido1 }} </span>
                                        <span class="">{{item.tblCitaCita.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion}}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:no-data>
                <v-row class="mt-2" align="center" justify="center">
                    <v-col cols="10" sm="10" md="10" lg="10">
                        <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                            No se encontro nada.
                        </v-alert>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:footer>
                <v-row class="mt-2 pb-5" align="center" justify="center">
                    <span class="mr-4 grey--text">
                        Pagina {{ page }} de {{ pageCount }}, Total resultados: {{(Count)}}
                    </span>
                    <v-pagination circle v-model="page" @input="buscar(filtros)" @next="buscar(filtros)" @previous="buscar(filtros)" :total-visible="10" :length="pageCount"></v-pagination>

                </v-row>
            </template>
        </v-data-iterator>

    </v-container>
</div>
</template>

<script>
import {
    documentos
}
from "../../utils/documentos";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
export default {

    components: {
        
    },
    data() {
        return {
            menu: false,
            DEFAULT: this.$cookies.get("DEFAULT"),
            dialog: false,
            singleExpand: false,
            pdfLoading: false,
            isNew: true,
            Count: 0,
            page: 1,
            pageCount: 0,
            url: "cita/facturaciones/",
            user: this.$cookies.get("user"),
            token: this.$cookies.get("token"),
            
            filtros: [{
                    text: "Fecha",
                    value: null,
                    tipo: "fecha",
                    menu: false,
                    searchNormal: true,
                    isLoading: false,
                    /*si es tipo lista descomentar esta parte*/
                    /* lista: [],
                    listatext: "nombre del campo que se mostrara",
                    listavalue: "nombre del valor que se mandara"*/
                },
                {
                    text: "Medico",
                    value: null,
                    tipo: "lista",
                    /*si es tipo lista descomentar esta parte*/
                    searchNormal: true,
                    isLoading: false,
                    lista: [],
                    listatext: "tblConfPersonasnaturales[0].copnNombre1",
                    listavalue: "tblConfPersonasnaturales[0].tblConfPersonasempleados[0].coprId",
                    filter: (item, queryText) => (item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + item.tblConfPersonasnaturales[0].copnApellido2).indexOf(queryText.toUpperCase()) > -1 || item.copeIdentificacion.indexOf(queryText) > -1,
                    default: true,
                    defaultselect: (data) => {
                        return data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2
                    },
                    defaultbody: (data) => {
                        return {
                            title: data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2,
                            subtitle: data.item.copeIdentificacion
                        }
                    },
                },
            ],

            lista: [],
            listaCopia: [],
            empleados: [],
            Historia: [],
            secciones: [],
            campos: [],
            diagnosticos: [],
            form:null
        };
    },

    computed: {

        formTitle() {
            return this.isNew === true ? "Nuevo" : "Actualizar";
        }
    },
    watch: {
        dialog(val) {
            val || this.close();
        }
    },
    created: async function () {
        

        if (this.$cookies.get('user').role == 'PACIENTE') {
            this.$store.commit('setCargaDatos', true);

            this.filtros[1].lista = this.empleados = await this.$apiService.index("sistema/personas/index/empleados").then(data => data);
            await this.buscar(this.filtros);
            this.$store.commit('setCargaDatos', false);
        }
    },

    methods: {
        
        async search(searchP) {
            this.$store.commit('setCargaDatos', true);
            const data = await this.$apiService.index(`cita/facturaciones/laboratorio/?${searchP}`).then(data => data);
            if (data) {
                this.lista = this.listaCopia = data.rows;
                this.Count = data.count;
                this.pageCount = Math.ceil((data.count) / 10);
            }
            this.$store.commit('setCargaDatos', false);
        },

        async buscar(filtros) {
            this.filtros = filtros;
            this.lista = this.listaCopia;
            var search = `offset=${(this.page-1)*10}&`;

            if (this.filtros[0].value != null && this.filtros[0].value.toString().replace(/ /g, "") != "") {
                search = search + `cifaFecha=${this.filtros[0].value}&`;
            }
            search = search + `coppId=${this.$cookies.get('user').empleado}&`;
            if (this.filtros[1].value != null && this.filtros[1].value.toString().replace(/ /g, "") != "") {
                search = search + `coprId=${this.filtros[1].value}&`;

            }
            if (search != "") {
                await this.search(search);
            }

        },
        async pdf(item) {
            this.pdfLoading = true;
            await this.dibujarH(item);
            this.pdfLoading = false;
        },
        async dibujarH(item) {
            this.form = await this.$apiService.index("cita/facturaciones/pdf/" + item.cifaId).then(data => data);
            this.Historia = [];
            this.secciones = [];
            this.campos = [];
            this.diagnosticos = [];
            let datosPaciente = null;
            if (this.DEFAULT == 'patologia') {
                 datosPaciente = await this.$apiService.index("cita/citas/view/factura/" + this.form.citaId).then(data => data);
                this.diagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + this.form.cifaId + "/diagnostico").then(data => data);
                this.secciones = await this.$apiService.index("sistema/formulariosecciones/secciones/" + item.cocuId + "?LABORATORIO").then(data => data);
                this.Historia = await this.$apiService.index("sistema/formulariosfacturaciones/index/" + item.ciorId + "?tipo=orden").then(data => data);
            } else {
                 datosPaciente = await this.$apiService.index("cita/citas/view/factura/" + item.citaId).then(data => data);
                this.diagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + item.cifaId + "/diagnostico").then(data => data);

                for (let index = 0; index < datosPaciente.tblCitaFacturaciones[0].tblCitaOrdenes.length; index++) {
                    let orden = datosPaciente.tblCitaFacturaciones[0].tblCitaOrdenes[index];
                    let s = await this.$apiService.index("sistema/formulariosecciones/secciones/" + orden.cocuId + "?LABORATORIO").then(data => data);
                    this.secciones.push({ ciorId: orden.ciorId, secciones: s });
                    let h = await this.$apiService.index("sistema/formulariosfacturaciones/index/" + orden.ciorId + "?tipo=orden").then(data => data);
                    this.Historia.push({ ciorId: orden.ciorId, Historia: h });

                }
            }

            if (this.Historia.length > 0) {
                if (this.DEFAULT == 'patologia') {
                    this.secciones.forEach((seccion) => {
                        if (seccion.campos.length > 0) {
                            seccion.campos.sort((a, b) => a.foseOrden - b.foseOrden);
                            seccion.campos.forEach(campo => {
                                if (this.Historia.find(e => e.foseId == campo.foseId) == undefined) {

                                    this.Historia.push({
                                        ciffId: null,

                                        ciffValor: null,

                                        foseId: campo.foseId
                                    });
                                }
                            });
                        }

                    });
                }
                if (typeof window !== 'undefined') {
                    let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("DEFAULT"));
                    let pdf = new jsPDF();
                    if (this.DEFAULT != 'patologia') {
                        documento.getLaboratorio(pdf, datosPaciente, this.Historia, this.secciones, this.diagnosticos);
                    } else {
                        documento.getDocumento(pdf, datosPaciente, this.Historia, this.secciones, this.diagnosticos);
                    }

                }

            } else {
                this.$swal.fire({
                    title: "Historia no tramitada!",
                    text: "primero debe ser llenado la historia",
                    icon: "error"
                });
            }

        },

    }
};
</script>
