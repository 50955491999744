<template>
    <v-container id="cont" lighten-5>
        <v-row>
            <v-col cols="auto" md="12">
                <v-form @submit.prevent="agregar">
                    <v-card class="mb-12">
                        <v-card-title>
                            <span class="headline">Procedimento {{$route.params.titulo}}</span>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-btn color="primary darken-1" dark v-if="e1==1 && pag > 1" @click="atras">atras</v-btn>
                            <v-btn color="primary darken-1" dark v-if="e1==1 && pag < seccionesPaginado.length" @click="siguiente">siguiente</v-btn>
                            <div class="flex-grow-1"></div>
                            <v-btn color="red darken-1" text :to="{ name: 'Procedimientos' }">Cancelar</v-btn>
                            <v-btn color="primary darken-1" dark :loading="loading" @click="agregar">Guardar</v-btn>
                        </v-card-actions>

                        <v-divider></v-divider>
                        <v-card-text>
                            <v-container v-if="seccionesvista.length > 0">
                                <v-row v-for="seccion in  seccionesvista" :key="'row-'+seccion.foseId">
                                        <v-col cols="12" sm="12" md="12" class="primary--text">
                                            <h3>{{seccion.foseNombre}}</h3>
                                        </v-col>
                                        <v-col cols="12" sm="12" :md="c.tipo.comaNombrecorto=='CAMPO'?c.foseCol:12" v-for="c in  seccion.campos" :key="'col-'+c.foseId" >
                                          <div v-if="c.tipo.comaNombrecorto=='CAMPO'">
                                            <div v-for="(item,index) in  formFormulario.filter(e=>e.foseId==c.foseId)" :key="'cam-'+item.foseId">
                                                    <div v-if="c.foseRequerido">
                                                        <template v-if="c.tipodato.comaNombrecorto == 'TEXT'">
                                                            <v-text-field :id="c.foseCodigo" outlined v-model="item.ciffValor" :error="$v.formFormulario.$each[index].ciffValor.$error" :label="c.foseNombre" v-on:keyup="item.ciffValor = $event.target.value.toUpperCase()"></v-text-field>
                                                        </template>
                                                        <template v-if="c.tipodato.comaNombrecorto == 'TEXTAREA'">
                                                            <v-textarea :id="c.foseCodigo" outlined v-model="item.ciffValor" :error="$v.formFormulario.$each[index].ciffValor.$error" :label="c.foseNombre" v-on:keyup="item.ciffValor = $event.target.value.toUpperCase()"></v-textarea>
                                                        </template>
                                                        <template v-if="c.tipodato.comaNombrecorto == 'LISTA'">
                                                            <v-autocomplete v-if="c.foseConsulta && listasParametros.length > 0" :id="c.foseCodigo" outlined v-model="item.ciffValor" :error="$v.formFormulario.$each[index].ciffValor.$error" :items="listasParametros.find(e => e.foseId ==c.foseId).lista" item-text="texts" item-value="values" :label="c.foseNombre" item-color="primary" clearable></v-autocomplete>
                                                            <v-autocomplete v-else :id="c.foseCodigo" outlined v-model="item.ciffValor" :error="$v.formFormulario.$each[index].ciffValor.$error" :items="getValues(c.foseValores)" item-text="texts" item-value="values" item-color="primary" :label="c.foseNombre" clearable></v-autocomplete>
                                                        </template>
                                                        <template v-if="c.tipodato.comaNombrecorto == 'SI/NO'">
                                                            <v-switch :id="c.foseCodigo" v-model="item.ciffValor" :label="c.foseNombre" :error="$v.formFormulario.$each[index].ciffValor.$error" :true-value="c.foseValores.split(';')[0]" :false-value="c.foseValores.split(';')[1]"></v-switch>
                                                        </template>
                                                        <template v-else-if="c.tipodato.comaNombrecorto == 'FECHA'">
                                                            <v-menu v-model="item.menu" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                                                <template v-slot:activator="{ on }">
                                                                    <v-text-field :id="c.foseCodigo" required outlined :error="$v.formFormulario.$each[index].ciffValor.$error" v-model="item.ciffValor" append-icon="event" readonly v-on="on" :label="c.foseNombre" clearable></v-text-field>
                                                                </template>
                                                                <v-date-picker locale="ES" v-model="item.ciffValor" @input="item.menu = false"></v-date-picker>
                                                            </v-menu>
                                                        </template>
                                                    </div>
                                                    <div v-else>
                                                        <template v-if="c.tipodato.comaNombrecorto == 'TEXT'">
                                                            <v-text-field :id="c.foseCodigo" outlined v-model="item.ciffValor" :label="c.foseNombre" v-on:keyup="item.ciffValor = $event.target.value.toUpperCase()"></v-text-field>
                                                        </template>
                                                        <template v-if="c.tipodato.comaNombrecorto == 'TEXTAREA'">
                                                            <v-textarea :id="c.foseCodigo" outlined v-model="item.ciffValor" :label="c.foseNombre" v-on:keyup="item.ciffValor = $event.target.value.toUpperCase()"></v-textarea>
                                                        </template>
                                                        <template v-if="c.tipodato.comaNombrecorto == 'LISTA'">
                                                            <v-autocomplete v-if="c.foseConsulta && listasParametros.length > 0" :id="c.foseCodigo" outlined v-model="item.ciffValor" :items="listasParametros.find(e => e.foseId ==c.foseId).lista" item-text="texts" item-value="values" :label="c.foseNombre" item-color="primary" clearable></v-autocomplete>
                                                            <v-autocomplete v-else :id="c.foseCodigo" outlined v-model="item.ciffValor" :items="getValues(c.foseValores)" item-text="texts" item-value="values" item-color="primary" :label="c.foseNombre" clearable></v-autocomplete>
                                                        </template>
                                                        <template v-if="c.tipodato.comaNombrecorto == 'SI/NO'">
                                                            <v-switch :id="c.foseCodigo" v-model="item.ciffValor" :label="c.foseNombre" :true-value="c.foseValores.split(';')[0]" :false-value="c.foseValores.split(';')[1]"></v-switch>
                                                        </template>
                                                        <template v-else-if="c.tipodato.comaNombrecorto == 'FECHA'">
                                                            <v-menu v-model="item.menu" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                                                <template v-slot:activator="{ on }">
                                                                    <v-text-field :id="c.foseCodigo" dense outlined v-model="item.ciffValor" append-icon="event" readonly v-on="on" :label="c.foseNombre" clearable></v-text-field>
                                                                </template>
                                                                <v-date-picker locale="ES" v-model="item.ciffValor" @input="item.menu = false"></v-date-picker>
                                                            </v-menu>
                                                        </template>
                                                    </div>
                                            </div>
                                          </div>
                                          <div v-else>
                                            <div v-if="c.foseRequerido">
                                            <div v-if="$v.formFormulario.$each[formFormulario.indexOf(formFormulario.find(e=>e.foseId==c.foseId))].ciffValor.$error">
                                                 <span class="danger--text">Debe registrar minimo un item.</span>
                                            </div>
                                          </div>
                                          <div v-if="formFormulario.find(e=>e.foseId==c.foseId)">
                                            <input type="hidden" :id="c.foseCodigo" v-model="formFormulario.find(e=>e.foseId==c.foseId).ciffValor"/>
                                          </div>
                                          <v-row>
                                                  <v-col cols="12" sm="12" md="12" class="primary--text">
                                                      <h3>{{c.foseNombre}}</h3>
                                                  </v-col>
                                                  <v-col cols="12" sm="12" v-if="subseccionesdatos.find(e => e.foseId == c.foseId)">
                                                    <v-row v-for="(subs,index) in subseccionesdatos.find(e => e.foseId == c.foseId).data"  v-bind:key="'subcol-'+subs.foseId+'-'+index">
                                                      <v-col cols="12" sm="12" :md="subc.foseCol" v-for="subc in  c.campos.filter(e => subs.some(a=> a.codigo ==e.foseCodigo ) )" :key="'col-'+subc.foseId+'-'+index">
                                                          <div>
                                                                  <template v-if="subc.tipodato.comaNombrecorto == 'TEXT'">
                                                                      <v-text-field :id="subc.foseCodigo+'_'+index" outlined v-model="subs.find(a=> a.codigo ==subc.foseCodigo ).value"  :label="subc.foseNombre" ></v-text-field>
                                                                  </template>
                                                                  <template v-if="subc.tipodato.comaNombrecorto == 'TEXTAREA'">
                                                                      <v-textarea :id="subc.foseCodigo+'_'+index" outlined v-model="subs.find(a=> a.codigo ==subc.foseCodigo ).value"  :label="subc.foseNombre"></v-textarea>
                                                                  </template>
                                                                  <template v-if="subc.tipodato.comaNombrecorto == 'LISTA'">
                                                                      <v-autocomplete v-if="subc.foseConsulta && listasParametros.length > 0" :id="subc.foseCodigo+'_'+index" outlined v-model="subs.find(a=> a.codigo ==subc.foseCodigo ).value"  :items="listasParametros.find(e => e.foseId ==subc.foseId).lista" item-text="texts" item-value="values" :label="subc.foseNombre" item-color="primary" clearable></v-autocomplete>
                                                                      <v-autocomplete v-else :id="subc.foseCodigo+'_'+index" outlined v-model="subs.find(a=> a.codigo ==subc.foseCodigo ).value"  :items="getValues(subc.foseValores)" item-text="texts" item-value="values" item-color="primary" :label="subc.foseNombre" clearable></v-autocomplete>
                                                                  </template>
                                                                  <template v-if="subc.tipodato.comaNombrecorto == 'SI/NO'">
                                                                      <v-switch :id="subc.foseCodigo+'_'+subs" v-model="subs.find(a=> a.codigo ==subc.foseCodigo ).value" :label="c.foseNombre"  :true-value="subc.foseValores.split(';')[0]" :false-value="subc.foseValores.split(';')[1]"></v-switch>
                                                                  </template>
                                                                  <template v-if="subc.tipodato.comaNombrecorto == 'FECHA' ">
                                                                      <v-menu v-model="subs.find(a=> a.codigo ==subc.foseCodigo ).menu" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                                                          <template v-slot:activator="{ on }">
                                                                              <v-text-field :id="subc.foseCodigo+'_'+subs" required outlined v-model="subs.find(a=> a.codigo ==subc.foseCodigo ).value" append-icon="event" readonly v-on="on" :label="subc.foseNombre" clearable></v-text-field>
                                                                          </template>
                                                                          <v-date-picker locale="ES" v-model="subs.find(a=> a.codigo ==subc.foseCodigo ).value" @input="subs.find(a=> a.codigo ==subc.foseCodigo ).menu = false"></v-date-picker>
                                                                      </v-menu>
                                                                  </template>
                                                              </div>
                                                  </v-col>
                                                  <v-col cols="12" sm="1" v-if="((index ==0 && !c.foseRequerido) || (index !=0) )&& c.foseMultiple">
                                                    <v-btn text icon color="error" @click="quitarItem(c.foseId,index)">
                                                        <v-icon>delete</v-icon>
                                                    </v-btn>
                                                  </v-col>
                                                    </v-row>
                                                  </v-col>
                                                  <v-col cols="12" sm="12" v-if="c.foseMultiple || (c.foseRequerido && subseccionesdatos.find(e => e.foseId == c.foseId).data.length==0 )">
                                                    <v-btn color="primary darken-1" dark  @click="agregarItem(c.foseId)">Agregar</v-btn>
                                                  </v-col>
                                          </v-row>
                                          </div>
                                        </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <div id="scripthistoria"> </div>
    </v-container>
</template>

<script>
import postscribe from 'postscribe';
import {
  required
}
from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      e1: 1,
      pag: 1,
      dialog: false,
      menu: false,
      isNew: true,
      loading: false,
      listasParametros: [],
      seccionesvista: [],
      seccionesPaginado: [],
      user: this.$cookies.get("user"),
      url: "sistema/formulariosfacturaciones/",
      token: this.$cookies.get("token"),

      secciones: [],
      subsecciones: [],
      subseccionesdatos:[],
      formFormulario: [],
      formFormularioValidado: [],
      script: ''

    };
  },

  validations: {

    formFormularioValidado: {
      $each: {
        ciffValor: {
          required,
        },
      }
    },
    formFormulario: {
      $each: {
        ciffValor: {
          required,
        },
      }
    },
  },
  created: async function() {

    this.$store.commit('setCargaDatos', true);

    if (this.$route.params.id) {
      var servicio = this.$route.params.servicio;

      try {
          this.secciones = await this.$apiService.index("sistema/formulariosecciones/secciones/" + servicio + "?PROCEDIMIENTO").then(data => data);
          this.formFormulario = await this.$apiService.index(this.url + "index/" + this.$route.params.id + "?tipo=factura").then(data => data);
          if (this.formFormulario.length > 0) {
            this.isNew = false;
            for (let formu of this.formFormulario) {
              formu.updatedBy = this.user.empleado;
              formu.updatedAt = new Date();
            }
          } else {
            this.isNew = true;
          }
          this.secciones.forEach(seccion => {
            if (seccion.campos.length > 0 && seccion.foseEstado) {
              seccion.campos.sort((a, b) => a.foseOrden - b.foseOrden);
              seccion.campos = seccion.campos.filter(e => e.foseEstado);
              
              seccion.campos.forEach(async data => {
                if (data.tipo.comaNombrecorto=='SECCION') {
                   let subsecciones = await this.$apiService.index(`sistema/formulariosecciones/index?offset=0&foseId=${data.foseId}`).then(data => data);
                   data.campos = subsecciones.rows[0].campos;
                   let structura = [];
                   data.campos.forEach(async datasub => {
                      if (datasub.foseConsulta) {
                        let lista = await this.$apiService.create("reporte/reportes/consultar/", {
                          consulta: datasub.foseConsulta
                        }).then(data => data);
                        this.listasParametros.push({
                          foseId: datasub.foseId,
                          lista: lista
                        });
                      }
                      structura.push({item:0,id:datasub.foseId,menu: false,label:datasub.foseNombre,seccionId:data.foseId,codigo:datasub.foseCodigo ,value:(datasub.foseDefault) ? datasub.foseDefault : (datasub.foseRequerido) ? null : ((datasub.tipodato.comaNombrecorto == 'SI/NO') ? 'NO' : (datasub.tipodato.comaNombrecorto == 'TEXTAREA') ? 'NORMAL' : null)});
                    });
                    this.subsecciones.push({id:data.foseId,default:structura});
                    if (!this.formFormulario.some(e => e.foseId == data.foseId)) {
                        this.formFormulario.push({
                            ciffId: null,
                            menu: false,
                            ciffValor: null,
                            cifaId: this.$route.params.id,
                            foseId: data.foseId,
                            createdBy: this.user.empleado
                          });
                            this.subseccionesdatos.push({foseId:data.foseId,data:data.foseRequerido?[structura]:[]});
                      }else{
                        this.subseccionesdatos.push({foseId:data.foseId,data:this.formFormulario.find(e => e.foseId == data.foseId).ciffValor!= null? JSON.parse(this.formFormulario.find(e => e.foseId == data.foseId).ciffValor):data.foseRequerido?[structura]:[]}) ;
                      }
                }else{
                  if (data.foseConsulta) {
                    let lista = await this.$apiService.create("reporte/reportes/consultar/", {
                      consulta: data.foseConsulta
                    }).then(data => data);
                    this.listasParametros.push({
                      foseId: data.foseId,
                      lista: lista
                    });
                  }
                  if (!this.formFormulario.some(e => e.foseId == data.foseId)) {
                    this.formFormulario.push({
                      ciffId: null,
                      menu: false,
                      ciffValor: (data.foseDefault) ? data.foseDefault : (data.foseRequerido) ? null : ((data.tipodato.comaNombrecorto == 'SI/NO') ? 'NO' : (data.tipodato.comaNombrecorto == 'TEXTAREA') ? 'NORMAL' : null),
                      cifaId: this.$route.params.id,
                      foseId: data.foseId,
                      createdBy: this.user.empleado
                    });
                  }
                }
                if (data.foseRequerido) {
                    this.formFormularioValidado.push({
                      ciffValor: null,
                      foseId: data.foseId,
                    });
                  }
              });
            }
          });

          let secciones = [];
          for (let i = 0; i < this.secciones.length; i++) {
            let seccion = this.secciones[i];

            if ((i % 4) == 0 && i != 0) {
              secciones.push(seccion);
              this.seccionesPaginado.push({
                pag: i / 4,
                secciones: secciones
              });
              secciones = [];
            } else {
              if ((i + 1) == this.secciones.length) {
                secciones.push(seccion);
                this.seccionesPaginado.push({
                  pag: (this.seccionesPaginado.length + 1),
                  secciones: secciones
                });
                secciones = [];
              } else {
                secciones.push(seccion);
              }

            }
          }
          this.seccionesvista = this.seccionesPaginado.filter(e => e.pag == 1)[0].secciones;
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    }

    this.$store.commit('setCargaDatos', false);

  },

  updated() {

    if (document.getElementById('scripthistoria') && this.secciones.length > 0) {
      if (document.getElementById('scripthistoria').innerHTML.length == 0) {
        let s = '';
        for (let i = 0; i < this.secciones.length; i++) {
          const data = this.secciones[i].campos;
          if (this.secciones[i].hiseScript) {
            s = s + this.secciones[i].hiseScript;
          }

          if (data) {

            for (let j = 0; j < data.length; j++) {
              if (data[j].hiseScript) {
                s = s + data[j].hiseScript;
              }
            }
          }

        }
        this.script = `<script>${ s }<${"/"}script>`;
        postscribe('#scripthistoria', this.script);
      }

    }

  },

  methods: {
    resetearIndex(id){
      for (let i = 0; i < this.subseccionesdatos.find(e => e.foseId == id).data.length;i++) {
       for (let iterator of this.subseccionesdatos.find(e => e.foseId == id).data[i]) {
        iterator.item = i;
       } 
      }
    },
    agregarItem(id){
      let data = JSON.parse(JSON.stringify(this.subseccionesdatos.find(e => e.foseId == id).data));
      data.push(this.subsecciones.find(e => e.id == id).default)
      this.subseccionesdatos.find(e => e.foseId == id).data =data;
      this.resetearIndex(id)
    },
    quitarItem(id,item){
      this.subseccionesdatos.find(e => e.foseId == id).data = this.subseccionesdatos.find(e => e.foseId == id).data.filter(e => !e.some(a=> a.item==item));
      if(this.subseccionesdatos.find(e => e.foseId == id).data.length>0){
        this.formFormulario.find(e => e.foseId == id).ciffValor = JSON.stringify(this.subseccionesdatos.find(e => e.foseId == id).data);
      }else{
        this.formFormulario.find(e => e.foseId == id).ciffValor = null;
      }
      this.resetearIndex(id);
    },
    atras() {

      if (this.pag != 1) {
        this.pag = this.pag - 1;
        this.seccionesvista = this.seccionesPaginado.find(e => e.pag == this.pag).secciones;

      }

    },
    siguiente() {

      if (this.pag < this.seccionesPaginado.length) {
        this.pag = this.pag + 1;
        this.seccionesvista = this.seccionesPaginado.find(e => e.pag == this.pag).secciones;

      }
    },
    getValues(values) {
      const lista = [];

      var datos = values.split('|');
      for (let i = 0; i < datos.length; i++) {
        let d = datos[i];
        lista.push({
          texts: d.split(';')[0],
          values: d.split(';')[1]
        });
      }
      return lista;

    },

    async agregar() {
      this.loading = true;
      for (let i = 0; i < this.formFormulario.length; i++) {
        const element = this.formFormulario[i];
        if(this.subseccionesdatos.some(e => e.foseId == element.foseId)){
          if(this.subseccionesdatos.find(e => e.foseId == element.foseId).data.length>0){
            element.ciffValor = JSON.stringify(this.subseccionesdatos.find(e => e.foseId == element.foseId).data);
          }else{
            element.ciffValor = null;
          }
        }
        
        for (let j = 0; j < this.formFormularioValidado.length; j++) {
          const elementv = this.formFormularioValidado[j];
          if (element.foseId == elementv.foseId) {
            elementv.ciffValor = element.ciffValor;
          }
        }
      }
      this.$v.formFormularioValidado.$touch();
      this.$v.formFormulario.$touch()
      if (!this.$v.formFormularioValidado.$error) {
        try {
          this.$apiService.update(`cita/asistenciales/atender/cambiarEstadoOrd/${this.$route.params.id}?tipo=PROC`, { ciorEstado: 'TRAMITADO'},this.token).then(data => data);
          if (this.isNew) {
              let data = await this.$apiService.create(this.url + "create?cifaId=" + this.$route.params.id, this.formFormulario).then(data => data);
              if (data) {
                this.$router.push({
                  name: 'Procedimientos'
                });
                this.$swal.fire({
                  title: "Completado!",
                  text: "Registro exitoso",
                  icon: "success",
                  confirmButtonText: "Ok"
                });
              }
          } else {
              let data = await this.$apiService.update(this.url + "update/" + this.$route.params.id, this.formFormulario).then(data => data);
              if (data) {
                this.$router.push({
                  name: 'Procedimientos'
                });
                this.$swal.fire({
                  title: "Completado!",
                  text: "Registro exitoso",
                  icon: "success",
                  confirmButtonText: "Ok"
                });
              }
            }
        } catch (error) {
          this.loading = false;
          console.log(error);
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }
      } else {
        this.$swal.fire({
          title: "Campos vacios de la historia!",
          text: "por favor diligenciarlos",
          icon: "warning"
        });
      }
      this.loading = false;
    },

    limpiar() {
      this.formFormulario = [];
    }
  }
};

</script>

<style scoped>
#scroll-historia {
  max-height: 700px !important;
  overflow: auto;
}

</style>
