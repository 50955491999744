<template>
<v-container id="cont" lighten-5>
    <v-row>
        <v-col cols="auto" md="12">
            <v-form @submit.prevent="agregar">
                <v-card class="mb-12">
                    <v-card-title>
                        <span class="headline">Laboratorio {{$route.params.titulo}}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-btn color="primary darken-1" dark v-if="e1==1 && pag > 1" @click="atras">atras</v-btn>
                        <v-btn color="primary darken-1" dark v-if="e1==1 && pag < seccionesPaginado.length" @click="siguiente">siguiente</v-btn>
                        <div class="flex-grow-1"></div>
                        <v-btn color="red darken-1" text :to="{ name: 'Laboratoriopanel',params: { id: $route.params.cifaId} }">Cancelar</v-btn>
                        <v-btn color="primary darken-1" dark :loading="loading" @click="agregar">Guardar</v-btn>
                    </v-card-actions>

                    <v-divider></v-divider>
                    <v-card-text>
                        
                        <input type="hidden" id="cifaId" name="cifaId" :value="$route.params.cifaId">
                        <v-container v-if="seccionesvista.length > 0">
                            <v-row >
                                <v-col cols="12" sm="12" md="6">
                                    <v-menu v-model="menuFechatoma" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field  required outlined  v-model="formOrden.ciorFechatoma" append-icon="event" readonly v-on="on" label="Fecha de la toma" clearable></v-text-field>
                                        </template>
                                        <v-date-picker locale="ES" v-model="formOrden.ciorFechatoma" @input="menuFechatoma = false"></v-date-picker>
                                
                                    </v-menu>
                                </v-col>
                                 <v-col cols="12" sm="12" md="6">
                                    <v-menu v-model="menuFechaprocedimiento" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field  required outlined  v-model="formOrden.ciorFechaprocedimiento" append-icon="event" readonly v-on="on" label="Fecha del procedimiento" clearable></v-text-field>
                                        </template>
                                        <v-date-picker locale="ES" v-model="formOrden.ciorFechaprocedimiento" @input="menuFechaprocedimiento = false"></v-date-picker>
                                
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row v-for="seccion in  seccionesvista" :key="'row-'+seccion.foseId">
                                <template v-if="secciones.length > 0">
                                    <v-col cols="12" sm="12" md="12" class="primary--text">
                                        <h3>{{seccion.foseNombre}}</h3>
                                    </v-col>
                                    <template cols="12" sm="12" md="12" v-for="ca in  campos">
                                        <template v-if="ca.foseId == seccion.foseId">
                                            <v-col cols="12" sm="12" :md="c.foseCol" v-for="c in  ca.campos" :key="'col-'+c.foseId">
                                                <div v-for="(item,index) in  formFormulario" :key="'cam-'+item.foseId">
                                                    <template v-if="c.foseId == item.foseId">

                                                        <div v-if="c.foseRequerido">
                                                            <template v-if="c.tipodato.comaNombrecorto == 'TEXT'">
                                                                <v-text-field :id="c.foseCodigo" outlined v-model="item.ciffValor" :error="$v.formFormulario.$each[index].ciffValor.$error" :label="c.foseNombre"></v-text-field>
                                                            </template>
                                                            <template v-if="c.tipodato.comaNombrecorto == 'TEXTAREA'">
                                                                <v-textarea :id="c.foseCodigo" outlined v-model="item.ciffValor" :error="$v.formFormulario.$each[index].ciffValor.$error" :label="c.foseNombre"></v-textarea>
                                                            </template>
                                                            <template v-if="c.tipodato.comaNombrecorto == 'LISTA'">
                                                                <v-autocomplete :id="c.foseCodigo" outlined v-model="item.ciffValor" :error="$v.formFormulario.$each[index].ciffValor.$error" :items="getValues(c.foseValores)" item-text="texts" item-value="values" item-color="primary" :label="c.foseNombre" clearable></v-autocomplete>
                                                            </template>
                                                            <template v-if="c.tipodato.comaNombrecorto == 'SI/NO'">
                                                                <v-switch :id="c.foseCodigo" v-model="item.ciffValor" :label="c.foseNombre" :error="$v.formFormulario.$each[index].ciffValor.$error" :true-value="c.foseValores.split(';')[0]" :false-value="c.foseValores.split(';')[1]"></v-switch>
                                                            </template>
                                                            <template v-else-if="c.tipodato.comaNombrecorto == 'FECHA'">
                                                                    <v-menu v-model="item.menu" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-text-field :id="c.foseCodigo" required outlined  :error="$v.formFormulario.$each[index].ciffValor.$error" v-model="item.ciffValor" append-icon="event" readonly v-on="on" :label="c.foseNombre" clearable></v-text-field>
                                                                        </template>
                                                                        <v-date-picker locale="ES" v-model="item.ciffValor" @input="item.menu = false"></v-date-picker>
                                                                    </v-menu>
                                                            </template>

                                                        </div>
                                                        <div v-else>
                                                            <template v-if="c.tipodato.comaNombrecorto == 'TEXT'">
                                                                <v-text-field :id="c.foseCodigo" outlined v-model="item.ciffValor" :label="c.foseNombre"></v-text-field>
                                                            </template>
                                                            <template v-if="c.tipodato.comaNombrecorto == 'TEXTAREA'">
                                                                <v-textarea :id="c.foseCodigo" outlined v-model="item.ciffValor" :label="c.foseNombre"></v-textarea>
                                                            </template>
                                                            <template v-if="c.tipodato.comaNombrecorto == 'LISTA'">
                                                                <v-autocomplete :id="c.foseCodigo" outlined v-model="item.ciffValor" :items="getValues(c.foseValores)" item-text="texts" item-value="values" item-color="primary" :label="c.foseNombre" clearable></v-autocomplete>
                                                            </template>
                                                            <template v-if="c.tipodato.comaNombrecorto == 'SI/NO'">
                                                                <v-switch :id="c.foseCodigo" v-model="item.ciffValor" :label="c.foseNombre" :true-value="c.foseValores.split(';')[0]" :false-value="c.foseValores.split(';')[1]"></v-switch>
                                                            </template>
                                                            <template v-else-if="c.tipodato.comaNombrecorto == 'FECHA'">
                                                                    <v-menu v-model="item.menu" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-text-field :id="c.foseCodigo" dense outlined v-model="item.ciffValor" append-icon="event" readonly v-on="on" :label="c.foseNombre" clearable></v-text-field>
                                                                        </template>
                                                                        <v-date-picker locale="ES" v-model="item.ciffValor" @input="item.menu = false"></v-date-picker>
                                                                    </v-menu>
                                                            </template>

                                                        </div>

                                                    </template>
                                                </div>
                                            </v-col>

                                        </template>
                                    </template>
                                </template>
                                <div id="scripthistoria"> </div>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-form>
        </v-col>
    </v-row>

</v-container>
</template>

<script>
import postscribe from 'postscribe';
import {
    required
}
from 'vuelidate/lib/validators'
export default {
    data() {
        return {
            e1: 1,
            pag: 1,
            dialog: false,
            menu: false,
            isNew: true,
            loading: false,
            seccionesvista: [],
            seccionesPaginado: [],
            user: this.$cookies.get("user"),
            url: "sistema/formulariosfacturaciones/",
            token: this.$cookies.get("token"),
            menuFechatoma:false,
            menuFechaprocedimiento:false,
            secciones: [],
            campos: [],
            formFormulario: [],
            formFormularioValidado: [],
            formOrden:{
                ciorId:null,
                ciorFechatoma: null,
                ciorFechaprocedimiento:null,
                cifaId:null
            },
            script: ''

        };
    },

    validations: {

        formFormularioValidado: {
            $each: {
                ciffValor: {
                    required,
                },
            }
        },
        formFormulario: {
            $each: {
                ciffValor: {
                    required,
                },
            }
        },
    },
    created: async function () {
        this.$store.commit('setCargaDatos', true);
        if (this.$route.params.id) {
            var servicio = this.$route.params.servicio;
            try {
                    this.secciones = await this.$apiService.index("sistema/formulariosecciones/secciones/" + servicio + "?LABORATORIO").then(data => data);
                    this.formOrden.ciorId = this.$route.params.id;
                    this.formOrden.ciorFechatoma = new Date().yyyymmdd();
                    this.formOrden.ciorFechaprocedimiento = new Date().yyyymmdd();
                    this.formOrden.ciorEstado = 'TRAMITADO';
                    this.formOrden.cifaId = this.$route.params.cifaId;
                    this.formFormulario = await this.$apiService.index(this.url + "index/" + this.$route.params.id + "?tipo=orden").then(data => data);
                    if (this.formFormulario.length > 0) {
                        for (let formu of this.formFormulario) {
                            formu.updatedBy = this.user.empleado;
                            formu.updatedAt = new Date();
                        }
                        this.isNew = false;
                    } else {
                        this.isNew = true;
                    }
                    for (let i = 0; i < this.secciones.length; i++) {
                        const data = this.secciones[i].campos;
                        if (data.length > 0) {
                            data.sort((a, b) => a.foseOrden - b.foseOrden);
                            this.campos.push({
                                foseId: data[0].foseIdDependencia,
                                campos: data
                            });

                            for (let j = 0; j < data.length; j++) {
                                if (this.formFormulario.filter(e => e.foseId == data[j].foseId).length == 0) {
                                    this.formFormulario.push({
                                        ciffId: null,
                                        menu:false,
                                        ciffValor: (data[j].foseDefault) ? data[j].foseDefault : (data.foseRequerido)?null:((data[j].tipodato.comaNombrecorto == 'SI/NO') ? 'NO' : (data[j].tipodato.comaNombrecorto == 'TEXTAREA') ? 'NORMAL' : null),

                                        ciorId: this.$route.params.id,

                                        foseId: data[j].foseId,
                                        createdBy: this.user.empleado
                                    });
                                }
                                if (data[j].foseRequerido) {
                                    this.formFormularioValidado.push({
                                        ciffValor: null,
                                        foseId: data[j].foseId
                                    });
                                }

                            }
                        }

                    }
                    let secciones = [];
                    for (let i = 0; i < this.secciones.length; i++) {
                        let seccion = this.secciones[i];

                        if ((i % 4) == 0 && i != 0) {
                            secciones.push(seccion);
                            this.seccionesPaginado.push({ pag: i / 4, secciones: secciones });
                            secciones = [];
                        } else {
                            if ((i + 1) == this.secciones.length) {
                                secciones.push(seccion);
                                this.seccionesPaginado.push({ pag: (this.seccionesPaginado.length + 1), secciones: secciones });
                                secciones = [];
                            } else {
                                secciones.push(seccion);
                            }

                        }
                    }
                    this.seccionesvista = this.seccionesPaginado.filter(e => e.pag == 1)[0].secciones;
            } catch (error) {
                console.log(error);
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }

        }

        this.$store.commit('setCargaDatos', false);

    },
    updated() {
       if (document.getElementById('scripthistoria') && this.secciones.length > 0) {
            if (document.getElementById('scripthistoria').innerHTML.length == 0) {
                let s = '';
                for (let i = 0; i < this.secciones.length; i++) {
                    const data = this.secciones[i].campos;
                    if (this.secciones[i].foseScript) {
                        s = s + this.secciones[i].foseScript;
                    }

                    if (data) {

                        for (let j = 0; j < data.length; j++) {
                            if (data[j].foseScript) {
                                s = s + data[j].foseScript;
                            }
                        }
                    }

                }
                this.script = `<script>${ s }<${"/"}script>`;
                postscribe('#scripthistoria', this.script);
            }

        }

    },

    methods: {

        atras() {

            if (this.pag != 1) {
                this.pag = this.pag - 1;
                this.seccionesvista = this.seccionesPaginado.find(e => e.pag == this.pag).secciones;

            }

        },
        siguiente() {

            if (this.pag < this.seccionesPaginado.length) {
                this.pag = this.pag + 1;
                this.seccionesvista = this.seccionesPaginado.find(e => e.pag == this.pag).secciones;

            }
        },
        getValues(values) {
            const lista = [];

            var datos = values.split('|');
            for (let i = 0; i < datos.length; i++) {
                let d = datos[i];
                lista.push({ texts: d.split(';')[0], values: d.split(';')[1] });
            }
            return lista;

        },

        async agregar() {
            this.loading = true;
            for (let i = 0; i < this.formFormulario.length; i++) {
                const element = this.formFormulario[i];
                for (let j = 0; j < this.formFormularioValidado.length; j++) {
                    const elementv = this.formFormularioValidado[j];
                    if (element.foseId == elementv.foseId) {
                        elementv.ciffValor = element.ciffValor;
                    }

                }
            }
            this.$v.formFormularioValidado.$touch();
            this.$v.formFormulario.$touch();

            if (!this.$v.formFormularioValidado.$error) {
                try {
                    this.$apiService.update("cita/asistenciales/atender/updateOrden/" + this.$route.params.id + "?tipo=TP_ORD_LAB" , this.formOrden,this.token).then(data => data);
                    if (this.isNew) {
                            let data = await this.$apiService.create(this.url + "create?cifaId=" + this.$route.params.cifaId, this.formFormulario).then(data => data);
                            if (data) {
                                this.$router.push({ name: 'Laboratoriopanel', params: { id: this.$route.params.cifaId } });
                                this.$swal.fire({
                                    title: "Completado!",
                                    text: "Registro exitoso",
                                    icon: "success",
                                    confirmButtonText: "Ok"
                                });
                            }
                    } else {
                            let data = await this.$apiService.update(this.url + "update/" + this.$route.params.id, this.formFormulario).then(data => data);
                            if (data) {
                                this.$router.push({ name: 'Laboratoriopanel', params: { id: this.$route.params.cifaId } });
                                this.$swal.fire({
                                    title: "Completado!",
                                    text: "Registro exitoso",
                                    icon: "success",
                                    confirmButtonText: "Ok"
                                });
                            }
                    }
                } catch (error) {
                    this.loading = false;
                    console.log(error);
                    this.$swal.fire({
                        title: "Algo sucedio!",
                        text: "contacta con el administrador",
                        icon: "error"
                    });
                }
            } else {
                this.$swal.fire({
                    title: "Campos vacios de la historia!",
                    text: "por favor diligenciarlos",
                    icon: "warning"
                });
            }
            this.loading = false;
        },

        limpiar() {
            this.formFormulario = [];
        }
    }
};


</script>

<style scoped>
#scroll-historia {
    max-height: 700px !important;
    overflow: auto;
}
</style>
