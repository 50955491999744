<template>
    <div v-if="form">
        <v-toolbar dense flat class="primary darken-4" dark>
            <v-toolbar-title>Panel de Atenciones</v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-btn text class="white" color="black" :to="{ name: 'Atenciones' }">
                <v-icon>mdi-chevron-left</v-icon>
                regresar
            </v-btn>
        </v-toolbar>

        <v-tabs v-model="tab" background-color="grey darken-3 accent-4" centered dark icons-and-text>
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#tab-1">
                Historia
                <v-icon>mdi-ballot-outline</v-icon>
            </v-tab>
            <v-tab href="#tab-2">
                Diagnostico
                <v-icon>mdi-plus-box-multiple</v-icon>
            </v-tab>
            <v-tab href="#tab-4">
                Recetario
                <v-icon>mdi-pill</v-icon>
            </v-tab>
            <v-tab href="#tab-3">
                Ordenes
                <v-icon>mdi-page-next</v-icon>
            </v-tab>
            <v-tab href="#tab-5">
                Remisiones
                <v-icon>mdi-account-arrow-right</v-icon>
            </v-tab>
            <v-tab href="#tab-6">
                Incapacidad
                <v-icon>mdi-account-heart</v-icon>
            </v-tab>
            <v-tab href="#tab-7">
                Certificado
                <v-icon>mdi-certificate</v-icon>
            </v-tab>
        </v-tabs>
        <v-card class="pa-2" outlined tile>
            <v-container>
                <v-row class="caption" no-gutters v-if="form.tblCitaFacturaciones">
                    <v-col cols="12" sm="12" md="4">
                        <label class="primary--text">PACIENTE: </label>

                        <label class="font-weight-black" v-if="form.tblConfPersonaspacientes.length > 0">{{
                form.tblConfPersonaspacientes[0].tblConfPersonasnaturale
                  .copnNombre1 +
                " " +
                form.tblConfPersonaspacientes[0].tblConfPersonasnaturale
                  .copnApellido1
              }}</label>
                    </v-col>
                    <v-col cols="12" sm="12" md="4">
                        <label class="primary--text">EMPLEADO: </label>

                        <label class="font-weight-black" v-if="form.tblConfPersonasempleados.length > 0">{{
                form.tblConfPersonasempleados[0].tblConfPersonasnaturale
                  .copnNombre1 +
                " " +
                form.tblConfPersonasempleados[0].tblConfPersonasnaturale
                  .copnApellido1
              }}</label>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" v-if="form.tblConfPersonaspacientes.length > 0">
                        <label class="primary--text">EDAD: </label>

                        <label class="font-weight-black">
                            {{
                new Date().calcularEdad(
                  form.tblConfPersonaspacientes[0].coppFechanacimiento

                )
              }}
                        </label>
                    </v-col>

                    <template v-if="form.tblCitaFacturaciones[0].tblConfBasedatospacientes">
                        <v-col cols="12" sm="12" md="4" v-if="
                form.tblCitaFacturaciones[0].tblConfBasedatospacientes.length >
                0
              ">
                            <label class="primary--text">EPS: </label>

                            <label class="font-weight-black">
                                {{
                  form.tblCitaFacturaciones[0].tblConfBasedatospacientes[0]
                    .tblConfBasedato.tblConfContratos[0]
                    .tblConfPersonasempresas[0].coemNombre
                }}</label>
                        </v-col>
                    </template>
                    <v-col cols="12" sm="12" md="4" v-if="form.tblCitaFacturaciones[0].tblConfContrato">
                        <label class="primary--text">EPS: </label>

                        <label class="font-weight-black">
                            {{
                form.tblCitaFacturaciones[0].tblConfContrato
                  .tblConfPersonasempresas[0].coemNombre
              }}</label>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" v-if="form.jornada">
                        <label class="primary--text">JORNADA: </label>

                        <label class="font-weight-black">{{
              form.jornada.comaNombrelargo
            }}</label>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" v-if="form.turno">
                        <label class="primary--text">TURNO: </label>

                        <label class="font-weight-black">{{
              form.cifaTipoTurno
            }}</label>
                    </v-col>

                    <v-col cols="12" sm="12" md="4" v-if="form.tblCitaFacturaciones">
                        <label class="primary--text">ESTADO: </label>

                        <label class="font-weight-black">
                            {{ form.tblCitaFacturaciones[0].estado.comaNombrecorto }}</label>
                    </v-col>

                    <v-col cols="12" sm="12" md="8">
                        <label class="primary--text">SERVICIO: </label>

                        <label class="font-weight-black" v-if="form.tblConfContratosxservicios.length > 0">{{ form.tblConfContratosxservicios[0].tblConfCup.cocuNombre }} -
                            {{
                form.tblConfContratosxservicios[0].tblConfServicios[0]
                  .coseNombre
              }}</label>
                    </v-col>
                </v-row>
            </v-container>
            <v-speed-dial v-model="fab" absolute direction="bottom" :right="true" transition="slide-y-reverse-transition" v-if="form.citaId">
                <template v-slot:activator>
                    <v-btn v-model="fab" color="primary darken-2" dark fab>
                        <v-icon v-if="fab">mdi-close</v-icon>
                        <v-icon v-else>mdi-menu-open</v-icon>
                    </v-btn>
                </template>
                <v-tooltip left v-if="
            (form.tblCitaFacturaciones[0].estado.comaNombrecorto == 'ABIERTA' &&
              user.role.indexOf('MEDICO') > -1) ||
            user.role.indexOf('ADMIN') > -1
          ">
                    <template v-slot:activator="{ on }">
                        <v-btn fab dark small color="green" v-on="on" :to="{
                name: 'Createhistoria',
                params: {
                  id: form.tblCitaFacturaciones[0].cifaId,
                  servicio: form.tblConfContratosxservicios[0].coseId,
                },
              }">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar historia</span>
                </v-tooltip>
                <v-tooltip left v-if="user.role.indexOf('ADMIN') > -1">
                    <template v-slot:activator="{ on }">
                        <v-btn fab dark small color="red" v-on="on" @click="eliminar(form.tblCitaFacturaciones[0].cifaId)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </template>
                    <span>Eliminar historia</span>
                </v-tooltip>

                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn fab dark small color="orange" v-on="on" @click="pdfRecetarios(form.tblCitaFacturaciones[0].cifaId)">
                            <v-icon>mdi-pill</v-icon>
                        </v-btn>
                    </template>
                    <span>Generar pdf de recetario</span>
                </v-tooltip>
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn fab dark small color="orange" v-on="on" @click="pdfOrdenes('LAB')">
                            <v-icon>mdi-page-next</v-icon>
                        </v-btn>
                    </template>
                    <span>Generar pdf de orden de laboratorios</span>
                </v-tooltip>
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn fab dark small color="orange" v-on="on" @click="pdfOrdenes('IMGDX')">
                            <v-icon>mdi-page-next</v-icon>
                        </v-btn>
                    </template>
                    <span>Generar pdf de orden de imagenes diagnosticas</span>
                </v-tooltip>
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn fab dark small color="orange" v-on="on" @click="pdfOrdenes('SERV')">
                            <v-icon>mdi-page-next</v-icon>
                        </v-btn>
                    </template>
                    <span>Generar pdf de orden de servicios</span>
                </v-tooltip>
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn fab dark small color="orange" v-on="on" @click="pdfOrdenes('CIR')">
                            <v-icon>mdi-page-next</v-icon>
                        </v-btn>
                    </template>
                    <span>Generar pdf de orden cirujias</span>
                </v-tooltip>
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn fab dark small color="orange" v-on="on" @click="pdfRemiciones(form.tblCitaFacturaciones[0].cifaId)">
                            <v-icon>mdi-account-arrow-right</v-icon>
                        </v-btn>
                    </template>
                    <span>Generar pdf de remision</span>
                </v-tooltip>
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn fab dark small color="orange" v-on="on" @click="pdfIncapacidad(form.tblCitaFacturaciones[0].cifaId)">
                            <v-icon>mdi-account-heart</v-icon>
                        </v-btn>
                    </template>
                    <span>Generar pdf de incapacidad</span>
                </v-tooltip>
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn fab dark small color="orange" v-on="on" @click="pdfCertificado(form.tblCitaFacturaciones[0].cifaId)">
                            <v-icon>mdi-certificate</v-icon>
                        </v-btn>
                    </template>
                    <span>Generar pdf de certificado</span>
                </v-tooltip>
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn fab dark small color="orange" v-on="on" @click="pdf(false)">
                            <v-icon>mdi-file-pdf-box</v-icon>
                        </v-btn>
                    </template>
                    <span>Generar pdf de historia</span>
                </v-tooltip>
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn fab dark small color="orange" v-on="on" @click.stop="dialog = true;correo = form.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeEmail">
                            <v-icon> mdi-email-send</v-icon>
                        </v-btn>
                    </template>
                    <span>Enviar pdf de historia</span>
                </v-tooltip>
            </v-speed-dial>
        </v-card>
        <v-tabs-items v-model="tab">

            <v-tab-item value="tab-1">
                <historia :datos="{cifaFecha:form.tblCitaFacturaciones[0].cifaFecha,historial:historial,secciones:secciones,dientesplaca:dientesplaca,primerOdontograma:primerOdontograma,dientesodontograma:dientesodontograma }" v-if="form.citaId && secciones.length > 0" />
            </v-tab-item>
            <v-tab-item value="tab-2">
                <diagnostico :diagnosticos="diagnosticos" v-if="form.citaId && diagnosticos.length > 0" />
            </v-tab-item>
            <v-tab-item value="tab-4">
                <recetario :recetarios="recetarios" v-if="form.citaId && recetarios.length > 0" />
            </v-tab-item>
            <v-tab-item value="tab-3">
                <orden :ordenes="ordenes" v-if="form.citaId && ordenes.length > 0" />
            </v-tab-item>
            <v-tab-item value="tab-5">
                <remision :remisiones="remisiones" v-if="form.citaId && remisiones.length > 0" />
            </v-tab-item>
            <v-tab-item value="tab-6">
                <incapacidad :incapacidades="incapacidades" v-if="form.citaId && incapacidades.length > 0" />
            </v-tab-item>
            <v-tab-item value="tab-7">
                <certificado :certificados="certificados" v-if="form.citaId && certificados.length > 0" />
            </v-tab-item>
        </v-tabs-items>
        <v-dialog v-model="dialog" width="800px" max-width="800px">
            <v-card>
                <v-card-title class="text-h5 green lighten-2"> E-MAILS </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field outlined label="Correo" v-model="correo"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" id="Idbtn" text @click="pdfEmail()" :loading="loadingEmail"> Enviar </v-btn>
                    <v-btn color="primary" text @click="dialog = false"> Cacelar </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import Historia from "./Historia";
import Recetario from "./Recetario";
import Orden from "./Orden";
import Diagnostico from "./Diagnostico";
import Remision from "./Remision";
import Certificado from "./Certificado";
import Incapacidad from "./Incapacidad";

import {
  documentos
}
from "../../utils/documentos";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import generales from '../../mixins/generales.js';
export default {
  components: {
    Historia,
    Recetario,
    Orden,
    Diagnostico,
    Remision,
    Certificado,
    Incapacidad
  },
  mixins: [generales],
  data() {
    return {
      tab: "tab-1",
      url: "cita/citas/",
      token: this.$cookies.get("token"),
      correo: "",
      historiaData: null,
      user: this.$cookies.get("user"),
      fab: false,
      dialog: false,
      Loading: false,
      tipojornada: [],
      tipoturno: [],
      tipoestado: [],
      datosPaciente: null,
      secciones: [],
      campos: [],
      Historia: [],
      historial: [],
      diagnosticos: [],
      ordenes: [],
      recetarios: [],
      remisiones: [],
      incapacidades: [],
      certificados: [],
      contratos: [],
      empresas: [],
      dientesplaca: [],
      primerOdontograma: [],
      dientesodontograma: [],
      vacunas: [],
      pesos: [],
      dataSource:[],
      loadingEmail: false,
      form: {
        citaFecha: new Date().yyyymmdd(),
        citaTipoJornada: null,
        citaTipoTurno: null,
        citaTipoEstado: null,
        coppId: null,
        coprId: null,
        cocsId: null,
        tblConfContratosxservicios: [],
        tblConfPersonasempleados: [],
        tblConfPersonaspacientes: [],
      },
    }
  },

  created: async function() {
    this.initialize();
  },

  methods: {
    async initialize() {
      if (this.$route.params.cifaId) {
        this.editar2(this.$route.params.cifaId);
      } else {
        if (this.$route.params.id) {
          this.editar(this.$route.params.id);
        }
      }
    },
    async editar(id) {
      try {
        if (this.getPermiso("verHistorias")) {
          this.$store.commit('setCargaDatos', true);
          let data = await this.$apiService.index("cita/citas/view/factura/" + id).then(data => data);
          if (data) {

            this.form = data;
            let resultados = await this.$apiService.index("sistema/historiasfacturaciones/generarPdf/" + this.form.citaId).then(data => data);
            if (!resultados.mensaje) {
              this.Historia = resultados.Historia;
              this.historial = resultados.historial;
              this.secciones = resultados.secciones;
              this.diagnosticos = resultados.diagnosticos;
              this.ordenes = resultados.ordenes;
              this.recetarios = resultados.recetarios;
              this.remisiones = resultados.remisiones;
              this.incapacidades = resultados.incapacidades;
              this.certificados = resultados.certificados;
              this.dientesplaca = resultados.dientesplaca;
              this.dientesodontograma = resultados.dientesodontograma;
              this.primerOdontograma = resultados.primerOdontograma;
              this.datosPaciente = resultados.datosPaciente;
              this.pesos = resultados.pesos;
              this.vacunas = resultados.vacunas;
              this.dataSource=resultados.dataSource;
              let data = await this.$apiService.index(`sistema/personas/getFirmaPaciente?copeIdentificacion=${this.datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeId}`).then(data => data);
              if (data.mensaje == undefined && data.mensaje != 'No data') {
                this.datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma = 'data:image/png;base64,' + data;
              } else {
                this.datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma = '/' + this.datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma
              }
            }
          }
          this.$store.commit('setCargaDatos', false);
          this.isNew = false;
        }
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
    },
    async editar2(id) {
      try {
        if (this.getPermiso("verHistorias")) {
          this.$store.commit('setCargaDatos', true);
          let data = await this.$apiService.index("cita/citas/view/factura/" + id).then(data => data);
          if (data) {
            this.form = data;
            let resultados = await this.$apiService.index("sistema/historiasfacturaciones/generarPdf/" + this.form.citaId).then(data => data);
            if (!resultados.mensaje) {
              this.Historia = resultados.Historia;
              this.historial = resultados.historial;
              this.secciones = resultados.secciones;
              this.diagnosticos = resultados.diagnosticos;
              this.ordenes = resultados.ordenes;
              this.recetarios = resultados.recetarios;
              this.remisiones = resultados.remisiones;
              this.incapacidades = resultados.incapacidades;
              this.certificados = resultados.certificados;
              this.dientesplaca = resultados.dientesplaca;
              this.dientesodontograma = resultados.dientesodontograma;
              this.primerOdontograma = resultados.primerOdontograma;
              this.datosPaciente = resultados.datosPaciente;
              this.pesos = resultados.pesos;
              this.vacunas = resultados.vacunas;
              this.dataSource=resultados.dataSource;
              let data = await this.$apiService.index(`sistema/personas/getFirmaPaciente?copeIdentificacion=${this.datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeId}`).then(data => data);
              if (data.mensaje == undefined && data.mensaje != 'No data') {
                this.datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma = 'data:image/png;base64,' + data;
              } else {
                this.datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma = '/' + this.datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma
              }
            }
          }
          this.$store.commit('setCargaDatos', false);
          this.isNew = false;
        }

      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async eliminar(id) {
      try {
        if (this.getPermiso("eliminarHistorias")) {
          var result = this.$swal.fire({
            title: "Estas seguro?",
            text: "No podras revertir esto!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!"
          }).then(result => result.value);
          if (result) {
            let data = await this.$apiService.delete("sistema/historiasfacturaciones/delete/" + id + "/").then(data => data);
            if (data) {
              this.$apiService.delete("cita/asistenciales/atender/delete/" + id + "/orden");
              this.$apiService.delete("cita/asistenciales/atender/delete/" + id + "/diagnostico");
              this.$apiService.delete("cita/asistenciales/atender/delete/" + id + "/recetario");
              this.$apiService.delete("cita/asistenciales/atender/delete/" + id + "/remision");
              // this.listaCopia = this.lista = this.lista.filter(
              //     e => e.tblCitaFacturaciones[0].cifaId != id
              // );
              this.$swal.fire(
                "Eliminado!",
                "ha sido eliminado con exito.",
                "success"
              );

            }
          }
        }

      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async pdf(email = false) {
      if (typeof window !== "undefined") {
        let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("ENTIDAD"));
        let pdf = new jsPDF();
        let imagenesodontologia = {
          imgcanvasplaca: null,
          imgprimerodontogramacanvas: null,
          imgodontogramacanvas: null
        };
        if (this.dientesplaca.length > 0) {
          imagenesodontologia.imgcanvasplaca = document
            .getElementById("imgplaca")
            .getAttribute("src");
        }
        if (this.primerOdontograma.length > 0) {
          imagenesodontologia.imgprimerodontogramacanvas = document
            .getElementById("imgprimerodontograma")
            .getAttribute("src");
        }
        if (this.dientesodontograma.length > 0) {
          imagenesodontologia.imgodontogramacanvas = document
            .getElementById("imgodontograma")
            .getAttribute("src");
        }
        let StrucsDataPaciente = await this.$apiService.index("sistema/maestra/index/TBL_DATOSPACIENTE").then(data => data);
        let StrucDataPaciente =  StrucsDataPaciente.find(e => e.comaNombrecorto == 'DP_HISTORIA').comaValor;
        this.datosPaciente.StrucDataPaciente = StrucDataPaciente;
        let tipoconfig = await this.$apiService.index("sistema/maestra/index/TBL_TIPOCOFIG",this.token).then(data => data);
        let configHistoria = '{}';
        let confOrdenes = '{}'; 
        if(tipoconfig.some(e => e.comaNombrecorto == 'TBL_CONFHISTORIA')){
            configHistoria = tipoconfig.find(e => e.comaNombrecorto == 'TBL_CONFHISTORIA').comaValor;
        }
        if(tipoconfig.some(e => e.comaNombrecorto == 'TBL_CONFORDENES')){
          confOrdenes = tipoconfig.find(e => e.comaNombrecorto == 'TBL_CONFORDENES').comaValor;
        }
        this.datosPaciente.confOrdenes = confOrdenes;
        if (email) {
          this.loading = false;
          return await documento.getHistoria(
            pdf,
            {
              datosPaciente:this.datosPaciente,
              secciones:this.secciones,
              ordenes:this.ordenes,
              diagnosticos:this.diagnosticos,
              remisiones:this.remisiones,
              recetarios:this.recetarios,
              historial:this.historial,
              pesos:this.pesos,
              incapacidades:this.incapacidades,
              certificados:this.certificados,
              vacunas:this.vacunas,
              imagenesodontologia,
              cuerpo:false,
              email,
              configHistoria,
              dataSource:this.dataSource
            }
          );
        }
        documento.getHistoria(
          pdf,
          {
              datosPaciente:this.datosPaciente,
              secciones:this.secciones,
              ordenes:this.ordenes,
              diagnosticos:this.diagnosticos,
              remisiones:this.remisiones,
              recetarios:this.recetarios,
              historial:this.historial,
              pesos:this.pesos,
              incapacidades:this.incapacidades,
              certificados:this.certificados,
              vacunas:this.vacunas,
              imagenesodontologia,
              cuerpo:false,
              email,
              configHistoria,
              dataSource:this.dataSource
            }
        );
      }
    },
    async pdfDiagnosticos() {
      this.loading = true;
      this.diagnosticos = [];
      var datosPaciente = await this.$apiService.index("cita/citas/view/factura/" + this.form.citaId).then(data => data);
      this.diagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + this.form.tblCitaFacturaciones[0].cifaId + "/diagnostico").then(data => data);
      if (this.diagnosticos.length) {
         let StrucsDataPaciente = await this.$apiService.index("sistema/maestra/index/TBL_DATOSPACIENTE").then(data => data);
         let confOrdenes = await this.$apiService.index("sistema/maestra/index/TBL_CONFORDENES").then(data => data);
        let StrucDataPaciente =  StrucsDataPaciente.find(e => e.comaNombrecorto == 'DP_RECETARIO').comaValor;
        datosPaciente.StrucDataPaciente = StrucDataPaciente;
        datosPaciente.confOrdenes = confOrdenes;
        if (typeof window !== 'undefined') {
          let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("ENTIDAD"));
          let pdf = new jsPDF();
          documento.getDiagnosticos(pdf, this.diagnosticos, false, datosPaciente, 2);
        }
        this.loading = false;
      } else {
        this.loading = false;
        this.$swal.fire({
          title: "No hay diagnosticos tramitadas!",
          icon: "error"
        });
      }
    },
    async pdfRecetarios() {
      this.loading = true;
      this.diagnosticos = [];
      var datosPaciente = await this.$apiService.index("cita/citas/view/factura/" + this.form.citaId).then(data => data);
      let data = await this.$apiService.index(`sistema/personas/getFirmaPaciente?copeIdentificacion=${datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeId}?copeIdentificacion=${datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion}`).then(data => data);
      if (data.mensaje == undefined && data.mensaje != 'No data') {
        datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma = 'data:image/png;base64,' + data;
      } else {
        datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma = '/' + datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma
      }
      this.diagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + this.form.tblCitaFacturaciones[0].cifaId + "/diagnostico").then(data => data);
      if (this.recetarios.length) {
        let StrucsDataPaciente = await this.$apiService.index("sistema/maestra/index/TBL_DATOSPACIENTE").then(data => data);
        let StrucDataPaciente =  StrucsDataPaciente.find(e => e.comaNombrecorto == 'DP_RECETARIO').comaValor;
        datosPaciente.StrucDataPaciente = StrucDataPaciente;
        let tipoconfig = await this.$apiService.index("sistema/maestra/index/TBL_TIPOCOFIG",this.token).then(data => data);
       let confOrdenes = '{}'; 
        if(tipoconfig.some(e => e.comaNombrecorto == 'TBL_CONFORDENES')){
          confOrdenes = tipoconfig.find(e => e.comaNombrecorto == 'TBL_CONFORDENES').comaValor;
        }
        datosPaciente.confOrdenes = confOrdenes;
        if (typeof window !== 'undefined') {
          let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("ENTIDAD"));

          let pdf = new jsPDF();
          documento.getRecetarios(pdf, this.recetarios, false, datosPaciente, 2, this.diagnosticos);
        }
        this.loading = false;
      } else {
        this.loading = false;
        this.$swal.fire({
          title: "No hay recetarios tramitadas!",
          icon: "error"
        });
      }
    },
    async pdfOrdenes(tipo) {
      this.loading = true;
      this.diagnosticos = [];
      var datosPaciente = await this.$apiService.index("cita/citas/view/factura/" + this.form.citaId).then(data => data);
      this.diagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + this.form.tblCitaFacturaciones[0].cifaId + "/diagnostico").then(data => data);
      let data = await this.$apiService.index(`sistema/personas/getFirmaPaciente?copeIdentificacion=${datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeId}?copeIdentificacion=${datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion}`).then(data => data);
      if (data.mensaje == undefined && data.mensaje != 'No data') {
        datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma = 'data:image/png;base64,' + data;
      } else {
        datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma = '/' + datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma
      }
      if (this.ordenes.length) {
        let StrucsDataPaciente = await this.$apiService.index("sistema/maestra/index/TBL_DATOSPACIENTE").then(data => data);
        let StrucDataPaciente =  StrucsDataPaciente.find(e => e.comaNombrecorto == 'DP_RECETARIO').comaValor;
        datosPaciente.StrucDataPaciente = StrucDataPaciente;
        let tipoconfig = await this.$apiService.index("sistema/maestra/index/TBL_TIPOCOFIG",this.token).then(data => data);
       let confOrdenes = '{}'; 
        if(tipoconfig.some(e => e.comaNombrecorto == 'TBL_CONFORDENES')){
          confOrdenes = tipoconfig.find(e => e.comaNombrecorto == 'TBL_CONFORDENES').comaValor;
        }
        datosPaciente.confOrdenes = confOrdenes;
        if (typeof window !== 'undefined') {
          let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("ENTIDAD"));

          let pdf = new jsPDF();
          switch (tipo) {
            case "LAB":
            documento.getOrdenesLaboratorios(pdf, this.ordenes, false, datosPaciente, 2, this.diagnosticos);
              break;
              case "IMGDX":
            documento.getOrdenesImagenDiag(pdf, this.ordenes, false, datosPaciente, 2, this.diagnosticos);
              break;
              case "SERV":
            documento.getOrdenesServicios(pdf, this.ordenes, false, datosPaciente, 2, this.diagnosticos);
              break;
              case "CIR":
            documento.getOrdenesCirujia(pdf, this.ordenes, false, datosPaciente, 2, this.diagnosticos);
              break;
          }
        }
        this.loading = false;
      } else {
        this.loading = false;
        this.$swal.fire({
          title: "No hay ordenes tramitadas!",
          icon: "error"
        });
      }
    },
    async pdfRemiciones() {
      this.loading = true;
      this.diagnosticos = [];
      var datosPaciente = await this.$apiService.index("cita/citas/view/factura/" + this.form.citaId).then(data => data);
      this.diagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + this.form.tblCitaFacturaciones[0].cifaId + "/diagnostico").then(data => data);
      let data = await this.$apiService.index(`sistema/personas/getFirmaPaciente?copeIdentificacion=${datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeId}?copeIdentificacion=${datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion}`).then(data => data);
      if (data.mensaje == undefined && data.mensaje != 'No data') {
        datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma = 'data:image/png;base64,' + data;
      } else {
        datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma = '/' + datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma
      } if (this.remisiones.length) {
        let StrucsDataPaciente = await this.$apiService.index("sistema/maestra/index/TBL_DATOSPACIENTE").then(data => data);
        let StrucDataPaciente =  StrucsDataPaciente.find(e => e.comaNombrecorto == 'DP_RECETARIO').comaValor;
        datosPaciente.StrucDataPaciente = StrucDataPaciente;
        let tipoconfig = await this.$apiService.index("sistema/maestra/index/TBL_TIPOCOFIG",this.token).then(data => data);
       let confOrdenes = '{}'; 
        if(tipoconfig.some(e => e.comaNombrecorto == 'TBL_CONFORDENES')){
          confOrdenes = tipoconfig.find(e => e.comaNombrecorto == 'TBL_CONFORDENES').comaValor;
        }
        datosPaciente.confOrdenes = confOrdenes;
        if (typeof window !== 'undefined') {
          let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("ENTIDAD"));

          let pdf = new jsPDF();
          documento.getRemisiones(pdf, this.remisiones, false, datosPaciente, 2, this.diagnosticos);
        }
        this.loading = false;
      } else {
        this.loading = false;
        this.$swal.fire({
          title: "No hay remisiones tramitadas!",
          icon: "error"
        });
      }
    },
    async pdfIncapacidad() {
      this.diagnosticos = [];
      var datosPaciente = await this.$apiService.index("cita/citas/view/factura/" + this.form.citaId).then(data => data);
      this.diagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + this.form.tblCitaFacturaciones[0].cifaId + "/diagnostico").then(data => data);
      let data = await this.$apiService.index(`sistema/personas/getFirmaPaciente?copeIdentificacion=${datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeId}?copeIdentificacion=${datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion}`).then(data => data);
      if (data.mensaje == undefined && data.mensaje != 'No data') {
        datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma = 'data:image/png;base64,' + data;
      } else {
        datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma = '/' + datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma
      }
      if (this.incapacidades.length) {
        let StrucsDataPaciente = await this.$apiService.index("sistema/maestra/index/TBL_DATOSPACIENTE").then(data => data);
        let StrucDataPaciente =  StrucsDataPaciente.find(e => e.comaNombrecorto == 'DP_RECETARIO').comaValor;
        datosPaciente.StrucDataPaciente = StrucDataPaciente;
        let tipoconfig = await this.$apiService.index("sistema/maestra/index/TBL_TIPOCOFIG",this.token).then(data => data);
       let confOrdenes = '{}'; 
        if(tipoconfig.some(e => e.comaNombrecorto == 'TBL_CONFORDENES')){
          confOrdenes = tipoconfig.find(e => e.comaNombrecorto == 'TBL_CONFORDENES').comaValor;
        }
        datosPaciente.confOrdenes = confOrdenes;
        if (typeof window !== 'undefined') {
          let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("ENTIDAD"));

          let pdf = new jsPDF();
          documento.getIncapacidades(pdf, this.incapacidades, false, datosPaciente, 2, this.diagnosticos);
        }
      } else {
        this.$swal.fire({
          title: "No hay incapacidades tramitadas!",
          icon: "error"
        });
      }
    },
    async pdfCertificado() {
      this.loading = true;
      this.diagnosticos = [];
      var datosPaciente = await this.$apiService.index("cita/citas/view/factura/" + this.form.citaId).then(data => data);
      this.diagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + this.form.tblCitaFacturaciones[0].cifaId + "/diagnostico").then(data => data);
      let data = await this.$apiService.index(`sistema/personas/getFirmaPaciente?copeIdentificacion=${datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeId}?copeIdentificacion=${datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion}`).then(data => data);
      if (data.mensaje == undefined && data.mensaje != 'No data') {
        datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma = 'data:image/png;base64,' + data;
      } else {
        datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma = '/' + datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma
      }
      if (this.certificados.length) {
        let StrucsDataPaciente = await this.$apiService.index("sistema/maestra/index/TBL_DATOSPACIENTE").then(data => data);
        let StrucDataPaciente =  StrucsDataPaciente.find(e => e.comaNombrecorto == 'DP_RECETARIO').comaValor;
        datosPaciente.StrucDataPaciente = StrucDataPaciente;
        let tipoconfig = await this.$apiService.index("sistema/maestra/index/TBL_TIPOCOFIG",this.token).then(data => data);
       let confOrdenes = '{}'; 
        if(tipoconfig.some(e => e.comaNombrecorto == 'TBL_CONFORDENES')){
          confOrdenes = tipoconfig.find(e => e.comaNombrecorto == 'TBL_CONFORDENES').comaValor;
        }
        datosPaciente.confOrdenes = confOrdenes;
        if (typeof window !== 'undefined') {
          let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("ENTIDAD"));

          let pdf = new jsPDF();
          documento.getCertificados(pdf, this.certificados, false, datosPaciente, 2, this.diagnosticos);
        }
        this.loading = false;
      } else {
        this.loading = false;
        this.$swal.fire({
          title: "No hay certificados tramitadas!",
          icon: "error"
        });
      }
    },
    async pdfEmail() {

      this.$apiService.usercan(this.token, {
        parent: this.$cookies.get("user").role,
        child: "verHistorias"
      });
      if (this.correo.indexOf('@') < 0) {
        this.$swal.fire({
          title: "Correo invalido!",
          text: "ingrese un correo valido",
          icon: "error"
        });
        return false;
      }
      this.loadingEmail = true;
      this.historiaData = await this.pdf(true);
      let formData = new FormData();
      formData.append('historia', this.historiaData);
      let resultado = await this.$apiService.createFile("cita/citas/sendemail?correo=" + this.correo, formData).then(data => data);
      if (resultado == 'Correo enviado correctamente') {
        this.$swal.fire({
          title: "Completado!",
          text: "Envio exitoso",
          icon: "success",
          confirmButtonText: "Ok"
        });
      } else {
        console.log(resultado);
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
      this.loadingEmail = false;
    },
    limpiar() {
      this.form = {
        citaFecha: new Date().yyyymmdd(),

        citaTipoJornada: null,

        citaTipoTurno: null,

        citaTipoEstado: null,

        coppId: null,

        coprId: null,

        cocsId: null,
        tblConfPersonasempleados: [],
        tblConfPersonaspacientes: [],
      };
    },

  }
}

</script>

<style>
/* This is for documentation purposes and will not be needed in your application */
.v-speed-dial {
  position: absolute !important;
}

</style>
