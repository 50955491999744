
import { fabric } from "fabric";
export default {
    data() {
        return {
            sheetConvenciones: false,
            sheetPlacas:false,
            parteActiva: null,
            dientesplaca: [],
            dientesodontograma: [],
            canvas: null,
            canvasodontograma: null,
            registroplaca:[],
            odontograma:[],
            formOdontograma:[
               
            ],
            formPlaca:[
               
            ],
            partes:[
                { text: 'parte1', img: require('@/assets/PARTE1.png'),},
                { text: 'parte2', img: require('@/assets/PARTE2.png'),},
                { text: 'parte3', img: require('@/assets/PARTE3.png'),},
                { text: 'parte4', img: require('@/assets/PARTE4.png'),},
                { text: 'parte5', img: require('@/assets/PARTE5.png'),},
                { text: 'parte6', img: require('@/assets/PARTE6.png'),},
                { text: 'parte7', img: require('@/assets/PARTE7.png'),},
            ],
            convenciones: [],
        };
    },
    created(){
        
        this.convenciones = this.$config.convenciones;
    },
    computed: {

        formTitle() {
            return this.isNew === true ? "Nuevo" : "Actualizar";
        },
    },
    methods: {
        cargarConvenciones() {
            this.sheetConvenciones = true;
        },
        sanos() {
            let sanos = document.querySelector('#V_HIGIEN_S6_4');
            sanos.value = 0;
            this.dientesodontograma.forEach((diente) => {
                if (
                    diente.edad == 'menor' &&
                    diente.convencion == null &&
                    diente.partes.parteTop.convencion == null &&
                    diente.partes.parteBottom.convencion == null &&
                    diente.partes.parteLeftTop.convencion == null &&
                    diente.partes.parteRightTop.convencion == null &&
                    diente.partes.parteLeftBottom.convencion == null &&
                    diente.partes.parteRightBottom.convencion == null &&
                    diente.partes.parteMedio.convencion == null &&
                    (new Date().getFullYear() - new Date(this.paciente.coppFechanacimiento.replace(/-+/g, '/')).getFullYear()) <= 12) {

                    sanos.value = parseInt(sanos.value) + 1;
                    let event = new Event('input');
                    sanos.dispatchEvent(event);
                }
                if (
                    diente.edad == 'mayor' &&
                    diente.convencion == null &&
                    diente.partes.parteTop.convencion == null &&
                    diente.partes.parteBottom.convencion == null &&
                    diente.partes.parteLeftTop.convencion == null &&
                    diente.partes.parteRightTop.convencion == null &&
                    diente.partes.parteLeftBottom.convencion == null &&
                    diente.partes.parteRightBottom.convencion == null &&
                    diente.partes.parteMedio.convencion == null &&
                    (new Date().getFullYear() - new Date(this.paciente.coppFechanacimiento.replace(/-+/g, '/')).getFullYear()) > 12) {

                    sanos.value = parseInt(sanos.value) + 1;
                    let event = new Event('input');
                    sanos.dispatchEvent(event);
                }
            });
        },
        async quitarconvencion(item) {
            this.loadingDiente = true;
            var img = document.getElementById("imgcanvasodontograma");
            var canvas = document.getElementById("contenedorcanvasodontograma");
            img.setAttribute("hidden",true);
            canvas.removeAttribute("hidden");
            if (/*convencion.partes.find(e => e == 'diente')*/item.ciodParte == null) {
                let teniaconvencion = await this.$apiService.index(`cita/odontologicas/view/${this.factura.cifaId}?tipo=1&diente=${item.ciodDiente}`).then(data => data);
                if (teniaconvencion) {
                    await this.$apiService.delete("cita/odontologicas/delete/" + teniaconvencion.ciodId).then(data => data);
                    this.dientesodontograma.find(e => e.numero == item.ciodDiente).convencion = null;
                    //this.canvasodontograma.remove(e.target);
                    //this.canvasodontograma.renderAll();
                    this.cargarodontograma();
                    //this.sanos();
                    this.odontograma = this.odontograma.filter(e => e != item);
                }
            } else {

                let teniaconvencion = await this.$apiService.index(`cita/odontologicas/view/${this.factura.cifaId}?tipo=1&parte=${item.ciodParte}`).then(data => data);
                if (teniaconvencion) {
                    await this.$apiService.delete("cita/odontologicas/delete/" + teniaconvencion.ciodId).then(data => data);
                    /*if (['CARIADO', 'OBTURADO_RECURRENTE', 'RESINA_RECURRENTE', 'VIDRIO_RECURRENTE'].some(e => e == item.ciodConvencion )) {
                        this.cariados.find(e => e.diente == item.ciodDiente).partes = this.cariados.find(e => e.diente == item.ciodDiente).partes.filter(e => e != item.ciodParte);
                        if (this.cariados.find(e => e.diente == item.ciodDiente).partes.length == 0) {
                            let cariados = document.querySelector('#V_HIGIEN_S6_1');
                            if (isNaN(parseInt(cariados.value))) cariados.value = 1;
                            cariados.value = parseInt(cariados.value) - 1;
                            let event = new Event('input');
                            cariados.dispatchEvent(event);
                        }
                    }
                    if (['OBTURADO', 'RESINA', 'VIDRIO'].some(e => e == item.ciodConvencion)) {
                        this.obturados.find(e => e.diente == item.ciodDiente).partes = this.obturados.find(e => e.diente == item.ciodDiente).partes.filter(e => e != item.ciodParte);
                        if (this.obturados.find(e => e.diente == item.ciodDiente).partes.length == 0) {
                            let obturados = document.querySelector('#V_HIGIEN_S6_2');
                            if (isNaN(parseInt(obturados.value))) obturados.value = 1;
                            obturados.value = parseInt(obturados.value) - 1;
                            let event = new Event('input');
                            obturados.dispatchEvent(event);
                        }
                    }*/

                    let d = this.dientesodontograma.find(e => e.numero == item.ciodDiente);
                    if (d.partes.parteTop.id == item.ciodParte) d.partes.parteTop.convencion = null;
                    if (d.partes.parteBottom.id == item.ciodParte) d.partes.parteBottom.convencion = null;
                    if (d.partes.parteLeftTop.id == item.ciodParte) d.partes.parteLeftTop.convencion = null;
                    if (d.partes.parteRightTop.id == item.ciodParte) d.partes.parteRightTop.convencion = null;
                    if (d.partes.parteLeftBottom.id == item.ciodParte) d.partes.parteLeftBottom.convencion = null;
                    if (d.partes.parteRightBottom.id == item.ciodParte) d.partes.parteRightBottom.convencion = null;
                    if (d.partes.parteMedio.id == item.ciodParte) d.partes.parteMedio.convencion = null;
                    //this.canvasodontograma.remove(e.target);
                    //this.canvasodontograma.renderAll();
                    this.cargarodontograma();
                    
                    //this.sanos();
                    this.odontograma = this.odontograma.filter(e => e != item);
                }
            }

            window.setTimeout( () => {
                img.removeAttribute("hidden");
                img.setAttribute("src" , this.canvasodontograma.toDataURL("image/png"));
                canvas.setAttribute("hidden",true);

            },2000);
            
            if(typeof cantidad != 'undefined'){
                this.cargarplaca();
            }
            this.loadingDiente = false;
        },
        async colocarconvencion() {
             this.$v.formOdontograma.$touch();
          
            if (!this.$v.formOdontograma.$error) {

            var img = document.getElementById("imgcanvasodontograma");
            var canvas = document.getElementById("contenedorcanvasodontograma");
            img.setAttribute("hidden",true);
            canvas.removeAttribute("hidden");
            this.loadingDiente = true;
            /*let form = {
                ciodConvencion: convencion.value,
                ciodDiente: this.parteActiva.numero,
                ciodParte: convencion.partes.find(e => e == 'diente') ? null : this.parteActiva.id,
                cifaId: this.factura.cifaId,
                ciodPlaca: 0,
                ciodTipo: 1
            };*/
           for (const diente of this.formOdontograma) {
               let guardo = await this.$apiService.create("cita/odontologicas/create", diente).then(data => data);
            
            if (guardo) {
                this.odontograma.push(guardo);
                let convencion = this.convenciones.find( e => e.value == diente.ciodConvencion);
                if (convencion.partes.find(e => e == 'diente')) {
                    this.dientesodontograma.find(e => e.numero == diente.ciodDiente).convencion = convencion.value;
                    let img = new Image();

                    img.src = convencion.img;
                    var dienteConvencion = new fabric.Image(img, {
                        left: this.dientesodontograma.find(e => e.numero == diente.ciodDiente).partes.parteMedio.left - 38,
                        top: this.dientesodontograma.find(e => e.numero == diente.ciodDiente).partes.parteMedio.top - 38,
                        hoverCursor: 'pointer',
                        scaleX: 0.15,
                        scaleY: 0.15,
                        selectable: false,
                        lockMovementX: true,
                        lockMovementY: true,
                        lockRotation: true,
                        lockScalingX: true,
                        lockScalingY: true,
                        lockSkewingX: true,
                        lockSkewingY: true,
                    })/*.on('mousedblclick', (e) => this.quitarconvencion(e, this.parteActiva, convencion))*/;
                    this.canvasodontograma.add(dienteConvencion);
                } else {
                   /* if (['CARIADO', 'OBTURADO_RECURRENTE', 'RESINA_RECURRENTE', 'VIDRIO_RECURRENTE'].some(e => e == convencion.value)) {
                        if (this.cariados.length > 0) {
                            if (!this.cariados.some(e => e.diente == diente.ciodDiente)) {
                                this.cariados.push({ diente:diente.ciodDiente , partes: [diente.ciodParte] });
                                let cariados = document.querySelector('#V_HIGIEN_S6_1');
                                if (isNaN(parseInt(cariados.value))) cariados.value = 0;
                                cariados.value = parseInt(cariados.value) + 1;
                                let event = new Event('input');
                                cariados.dispatchEvent(event);
                            } else {
                                this.cariados.find(e => e.diente == diente.ciodDiente).partes.push(diente.ciodParte);
                            }
                        } else {
                            this.cariados.push({ diente: diente.ciodDiente, partes: [diente.ciodParte] });
                            let cariados = document.querySelector('#V_HIGIEN_S6_1');
                            if (isNaN(parseInt(cariados.value))) cariados.value = 0;
                            cariados.value = parseInt(cariados.value) + 1;
                            let event = new Event('input');
                            cariados.dispatchEvent(event);
                        }

                    }
                    if (['OBTURADO', 'RESINA', 'VIDRIO'].some(e => e == convencion.value)) {
                        if (this.obturados.length > 0) {
                            if (!this.obturados.some(e => e.diente == diente.ciodDiente)) {
                                this.obturados.push({ diente: diente.ciodDiente, partes: [diente.ciodParte] });
                                let obturados = document.querySelector('#V_HIGIEN_S6_2');
                                if (isNaN(parseInt(obturados.value))) obturados.value = 0;
                                obturados.value = parseInt(obturados.value) + 1;
                                let event = new Event('input');
                                obturados.dispatchEvent(event);
                            } else {
                                this.obturados.find(e => e.diente == diente.ciodDiente).partes.push(diente.ciodParte);
                            }
                        } else {
                            this.obturados.push({ diente: diente.ciodDiente, partes: [diente.ciodParte] });
                            let obturados = document.querySelector('#V_HIGIEN_S6_2');
                            if (isNaN(parseInt(obturados.value))) obturados.value = 0;
                            obturados.value = parseInt(obturados.value) + 1;
                            let event = new Event('input');
                            obturados.dispatchEvent(event);
                        }
                    }*/
                    let img = new Image();
                    img.src = convencion.img;
                    this.canvasodontograma.add(new fabric.Image(img, {
                        left: this.dientesodontograma.find(e => e.numero == diente.ciodDiente).partesArray.find(e => e.id == diente.ciodParte).left - 12,
                        top: this.dientesodontograma.find(e => e.numero == diente.ciodDiente).partesArray.find(e => e.id == diente.ciodParte).top - 13,
                        hoverCursor: 'pointer',
                        scaleX: 0.05,
                        scaleY: 0.05,
                        selectable: false,
                        lockMovementX: true,
                        lockMovementY: true,
                        lockRotation: true,
                        lockScalingX: true,
                        lockScalingY: true,
                        lockSkewingX: true,
                        lockSkewingY: true,
                    })/*.on('mousedblclick', (e) => this.quitarconvencion(e, this.parteActiva, convencion))*/);
                    //this.canvasodontograma.renderAll();
                }
                
            }
            
           }  
           //this.sanos();
           
           img.removeAttribute("hidden");
           img.setAttribute("src" , this.canvasodontograma.toDataURL("image/png"));
           canvas.setAttribute("hidden",true);
           this.sheetConvenciones = false;
           this.formOdontograma = [];
           this.agregarDiente();
           let cantidad = document.querySelector('#V_HIGIEN_S11_1');
           if(typeof cantidad != 'undefined'){
            this.cargarplaca();
           }
           this.loadingDiente = false;
            }
        },
        async colocarplaca() {
            this.loadingDiente = true;
            try {
                this.$v.formPlaca.$touch();
          
            if (!this.$v.formPlaca.$error) {
                var img = document.getElementById("imgcanvasplaca");
                var canvas = document.getElementById("contenedorcanvasplaca");
                img.setAttribute("hidden",true);
                canvas.removeAttribute("hidden");
                for (const diente of this.formPlaca.filter(e => !this.registroPlacaActual.some(a => a.ciodParte == e.ciodParte))) {
                    let teniaplaca = await this.$apiService.index(`cita/odontologicas/view/${this.factura.cifaId}?tipo=0&parte=${diente.ciodParte}`).then(data => data);
                    let dientesplaca = this.dientesplaca.filter( e =>   this.dientesodontograma.filter(a => ['ENDODONCIA_REALIZADA','ENDODONCIA_INDICADA','PROCEDIMIENTO_REALIZADO','DIENTE_SANO',null].some(i => i == a.convencion) ).some(a => a.numero == e.numero) );
                    dientesplaca = dientesplaca.filter(e => (e.edad == 'menor' &&  (new Date().getFullYear() - new Date(this.paciente.coppFechanacimiento.replace(/-+/g, '/')).getFullYear()) <= 12) || (e.edad == 'mayor' &&  (new Date().getFullYear() - new Date(this.paciente.coppFechanacimiento.replace(/-+/g, '/')).getFullYear()) > 12));
                    
                    if (!teniaplaca) {
                        let form = {
                            ciodDiente: diente.ciodDiente,
                            ciodParte: diente.ciodParte,
                            cifaId: this.factura.cifaId,
                            ciodPlaca: 1,
                            ciodTipo: 0
                        };
                        let guardo = await this.$apiService.create("cita/odontologicas/create", form).then(data => data);
                        if (guardo) {
                            this.registroPlacaActual.push(guardo);
                            let d = this.dientesplaca.find(e => e.numero == diente.ciodDiente);
                            if (d) {
                                if (d.partes.parteLeftTop.id == diente.ciodParte) d.partes.parteLeftTop.placa = 1;
                                if (d.partes.parteRightTop.id == diente.ciodParte) d.partes.parteRightTop.placa = 1;
                                if (d.partes.parteLeftBottom.id == diente.ciodParte) d.partes.parteLeftBottom.placa = 1;
                                if (d.partes.parteRightBottom.id == diente.ciodParte) d.partes.parteRightBottom.placa = 1;
                                if (d.partes.parteMedio.id == diente.ciodParte) d.partes.parteMedio.placa = 1;
                            }
                            let cantidad = document.querySelector('#V_HIGIEN_S11_1');
                            if (isNaN(parseInt(cantidad.value))) cantidad.value = 0;
                            cantidad.value = parseInt(cantidad.value) + 1;
                            let event = new Event('input');
                            cantidad.dispatchEvent(event);
                            let porcentaje = document.querySelector('#V_HIGIEN_S11_2');
                            porcentaje.value = ((parseInt(cantidad.value) / (dientesplaca.length * 5)) * 100).toFixed(2);
                            porcentaje.dispatchEvent(event);
                        }
                    }
                }
                this.sheetPlacas = false;
                this.formPlaca = [];
                this.agregarDientePlaca();
                this.cargarplaca();
                //this.canvas.renderAll();
                 window.setTimeout( () => {
                    img.removeAttribute("hidden");
                    img.setAttribute("src" , this.canvas.toDataURL("image/png"));
                    canvas.setAttribute("hidden",true);
                },2000);
            }
            } catch (error) {
                console.log(error);
            }
            this.loadingDiente = false;
            //this.objetoActual = e;
            //this.canvas = null;
            //this.cargarplaca();
        },
        async quitarplaca(parte) {
            this.loadingDiente = true;
            try {
                    let dientesplaca = this.dientesplaca.filter( e =>   this.dientesodontograma.filter(a => ['ENDODONCIA_REALIZADA','ENDODONCIA_INDICADA','PROCEDIMIENTO_REALIZADO','DIENTE_SANO',null].some(i => i == a.convencion) ).some(a => a.numero == e.numero) );
                    dientesplaca = dientesplaca.filter(e => (e.edad == 'menor' &&  (new Date().getFullYear() - new Date(this.paciente.coppFechanacimiento.replace(/-+/g, '/')).getFullYear()) <= 12) || (e.edad == 'mayor' &&  (new Date().getFullYear() - new Date(this.paciente.coppFechanacimiento.replace(/-+/g, '/')).getFullYear()) > 12));
                    var img = document.getElementById("imgcanvasplaca");
                    var canvas = document.getElementById("contenedorcanvasplaca");
                    img.setAttribute("hidden",true);
                    canvas.removeAttribute("hidden");

                    await this.$apiService.delete("cita/odontologicas/delete/" + parte.ciodId).then(data => data);
                    this.registroPlacaActual = this.registroPlacaActual.filter(e => e != parte);
                    let d = this.dientesplaca.find(e => e.numero == parte.ciodDiente);
                    if (d) {
                        if (d.partes.parteLeftTop.id == parte.ciodParte) d.partes.parteLeftTop.placa = 0;
                        if (d.partes.parteRightTop.id == parte.ciodParte) d.partes.parteRightTop.placa = 0;
                        if (d.partes.parteLeftBottom.id == parte.ciodParte) d.partes.parteLeftBottom.placa = 0;
                        if (d.partes.parteRightBottom.id == parte.ciodParte) d.partes.parteRightBottom.placa = 0;
                        if (d.partes.parteMedio.id == parte.ciodParte) d.partes.parteMedio.placa = 0;
                    }
                    let cantidad = document.querySelector('#V_HIGIEN_S11_1');
                    if (isNaN(parseInt(cantidad.value))) cantidad.value = 0;
                    cantidad.value = parseInt(cantidad.value) - 1;
                    let event = new Event('input');
                    cantidad.dispatchEvent(event);
                    let porcentaje = document.querySelector('#V_HIGIEN_S11_2');
                    porcentaje.value = ((parseInt(cantidad.value) / (dientesplaca.length * 5)) * 100).toFixed(2);
                    porcentaje.dispatchEvent(event);
                    this.cargarplaca();
                   window.setTimeout( () => {
                        img.removeAttribute("hidden");
                        img.setAttribute("src" , this.canvas.toDataURL("image/png"));
                        canvas.setAttribute("hidden",true);
                   },2000);
            } catch (error) {
                console.log(error);
            }
            this.loadingDiente = false;

            //this.objetoActual = e;
            //this.canvas = null;
            //this.cargarplaca();
        },
        generarDientesplaca(t, l, ini, fin, valor, tipo, tipo2, edad) {
            let dientes = [];
            let left = 0;
            for (let i = ini; i < fin; i++) {
                let parteTop = {
                    numero: valor,
                    convencion: null,
                    id: "parte6-diente-" + valor,
                    name: "parte6",
                    backgroundColor: "#FFFFFF",
                    top: t + 63,
                    left: l + 53,

                };
                let parteBottom = {
                    numero: valor,
                    convencion: null,
                    id: "parte7-diente-" + valor,
                    name: "parte7",
                    backgroundColor: "#FFFFFF",
                    top: t + 113,
                    left: l + 53,
                };
                let parteLeftTop = {
                    numero: valor,
                    placa: 0,
                    convencion: null,
                    id: "parte1-diente-" + valor,
                    name: "parte1",
                    backgroundColor: "#FFFFFF",
                    top: t + 88,
                    left: l + 38,
                };
                let parteRightTop = {
                    numero: valor,
                    placa: 0,
                    convencion: null,
                    id: "parte2-diente-" + valor,
                    name: "parte2",
                    backgroundColor: "#FFFFFF",
                    top: t + 73,
                    left: l + 53,
                };
                let parteLeftBottom = {
                    numero: valor,
                    placa: 0,
                    convencion: null,
                    id: "parte3-diente-" + valor,
                    name: "parte3",
                    backgroundColor: "#FFFFFF",
                    top: t + 103,
                    left: l + 53,
                };
                let parteRightBottom = {
                    numero: valor,
                    placa: 0,
                    convencion: null,
                    id: "parte4-diente-" + valor,
                    name: "parte4",
                    backgroundColor: "#FFFFFF",
                    top: t + 88,
                    left: l + 68,
                };
                let parteMedio = {
                    numero: valor,
                    placa: 0,
                    convencion: null,
                    id: "parte5-diente-" + valor,
                    name: "parte5",
                    backgroundColor: "#FFFFFF",
                    top: t + 88,
                    left: l + 53,
                };

                if (i > ini) {
                    left = 70;

                    parteTop.left = parteTop.left + left * (i - ini);
                    parteBottom.left = parteBottom.left + left * (i - ini);
                    parteLeftTop.left = parteLeftTop.left + left * (i - ini);
                    parteRightTop.left = parteRightTop.left + left * (i - ini);
                    parteLeftBottom.left = parteLeftBottom.left + left * (i - ini);
                    parteRightBottom.left = parteRightBottom.left + left * (i - ini);
                    parteMedio.left = parteMedio.left + left * (i - ini);
                }

                dientes.push({
                    id: "diente-" + valor,
                    convencion: null,
                    numero: valor,
                    edad: edad,
                    partes: (tipo2 == 'PLACA') ? {
                        //parteTop: parteTop,
                        //parteBottom: parteBottom,
                        parteLeftTop: parteLeftTop,
                        parteRightTop: parteRightTop,
                        parteLeftBottom: parteLeftBottom,
                        parteRightBottom: parteRightBottom,
                        parteMedio: parteMedio
                    } : {
                        parteTop: parteTop,
                        parteBottom: parteBottom,
                        parteLeftTop: parteLeftTop,
                        parteRightTop: parteRightTop,
                        parteLeftBottom: parteLeftBottom,
                        parteRightBottom: parteRightBottom,
                        parteMedio: parteMedio
                    },
                    partesArray:
                        (tipo2 == 'PLACA') ? [
                         parteLeftTop, parteRightTop, parteLeftBottom,parteRightBottom,parteMedio
                    ] : [
                        parteTop, parteBottom, parteLeftTop, parteRightTop, parteLeftBottom,parteRightBottom,parteMedio
                    
                    ]
                });
                if (tipo == 'negativo') {
                    valor = valor - 1;
                } else {
                    valor = valor + 1;
                }
            }
            return dientes;
        },
        generarDientes(tipo) {
            let dientes = [];
            dientes = dientes.concat(this.generarDientesplaca(0, 0, 0, 8, 18, 'negativo', tipo, 'mayor'));
            dientes = dientes.concat(this.generarDientesplaca(0, 590, 8, 16, 21, 'positivo', tipo, 'mayor'));
            dientes = dientes.concat(this.generarDientesplaca(100, 210, 16, 21, 55, 'negativo', tipo, 'menor'));
            dientes = dientes.concat(this.generarDientesplaca(100, 590, 21, 26, 61, 'positivo', tipo, 'menor'));
            dientes = dientes.concat(this.generarDientesplaca(200, 210, 26, 31, 85, 'negativo', tipo, 'menor'));
            dientes = dientes.concat(this.generarDientesplaca(200, 590, 31, 36, 71, 'positivo', tipo, 'menor'));
            dientes = dientes.concat(this.generarDientesplaca(300, 0, 36, 44, 48, 'negativo', tipo, 'mayor'));
            dientes = dientes.concat(this.generarDientesplaca(300, 590, 44, 52, 31, 'positivo', tipo, 'mayor'));
            return dientes;
        },
        cargarplaca() {
            let cantidad = document.querySelector('#V_HIGIEN_S11_1');
            let arrayPlaca = (this.registroPlacaActual.length > 0) ? this.registroPlacaActual : this.registroPlacaAnterior;
            let dientesplaca = this.dientesplaca.filter( e =>   this.dientesodontograma.filter(a => ['ENDODONCIA_REALIZADA','ENDODONCIA_INDICADA','PROCEDIMIENTO_REALIZADO','EROCION_DOBLE','DIENTE_PROVISIONAL_MAL_ESTADO'].some(i => i != a.convencion) ).some(a => a.numero == e.numero) );
            //dientesplaca = dientesplaca.filter(e => (e.edad == 'menor' &&  (new Date().getFullYear() - new Date(this.paciente.coppFechanacimiento.replace(/-+/g, '/')).getFullYear()) <= 12) || (e.edad == 'mayor' &&  (new Date().getFullYear() - new Date(this.paciente.coppFechanacimiento.replace(/-+/g, '/')).getFullYear()) > 12));
            cantidad.value = arrayPlaca.length;
            let event = new Event('input');
            cantidad.dispatchEvent(event);
            let porcentaje = document.querySelector('#V_HIGIEN_S11_2');
            porcentaje.value = ((parseInt(cantidad.value) / (dientesplaca.length * 5)) * 100).toFixed(2);
            porcentaje.dispatchEvent(event);
            this.canvas = new fabric.Canvas(document.querySelector("#canvasplaca"), {
                height: 500,
                width: 1200,
                selectionColor: "#90ccb7",
                backgroundColor: "#fff"
            });
            for (let diente of dientesplaca ) {
                var text = new fabric.Text(diente.numero + '', { left: diente.partes.parteRightTop.left - 10, top: diente.partes.parteRightTop.top - 35, fontSize: 10 });
                this.canvas.add(text);
                var rect1 = new fabric.Rect({
                    hoverCursor: 'pointer',
                    originX: 'center',
                    originY: 'center',
                    angle: 45,
                    selectable: false,
                    stroke: '#000000',
                    width: 20,
                    height: 20,
                    left: diente.partes.parteLeftTop.left,
                    top: diente.partes.parteLeftTop.top,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    fill: (diente.partes.parteLeftTop.placa) ? '#FF0000' : diente.partes.parteLeftTop.backgroundColor,
                    id: diente.partes.parteLeftTop.id,
                }).on('mousedblclick', (e) => this.colocarplaca(e, diente.partes.parteLeftTop));
                var rect2 = new fabric.Rect({
                    hoverCursor: 'pointer',
                    originX: 'center',
                    originY: 'center',
                    angle: 45,
                    selectable: false,
                    stroke: '#000000',
                    width: 20,
                    height: 20,
                    left: diente.partes.parteRightTop.left,
                    top: diente.partes.parteRightTop.top,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    fill: (diente.partes.parteRightTop.placa) ? '#FF0000' : diente.partes.parteRightTop.backgroundColor,
                    id: diente.partes.parteRightTop.id,
                }).on('mousedblclick', (e) => this.colocarplaca(e, diente.partes.parteRightTop));
                var rect3 = new fabric.Rect({
                    hoverCursor: 'pointer',
                    originX: 'center',
                    originY: 'center',
                    angle: 45,
                    selectable: false,
                    stroke: '#000000',
                    width: 20,
                    height: 20,
                    left: diente.partes.parteLeftBottom.left,
                    top: diente.partes.parteLeftBottom.top,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    fill: (diente.partes.parteLeftBottom.placa) ? '#FF0000' : diente.partes.parteLeftBottom.backgroundColor,
                    id: diente.partes.parteLeftBottom.id,
                }).on('mousedblclick', (e) => this.colocarplaca(e, diente.partes.parteLeftBottom));
                var rect4 = new fabric.Rect({
                    hoverCursor: 'pointer',
                    originX: 'center',
                    originY: 'center',
                    angle: 45,
                    selectable: false,
                    stroke: '#000000',
                    width: 20,
                    height: 20,
                    left: diente.partes.parteRightBottom.left,
                    top: diente.partes.parteRightBottom.top,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    fill: (diente.partes.parteRightBottom.placa) ? '#FF0000' : diente.partes.parteRightBottom.backgroundColor,
                    id: diente.partes.parteRightBottom.id,
                }).on('mousedblclick', (e) => this.colocarplaca(e, diente.partes.parteRightBottom));
                var circulo = new fabric.Circle({
                    hoverCursor: 'pointer',
                    originX: 'center',
                    originY: 'center',
                    stroke: '#000000',
                    selectable: false,
                    absolutePositioned: true,
                    radius: 12,
                    left: diente.partes.parteMedio.left,
                    top: diente.partes.parteMedio.top,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    fill: (diente.partes.parteMedio.placa) ? '#FF0000' : diente.partes.parteMedio.backgroundColor,
                    id: diente.partes.parteMedio.id,
                }).on('mousedblclick', (e) => this.colocarplaca(e, diente.partes.parteMedio));
                
                this.canvas.add(rect1);
                this.canvas.add(rect2);
                this.canvas.add(rect3);
                this.canvas.add(rect4);
                this.canvas.add(circulo);

            }
            this.canvas.renderAll();

        },
        cargarodontograma() {
            this.canvasodontograma = new fabric.Canvas(document.querySelector("#canvasodontograma"), {
                height: 500,
                width: 1200,
                selectionColor: "#90ccb7",
                backgroundColor: "#fff"
            });
            
            this.canvasodontograma.renderAll();
            //let cariados = document.querySelector('#V_HIGIEN_S6_1');
           // let obturados = document.querySelector('#V_HIGIEN_S6_2');
            //let sanos = document.querySelector('#V_HIGIEN_S6_4');
            //obturados.value = 0;
            //cariados.value = 0;
            //sanos.value = 0;
            //this.cariados = [];
            //obturados = [];
            //this.dientesodontograma.filter(e => e.edad == ((new Date().getFullYear() - new Date(this.paciente.coppFechanacimiento.replace(/-+/g, '/')).getFullYear()) <= 12 ? 'menor' : 'mayor'))
            for (let diente of this.dientesodontograma) {
               /* [diente.partes.parteTop, diente.partes.parteBottom, diente.partes.parteLeftTop, diente.partes.parteRightTop, diente.partes.parteLeftBottom, diente.partes.parteRightBottom, diente.partes.parteMedio].forEach(a => {
                    if (a.convencion) {
                        if (['CARIADO', 'OBTURADO_RECURRENTE', 'RESINA_RECURRENTE', 'VIDRIO_RECURRENTE'].some(e => e == a.convencion)) {
                            if (this.cariados.length > 0) {
                                if (!this.cariados.some(e => e.diente == a.numero)) {
                                    this.cariados.push({ diente: a.numero, partes: [a.id] });

                                    if (isNaN(parseInt(cariados.value))) cariados.value = 0;
                                    cariados.value = parseInt(cariados.value) + 1;
                                    let event = new Event('input');
                                    cariados.dispatchEvent(event);
                                } else {
                                    this.cariados.find(e => e.diente == a.numero).partes.push(a.id);
                                }
                            } else {
                                this.cariados.push({ diente: a.numero, partes: [a.id] });

                                if (isNaN(parseInt(cariados.value))) cariados.value = 0;
                                cariados.value = parseInt(cariados.value) + 1;
                                let event = new Event('input');
                                cariados.dispatchEvent(event);
                            }

                        }
                        if (['OBTURADO', 'RESINA', 'VIDRIO'].some(e => e == a.convencion)) {
                            if (this.obturados.length > 0) {
                                if (!this.obturados.some(e => e.diente == a.numero)) {
                                    this.obturados.push({ diente: a.numero, partes: [a.id] });
                                    let obturados = document.querySelector('#V_HIGIEN_S6_2');
                                    if (isNaN(parseInt(obturados.value))) obturados.value = 0;
                                    obturados.value = parseInt(obturados.value) + 1;
                                    let event = new Event('input');
                                    obturados.dispatchEvent(event);
                                } else {
                                    
                                    this.obturados.find(e => e.diente == a.numero).partes.push(a.id);
                                }
                            } else {
                                this.obturados.push({ diente: a.numero, partes: [a.id] });
                                let obturados = document.querySelector('#V_HIGIEN_S6_2');
                                if (isNaN(parseInt(obturados.value))) obturados.value = 0;
                                obturados.value = parseInt(obturados.value) + 1;
                                let event = new Event('input');
                                obturados.dispatchEvent(event);
                            }
                        }

                    }

                });*/

               /* if (
                    diente.edad == 'menor' &&
                    diente.convencion == null &&
                    diente.partes.parteTop.convencion == null &&
                    diente.partes.parteBottom.convencion == null &&
                    diente.partes.parteLeftTop.convencion == null &&
                    diente.partes.parteRightTop.convencion == null &&
                    diente.partes.parteLeftBottom.convencion == null &&
                    diente.partes.parteRightBottom.convencion == null &&
                    diente.partes.parteMedio.convencion == null &&
                    (new Date().getFullYear() - new Date(this.paciente.coppFechanacimiento.replace(/-+/g, '/')).getFullYear()) <= 12) {

                    sanos.value = parseInt(sanos.value) + 1;
                    let event = new Event('input');
                    sanos.dispatchEvent(event);
                }*/
                /*if (
                    diente.edad == 'mayor' &&
                    diente.convencion == null &&
                    diente.partes.parteTop.convencion == null &&
                    diente.partes.parteBottom.convencion == null &&
                    diente.partes.parteLeftTop.convencion == null &&
                    diente.partes.parteRightTop.convencion == null &&
                    diente.partes.parteLeftBottom.convencion == null &&
                    diente.partes.parteRightBottom.convencion == null &&
                    diente.partes.parteMedio.convencion == null &&
                    (new Date().getFullYear() - new Date(this.paciente.coppFechanacimiento.replace(/-+/g, '/')).getFullYear()) > 12) {

                    sanos.value = parseInt(sanos.value) + 1;
                    let event = new Event('input');
                    sanos.dispatchEvent(event);
                }*/

                this.canvasodontograma.add(new fabric.Text(diente.numero + '', { selectable: false, left: diente.partes.parteRightTop.left - 8, top: diente.partes.parteRightTop.top - 40, fontSize: 12 }));
                this.canvasodontograma.add(new fabric.Rect({
                    hoverCursor: 'pointer',
                    originX: 'center',
                    originY: 'center',
                    selectable: false,
                    stroke: '#000000',
                    width: 30,
                    height: 30,
                    left: diente.partes.parteTop.left,
                    top: diente.partes.parteTop.top,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    fill: diente.partes.parteTop.backgroundColor,
                    id: diente.partes.parteTop.id,
                })/*.on('mousedblclick', (e) => this.cargarConvenciones(e, diente.partes.parteTop))*/);
                this.canvasodontograma.add(new fabric.Rect({
                    hoverCursor: 'pointer',
                    originX: 'center',
                    originY: 'center',
                    selectable: false,
                    stroke: '#000000',
                    width: 30,
                    height: 30,
                    left: diente.partes.parteBottom.left,
                    top: diente.partes.parteBottom.top,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    fill: diente.partes.parteBottom.backgroundColor,
                    id: diente.partes.parteBottom.id,
                })/*.on('mousedblclick', (e) => this.cargarConvenciones(e, diente.partes.parteBottom))*/);
                this.canvasodontograma.add(new fabric.Rect({
                    hoverCursor: 'pointer',
                    originX: 'center',
                    originY: 'center',
                    angle: 45,
                    selectable: false,
                    stroke: '#000000',
                    width: 20,
                    height: 20,
                    left: diente.partes.parteLeftTop.left,
                    top: diente.partes.parteLeftTop.top,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    fill: diente.partes.parteLeftTop.backgroundColor,
                    id: diente.partes.parteLeftTop.id,
                })/*.on('mousedblclick', (e) => this.cargarConvenciones(e, diente.partes.parteLeftTop))*/);
                this.canvasodontograma.add(new fabric.Rect({
                    hoverCursor: 'pointer',
                    originX: 'center',
                    originY: 'center',
                    angle: 45,
                    selectable: false,
                    stroke: '#000000',
                    width: 20,
                    height: 20,
                    left: diente.partes.parteRightTop.left,
                    top: diente.partes.parteRightTop.top,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    fill: diente.partes.parteRightTop.backgroundColor,
                    id: diente.partes.parteRightTop.id,
                })/*.on('mousedblclick', (e) => this.cargarConvenciones(e, diente.partes.parteRightTop))*/);
                this.canvasodontograma.add(new fabric.Rect({
                    hoverCursor: 'pointer',
                    originX: 'center',
                    originY: 'center',
                    angle: 45,
                    selectable: false,
                    stroke: '#000000',
                    width: 20,
                    height: 20,
                    left: diente.partes.parteLeftBottom.left,
                    top: diente.partes.parteLeftBottom.top,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    fill: diente.partes.parteLeftBottom.backgroundColor,
                    id: diente.partes.parteLeftBottom.id,
                })/*.on('mousedblclick', (e) => this.cargarConvenciones(e, diente.partes.parteLeftBottom))*/);
                this.canvasodontograma.add(new fabric.Rect({
                    hoverCursor: 'pointer',
                    originX: 'center',
                    originY: 'center',
                    angle: 45,
                    selectable: false,
                    stroke: '#000000',
                    width: 20,
                    height: 20,
                    left: diente.partes.parteRightBottom.left,
                    top: diente.partes.parteRightBottom.top,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    fill: diente.partes.parteRightBottom.backgroundColor,
                    id: diente.partes.parteRightBottom.id,
                })/*.on('mousedblclick', (e) => this.cargarConvenciones(e, diente.partes.parteRightBottom))*/);
                this.canvasodontograma.add(new fabric.Circle({
                    hoverCursor: 'pointer',
                    originX: 'center',
                    originY: 'center',
                    stroke: '#000000',
                    selectable: false,
                    absolutePositioned: true,
                    radius: 12,
                    left: diente.partes.parteMedio.left,
                    top: diente.partes.parteMedio.top,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    fill: diente.partes.parteMedio.backgroundColor,
                    id: diente.partes.parteMedio.id,
                })/*.on('mousedblclick', (e) => this.cargarConvenciones(e, diente.partes.parteMedio))*/);

                /*this.canvasodontograma.add(rect6);
                this.canvasodontograma.add(rect7);
                this.canvasodontograma.add(rect1);
                this.canvasodontograma.add(rect2);
                this.canvasodontograma.add(rect3);
                this.canvasodontograma.add(rect4);
                this.canvasodontograma.add(circulo);*/
                if (diente.convencion) {
                    let img = new Image();
                    img.onload = () => {
                        this.canvasodontograma.add(new fabric.Image(img, {
                            left: diente.partes.parteMedio.left - 38,
                            top: diente.partes.parteMedio.top - 38,
                            scaleX: 0.15,
                            scaleY: 0.15,
                            hoverCursor: 'pointer',
                            selectable: false,
                            lockMovementX: true,
                            lockMovementY: true,
                            lockRotation: true,
                            lockScalingX: true,
                            lockScalingY: true,
                            lockSkewingX: true,
                            lockSkewingY: true,
                        })/*.on('mousedblclick', (e) => this.quitarconvencion(e, diente.partes.parteMedio, this.convenciones.find(e => e.value == diente.convencion)))*/);
                    };
                    img.src = this.convenciones.find(e => e.value == diente.convencion).img;

                }

                if (diente.partes.parteTop.convencion) {
                    let img = new Image();

                    img.onload = () => {
                        this.canvasodontograma.add(new fabric.Image(img, {
                            left: diente.partes.parteTop.left - 12,
                            top: diente.partes.parteTop.top - 13,
                            scaleX: 0.05,
                            scaleY: 0.05,
                            hoverCursor: 'pointer',
                            selectable: false,
                            lockMovementX: true,
                            lockMovementY: true,
                            lockRotation: true,
                            lockScalingX: true,
                            lockScalingY: true,
                            lockSkewingX: true,
                            lockSkewingY: true,
                        })/*.on('mousedblclick', (e) => this.quitarconvencion(e, diente.partes.parteTop, this.convenciones.find(e => e.value == diente.partes.parteTop.convencion)))*/);
                    }
                    img.src = this.convenciones.find(e => e.value == diente.partes.parteTop.convencion).img;
                }
                if (diente.partes.parteBottom.convencion) {
                    let img = new Image();

                    img.onload = () => {
                        this.canvasodontograma.add(new fabric.Image(img, {
                            left: diente.partes.parteBottom.left - 12,
                            top: diente.partes.parteBottom.top - 13,
                            scaleX: 0.05,
                            scaleY: 0.05,
                            hoverCursor: 'pointer',
                            selectable: false,
                            lockMovementX: true,
                            lockMovementY: true,
                            lockRotation: true,
                            lockScalingX: true,
                            lockScalingY: true,
                            lockSkewingX: true,
                            lockSkewingY: true,
                        })/*.on('mousedblclick', (e) => this.quitarconvencion(e, diente.partes.parteBottom, this.convenciones.find(e => e.value == diente.partes.parteBottom.convencion)))*/);
                    }
                    img.src = this.convenciones.find(e => e.value == diente.partes.parteBottom.convencion).img;
                }
                if (diente.partes.parteLeftTop.convencion) {
                    let img = new Image();

                    img.onload = () => {
                        this.canvasodontograma.add(new fabric.Image(img, {
                            left: diente.partes.parteLeftTop.left - 12,
                            top: diente.partes.parteLeftTop.top - 13,
                            scaleX: 0.05,
                            scaleY: 0.05,
                            hoverCursor: 'pointer',
                            selectable: false,
                            lockMovementX: true,
                            lockMovementY: true,
                            lockRotation: true,
                            lockScalingX: true,
                            lockScalingY: true,
                            lockSkewingX: true,
                            lockSkewingY: true,
                        })/*.on('mousedblclick', (e) => this.quitarconvencion(e, diente.partes.parteLeftTop, this.convenciones.find(e => e.value == diente.partes.parteLeftTop.convencion)))*/);
                    }
                    img.src = this.convenciones.find(e => e.value == diente.partes.parteLeftTop.convencion).img;
                }
                if (diente.partes.parteRightTop.convencion) {
                    let img = new Image();

                    img.onload = () => {
                        this.canvasodontograma.add(new fabric.Image(img, {
                            left: diente.partes.parteRightTop.left - 12,
                            top: diente.partes.parteRightTop.top - 13,
                            scaleX: 0.05,
                            scaleY: 0.05,
                            hoverCursor: 'pointer',
                            selectable: false,
                            lockMovementX: true,
                            lockMovementY: true,
                            lockRotation: true,
                            lockScalingX: true,
                            lockScalingY: true,
                            lockSkewingX: true,
                            lockSkewingY: true,
                        })/*.on('mousedblclick', (e) => this.quitarconvencion(e, diente.partes.parteRightTop, this.convenciones.find(e => e.value == diente.partes.parteRightTop.convencion)))*/);
                    }
                    img.src = this.convenciones.find(e => e.value == diente.partes.parteRightTop.convencion).img;
                }
                if (diente.partes.parteLeftBottom.convencion) {
                    let img = new Image();

                    img.onload = () => {
                        this.canvasodontograma.add(new fabric.Image(img, {
                            left: diente.partes.parteLeftBottom.left - 12,
                            top: diente.partes.parteLeftBottom.top - 13,
                            scaleX: 0.05,
                            scaleY: 0.05,
                            hoverCursor: 'pointer',
                            selectable: false,
                            lockMovementX: true,
                            lockMovementY: true,
                            lockRotation: true,
                            lockScalingX: true,
                            lockScalingY: true,
                            lockSkewingX: true,
                            lockSkewingY: true,
                        })/*.on('mousedblclick', (e) => this.quitarconvencion(e, diente.partes.parteLeftBottom, this.convenciones.find(e => e.value == diente.partes.parteLeftBottom.convencion)))*/);
                    }
                    img.src = this.convenciones.find(e => e.value == diente.partes.parteLeftBottom.convencion).img;
                }
                if (diente.partes.parteRightBottom.convencion) {
                    let img = new Image();

                    img.onload = () => {
                        this.canvasodontograma.add(new fabric.Image(img, {
                            left: diente.partes.parteRightBottom.left - 12,
                            top: diente.partes.parteRightBottom.top - 13,
                            scaleX: 0.05,
                            scaleY: 0.05,
                            hoverCursor: 'pointer',
                            selectable: false,
                            lockMovementX: true,
                            lockMovementY: true,
                            lockRotation: true,
                            lockScalingX: true,
                            lockScalingY: true,
                            lockSkewingX: true,
                            lockSkewingY: true,
                        })/*.on('mousedblclick', (e) => this.quitarconvencion(e, diente.partes.parteRightBottom, this.convenciones.find(e => e.value == diente.partes.parteRightBottom.convencion)))*/);
                    }
                    img.src = this.convenciones.find(e => e.value == diente.partes.parteRightBottom.convencion).img;
                }
                if (diente.partes.parteMedio.convencion) {
                    let img = new Image();

                    img.onload = () => {
                        this.canvasodontograma.add(new fabric.Image(img, {
                            left: diente.partes.parteMedio.left - 12,
                            top: diente.partes.parteMedio.top - 13,
                            scaleX: 0.05,
                            scaleY: 0.05,
                            hoverCursor: 'pointer',
                            selectable: false,
                            lockMovementX: true,
                            lockMovementY: true,
                            lockRotation: true,
                            lockScalingX: true,
                            lockScalingY: true,
                            lockSkewingX: true,
                            lockSkewingY: true,
                        })/*.on('mousedblclick', (e) => this.quitarconvencion(e, diente.partes.parteMedio, this.convenciones.find(e => e.value == diente.partes.parteMedio.convencion)))*/);
                    }
                    img.src = this.convenciones.find(e => e.value == diente.partes.parteMedio.convencion).img;
                }

            }
            
        },
    },
};