<style>

</style>

<template>
<div>
    <v-container lighten-5>
        <v-row>
            <v-col cols="auto" md="12">
                <span class="primary--text display-2">Laboratorios</span><br>

            </v-col>
            <v-col cols="auto" md="12">
                <v-data-table :headers="headers" dense :items="lista" :page.sync="page" :items-per-page="10" hide-default-footer sort-desc sort-by="cifaFecha" class="elevation-1">
                    <template v-slot:top>
                        <Dfiltros v-if="filtrar" :onBuscar="buscar" v-bind:filtros="filtros" />
                        <v-toolbar>
                            <div class="flex-grow-1"></div>
                            <v-btn class="mx-2" dark tile color="purple" @click="filtrar=!filtrar">
                                Filtros
                            </v-btn>
                        </v-toolbar>

                    </template>

                    <template v-slot:[`item.tblCitaCita.coppId`]="{ item }">
                        {{ item.tblCitaCita.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnNombre1 + " " +item.tblCitaCita.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido1 }}
                        -<span class="caption">{{item.tblCitaCita.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion}}</span>

                    </template>
                    <template v-slot:[`item.action`]="{item}">

                        <v-btn icon class="mx-2" x-small dark color="info" v-if="version != 'patologia'" @click="pdf(item)">
                            <v-icon dark>mdi-file-pdf-box</v-icon>
                        </v-btn>

                    </template>
                    <template v-slot:no-data>
                        <label class="primary--text">No hay datos disponibles</label>
                    </template>
                </v-data-table>
                <div class="text-center pt-2" v-if="pageCount > 0">
                    <span>total resultados: {{(Count)}}</span>
                    <v-pagination circle v-model="page" @input="buscar(filtros)" @next="buscar(filtros)" @previous="buscar(filtros)" :total-visible="10" :length="pageCount"></v-pagination>

                </div>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import jsPDF from 'jspdf';
import QRCode from 'qrcode'
import 'jspdf-autotable';
import {
    documentos
}
from "../../utils/documentos";

export default {
    name: 'Historialresultados',
    props: {
        coppId: Number
    },
    data() {
        return {
            menu: false,
            dialog: false,
            singleExpand: false,
            isNew: true,
            filtrar:false,
            Count: 0,
            page: 1,
            pageCount: 0,
            version: this.$cookies.get("version"),
            url: "cita/facturaciones/",
            user: this.$cookies.get("user"),
            token: this.$cookies.get("token"),
            
            filtros: [{
                text: "Fecha",
                value: null,
                tipo: "fecha",
                menu: false,
                searchNormal: true,
                isLoading: false,
                /*si es tipo lista descomentar esta parte*/
                /* lista: [],
                listatext: "nombre del campo que se mostrara",
                listavalue: "nombre del valor que se mandara"*/
            }, ],
            headers: [{
                    text: "Id",
                    value: "cifaId"
                }, {
                    text: "Fecha",
                    value: "cifaFecha"
                },
                {
                    text: "Paciente",
                    value: "tblCitaCita.coppId"
                }, {
                    text: "Acciones",
                    value: "action",
                    sortable: false
                }
            ],
            lista: [],
            listaCopia: [],
            empleados: [],
            secciones: [],
            campos: [],
            diagnosticos: [],
            Historia: [],
            historial: [],
        };
    },

    computed: {

        formTitle() {
            return this.isNew === true ? "Nuevo" : "Actualizar";
        }
    },
    watch: {
        dialog(val) {
            val || this.close();
        }
    },
    created: async function () {
        this.$store.commit('setCargaDatos', true);
        await this.buscar(this.filtros);
        this.$store.commit('setCargaDatos', false);
    },

    methods: {

        async search(searchP) {
            this.$store.commit('setCargaDatos', true);
            const data = await this.$apiService.index(`cita/facturaciones/laboratorio/?${searchP}`).then(data => data);
            if (data) {
                this.lista = this.listaCopia = data.rows;
                this.Count = data.count;
                this.pageCount = Math.ceil((data.count) / 10);
            }
            this.$store.commit('setCargaDatos', false);
        },

        async buscar(filtros) {
            this.filtros = filtros;
            this.lista = this.listaCopia;
            var search = `offset=${(this.page-1)*10}&`;

            if (this.filtros[0].value != null && this.filtros[0].value.toString().replace(/ /g, "") != "") {
                search = search + `cifaFecha=${this.filtros[0].value}&`;
            }

            search = search + `coppId=${this.coppId}&`;
            /*  if (this.$cookies.get("user").empleado) {
                  search = search + `coprId=${this.$cookies.get("user").empleado}&`;
              }*/

            if (search != "") {
                await this.search(search);
            }

        },
        async pdf(item) {
            await this.dibujarH(item);
        },
        async dibujarH(item) {
            this.Historia = [];
            this.secciones = [];
            this.campos = [];
            this.diagnosticos = [];
            let datosPaciente = null;
            if (this.version == 'patologia' && item.tblCitaCita.citaTipo != 0) {
                datosPaciente = await this.$apiService.index("cita/citas/view/factura/" + item.citaId).then(data => data);
                this.diagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + item.cifaId + "/diagnostico").then(data => data);
                this.secciones = await this.$apiService.index("sistema/formulariosecciones/secciones/" + item.cocuId + "?LABORATORIO").then(data => data);
                this.Historia = await this.$apiService.index("sistema/formulariosfacturaciones/index/" + item.ciorId + "?tipo=orden").then(data => data);
            } else {
                datosPaciente = await this.$apiService.index("cita/citas/view/factura/" + item.citaId).then(data => data);
                this.diagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + item.cifaId + "/diagnostico").then(data => data);
                datosPaciente.tblCitaFacturaciones[0].tblCitaOrdenes = datosPaciente.tblCitaFacturaciones[0].tblCitaOrdenes.filter(e => e.orden.comaNombrecorto == 'TP_ORD_LAB');
                for (let index = 0; index < datosPaciente.tblCitaFacturaciones[0].tblCitaOrdenes.length; index++) {
                let orden = datosPaciente.tblCitaFacturaciones[0].tblCitaOrdenes[index];
                let s = await this.$apiService.index("sistema/formulariosecciones/secciones/" + orden.cocuId + "?LABORATORIO").then(data => data);

                this.secciones.push({
                    ciorId: orden.ciorId,
                    secciones: s
                });
                let h = await this.$apiService.index("sistema/formulariosfacturaciones/index/" + orden.ciorId + "?tipo=orden").then(data => data);
                this.Historia.push({
                    ciorId: orden.ciorId,
                    Historia: h
                });

                }
            }

            if (this.Historia.length > 0) {
                let StrucsDataPaciente = await this.$apiService.index("sistema/maestra/index/TBL_DATOSPACIENTE").then(data => data);
                let StrucDataPaciente =  StrucsDataPaciente.find(e => e.comaNombrecorto == 'DP_LABORATORIO').comaValor;
                datosPaciente.StrucDataPaciente = StrucDataPaciente;
                if (this.version == 'patologia' && item.tblCitaCita.citaTipo != 0) {
                this.secciones.forEach((seccion) => {
                    if (seccion.campos.length > 0) {
                    seccion.campos.sort((a, b) => a.foseOrden - b.foseOrden);
                    seccion.campos.forEach(campo => {
                        if (this.Historia.find(e => e.foseId == campo.foseId) == undefined) {

                        this.Historia.push({
                            ciffId: null,

                            ciffValor: null,

                            foseId: campo.foseId
                        });
                        }
                    });
                    }

                });
                }
                if (typeof window !== 'undefined') {
                let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("ENTIDAD"));
                let pdf = new jsPDF('p', 'pt', 'letter');
                var firma = '';
                if (this.$cookies.get("TIPO") != 'patologia' || datosPaciente.citaTipo == 0) {
                    if (this.Historia.find(e => e.Historia.length).Historia[0].empleado) {
                    let datafirma = await this.$apiService.index(`sistema/personas/getFirmaPaciente?copeIdentificacion=${this.Historia.find(e => e.Historia.length).Historia[0].empleado.tblConfPersonasnaturale.tblConfPersona.copeId}`).then(data => data);
                    if (datafirma.mensaje == undefined && datafirma.mensaje != 'No data') {
                        firma = 'data:image/png;base64,' + datafirma;
                    } else {
                        firma = (this.$cookies.get("ENTIDAD") != 'talatshi' ? ('/' + this.Historia.find(e => e.Historia.length).Historia[0].empleado.tblConfPersonasnaturale.tblConfPersona.copeFirma) : '/firmas/talatshi/RM 441178.png');
                    }
                    }
                    QRCode.toDataURL("http://149.56.134.140:8004/laboratorio-certificado/" + this.$cookies.get("ENTIDAD") +"/"+(this.$route.params.id ? this.$route.params.id : this.Id)).then(url => {

                        documento.getLaboratorio(pdf, datosPaciente, this.Historia, this.secciones, this.diagnosticos, url, firma);
                    })
                    .catch(err => {
                        console.error(err)
                    });
                } else {
                    if (this.$cookies.get("TIPO") != 'patologia' || this.secciones.find(a => a.campos.find(b => b.foseCodigo == 'F_DEFAULT_0')) == undefined) {
                    if (this.secciones.find(e => e.campos.find(a => a.tblConfFormularioseccione.foseCodigo == 'F_CITO_S6_13'))) {
                        let valor = this.Historia.find(e => e.foseId == this.secciones.find(e => e.campos.find(a => a.tblConfFormularioseccione.foseCodigo == 'F_CITO_S6_13')).campos.find(a => a.tblConfFormularioseccione.foseCodigo == 'F_CITO_S6_13').foseId);
                        if (valor.ciffValor) {
                            let empleados = await this.$apiService.index("sistema/personas/buscar2/empleados/" + valor.ciffValor).then(data => data)
                            let datafirma = await this.$apiService.index(`sistema/personas/getFirmaPaciente?copeIdentificacion=${empleados[0].copeId}`).then(data => data);
                            if (datafirma.mensaje == undefined && datafirma.mensaje != 'No data') {
                            firma = 'data:image/png;base64,' + datafirma;
                            } else {
                            firma = '/firmas/' + this.entidad + '/' + valor.ciffValor + '.png';
                            }
                        }
                    } else {
                        if (this.Historia[0].empleado && this.Historia[0].empleado.tblConfPersonasnaturale.tblConfPersona.copeFirma != null) {
                            let datafirma = await this.$apiService.index(`sistema/personas/getFirmaPaciente?copeIdentificacion=${this.Historia[0].empleado.tblConfPersonasnaturale.tblConfPersona.copeId}`).then(data => data);
                            if (datafirma.mensaje == undefined && datafirma.mensaje != 'No data') {
                            firma = 'data:image/png;base64,' + datafirma;
                            } else {
                            firma = '/' + this.Historia[0].empleado.tblConfPersonasnaturale.tblConfPersona.copeFirma;
                            }
                        }
                    }
                    } else {
                    if (this.$cookies.get("TIPO") == 'patologia' && this.$cookies.get("ENTIDAD") == 'patologia') {
                        let valor = this.Historia.find(e => e.foseId == this.secciones.find(e => e.campos.find(a => a.foseCodigo == 'F_DEFAULT_6')).campos.find(a => a.foseCodigo == 'F_DEFAULT_6').foseId);
                        if (valor.ciffValor) {
                            firma = '/firmas/patologia/' + valor.ciffValor.split(',')[0] + '.jpg';
                        }
                    }

                    if (this.$cookies.get("TIPO") == 'patologia' && this.$cookies.get("ENTIDAD") == 'patologia2') {
                        let valor = this.Historia.find(e => e.foseId == this.secciones.find(e => e.campos.find(a => a.foseCodigo == 'F_DEFAULT_6')).campos.find(a => a.foseCodigo == 'F_DEFAULT_6').foseId);
                        if (valor.ciffValor) {
                            let empleados = await this.$apiService.index("sistema/personas/buscar2/empleados/" + valor.ciffValor.split(',')[0]).then(data => data)
                            let datafirma = await this.$apiService.index(`sistema/personas/getFirmaPaciente?copeIdentificacion=${empleados[0].copeId}`).then(data => data);
                            if (datafirma.mensaje == undefined && datafirma.mensaje != 'No data') {
                            firma = 'data:image/png;base64,' + datafirma;
                            } else {
                            firma = '/firmas/patologia2/' + valor.ciffValor.split(',')[0] + '.jpg';
                            }
                        }
                    }
                    }
                    documento.getDocumento(pdf, datosPaciente, this.Historia, this.secciones, this.diagnosticos,[],'', firma);
                }

                }

            } else {
                this.$swal.fire({
                title: "Historia no tramitada!",
                text: "primero debe ser llenado la historia",
                icon: "error"
                });
            }

        },

    }
};
</script>
