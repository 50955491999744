<template>
<div>
    <v-tabs v-model="tab" background-color="grey darken-3 accent-4" centered dark icons-and-text>
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#tab-1">
            Laboratorios
            <v-icon>mdi-account-box</v-icon>
        </v-tab>
        <v-tab href="#tab-2" v-if="getPermiso('editarLaboratorios') || getPermiso('crearLaboratorios')">
            Procedimientos
            <v-icon>mdi-account-box-multiple</v-icon>
        </v-tab> 

    </v-tabs>
    <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1" >
            <Panellaboratorios v-bind="{Id:id}" />
        </v-tab-item>
        <v-tab-item value="tab-2" v-if="getPermiso('editarLaboratorios') || getPermiso('crearLaboratorios')" >
            <Panelprocedimientos />
        </v-tab-item> 

    </v-tabs-items>

</div>
</template>

<script>
import Panellaboratorios from "./Panel";
import Panelprocedimientos from "./Panelprocedimientos";

import generales from '../../mixins/generales.js';
export default {
    components: {
        Panellaboratorios,
        Panelprocedimientos,
        // Medicamentos
    },
    mixins: [generales],
    data() {
        return {
            tab: "tab-1",
            id: null
        }
    },
    created: async function () {
            if (this.$route.params.tab) {
                this.tab = this.$route.params.tab;
            }
            if (this.$route.params.id) {
                this.id = this.$route.params.id;
            }
    },
}
</script>

<style>

</style>