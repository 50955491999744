<template>
  <v-container lighten-5>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <span class="mx-2 secondary--text display-2 float-left">Alertas del paciente</span>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-btn class="mx-2 float-right" fab small dark color="accent" @click="verFiltros = !verFiltros">
          <v-icon dark>filter_list</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="12" v-if="tabla">
        <v-card elevation="0" class="rounded-xl">
          <v-data-iterator :items="lista" :page.sync="page" :loading="loadingTable" :item-key="tabla.itemKey"
            :items-per-page="10" single-expand hide-default-footer :sort-by="tabla.orderBy" :sort-desc="tabla.orderDesc">
            <template v-slot:header>
              <Dfiltros v-if="verFiltros" :onBuscar="search" v-bind:filtros="filtros" />
              <v-toolbar dark class="mb-1">
                <v-btn class="mx-2 primary--text" tile color="white" @click.stop="dialog = true">
                  agregar
                </v-btn>
                <v-divider class="mx-4" inset vertical></v-divider>
                <div class="flex-grow-1"></div>
              </v-toolbar>
            </template>
            <template v-slot:default="{ items, isExpanded, expand }">
              <CardTable v-bind:table="{ tabla, data: { items, isExpanded, expand } }"></CardTable>
            </template>
            <template v-slot:no-data>
              <v-row class="mt-2" align="center" justify="center">
                <v-col cols="10" sm="10" md="10" lg="10">
                  <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                    No se encontro nada.
                  </v-alert>
                </v-col>
              </v-row>
            </template>
            <template v-slot:loading>
              <v-row class="mt-2" align="center" justify="center">
                <v-col cols="12" sm="12" md="12" lg="12">
                  <Loader />
                </v-col>
              </v-row>
            </template>
            <template v-slot:footer>
              <Paginacion v-on:cambiarPagina="page = $event" :onSearch="search"
                v-bind:propiedades="{ filtros, page, pageCount, Count }" />
            </template>
          </v-data-iterator>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent width="800px" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row v-if="loadingForm" align="center" justify="center">
              <v-col cols="12" sm="12" md="12" lg="12">
                <Loader />
              </v-col>
            </v-row>
            <v-row v-else-if="!loadingGuardar">
              <v-col cols="12" sm="12" md="6">
                <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                  offset-y min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field dense outlined v-model="form.cirpFechainicio" required label="Fecha de inicio"
                      append-icon="event" readonly v-on="on"></v-text-field>
                  </template>
                  <v-date-picker locale="ES" v-model="form.cirpFechainicio" :error-messages="errores['cirpFechainicio']"
                    @input="menu = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-autocomplete dense outlined v-model="form.cirpTipo" :error-messages="errores['cirpTipo']"
                  :items="['RUTA','PATOLOGIA','EVENTO SALUD']"  item-color="primary" label="Tipo de alerta"
                  clearable></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="6" v-if="form.cirpTipo == 'EVENTO SALUD'">
                <v-autocomplete dense outlined v-model="form.cirpRuta" :error-messages="errores['cirpRuta']"
                  :items="tipoeventos" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Evento de salud"
                  clearable></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="6" v-if="form.cirpTipo == 'RUTA'">
                <v-autocomplete dense outlined v-model="form.cirpRuta" :error-messages="errores['cirpRuta']"
                  :items="tiporutas" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Ruta"
                  clearable></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="6" v-if="form.cirpTipo == 'PATOLOGIA'">
                  <v-autocomplete dense outlined :error-messages="errores['cirpRuta']" v-model="form.cirpRuta" :loading="diagnosticos.isLoading" :search-input.sync="diagnosticos.search" hide-no-data hide-selected no-filter :items="diagnosticos.diagnosticos" @keypress.enter="searchCIE10()" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de CIE 10" clearable>
                      <template v-slot:selection="data">
                          <v-list-item-content>
                              <v-list-item-title class="text-wrap" v-html="data.item.comaNombrelargo"></v-list-item-title>
                              <v-list-item-subtitle v-html="data.item.comaNombrecorto"></v-list-item-subtitle>
                          </v-list-item-content>
                      </template>
                      <template v-slot:item="data">
                          <template v-if="(typeof data.item !== 'object')">
                              <v-list-item-content v-text="data.item"></v-list-item-content>
                          </template>
                          <template v-else>
                              <v-list-item-content>
                                  <v-list-item-title class="text-wrap" v-html="data.item.comaNombrelargo"></v-list-item-title>
                                  <v-list-item-subtitle v-html="data.item.comaNombrecorto"></v-list-item-subtitle>
                              </v-list-item-content>
                          </template>
                      </template>
                  </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="12" v-if="!coppId">
                <v-autocomplete dense outlined :error-messages="errores['coppId']" v-model="form.coppId"
                  :items="pacientes.pacientes" :loading="pacientes.isLoading" :search-input.sync="pacientes.search"
                  @keypress.enter="searchPaciente" no-filter hide-no-data hide-selected
                  item-text="tblConfPersonasnaturales[0].copnNombre1" item-value="copeId" item-color="primary"
                  label="Paciente" clearable>
                  <template v-slot:selection="data">
                    {{ `${data.item.tblConfPersonasnaturales[0].copnNombre1 } ${data.item.tblConfPersonasnaturales[0].copnNombre2} ${data.item.tblConfPersonasnaturales[0].copnApellido1 } ${data.item.tblConfPersonasnaturales[0].copnApellido2}`}}
                  </template>
                  <template v-slot:item="data">
                    <template v-if="(typeof data.item !== 'object')">
                      <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.copeIdentificacion">
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="primary darken-1" text v-if="!loadingGuardar" @click="close">Cancelar</v-btn>
          <v-btn color="primary darken-1" dark v-if="!loadingGuardar" @click="agregar">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2" persistent width="800px" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row v-if="loadingForm" align="center" justify="center">
              <v-col cols="12" sm="12" md="12" lg="12">
                <Loader />
              </v-col>
            </v-row>
            <v-row v-else-if="!loadingGuardar">
              <v-col cols="12" sm="12" md="4">
                <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                  offset-y min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field dense outlined v-model="form.cirpFechasalida" required label="Fecha de salida"
                      prepend-icon="event" readonly v-on="on"></v-text-field>
                  </template>
                  <v-date-picker locale="ES" v-model="form.cirpFechasalida" :error-messages="errores['cirpFechasalida']"
                    @input="menu2 = false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="primary darken-1" text v-if="!loadingGuardar" @click="close">Cancelar</v-btn>
          <v-btn color="primary darken-1" dark v-if="!loadingGuardar" @click="agregar">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
 
<script>
import generales from '../../mixins/generales.js';
export default {
  name: "Demanda",
  props: {
    coppId: Number,
  },
  mixins: [generales],
  data() {
    return {
      menu: false,
      menu2: false,
      dialog2: false,
      loading: false,
      isNew: true,
      copeId: null,
      isLoading: false,
      url: "cita/demanda/",
      form: {
        cirpId: null,
        cirpFechainicio: new Date().yyyymmdd(),
        cirpRuta: null,
        coppId: null,
        createdBy: this.$cookies.get("user").id
      },
      tabla: {
        itemKey: 'cirpId',
        orderBy: 'cirpFechainicial',
        orderDesc: true,
        expandible: false,
        expandibleKey: 'cirpId',
        expandibleTitle: () => { return `` },
        expandibleItems: () => { return [] },
        primarios: [{
          titulo: 'PACIENTE',
          tamaño: {
            cols: '12',
            sm: '12',
            md: '12',
            lg: '12'
          },
          class: 'primary--text subtitle-1',
          valor: (item) => { return `${item.paciente.tblConfPersonasnaturales[0].copnNombre1} ${item.paciente.tblConfPersonasnaturales[0].copnApellido1}` },
          show: (item) => { return item ? true : false }
        }, {
          titulo: 'FECHA ENTRADA',
          tamaño: {
            cols: '12',
            sm: '12',
            md: '3',
            lg: '3'
          },
          class: 'green--text subtitle-1',
          valor: (item) => { return item.cirpFechainicio },
          show: (item) => { return item ? true : false }
        },
        {
          titulo: 'FECHA SALIDA',
          tamaño: {
            cols: '12',
            sm: '12',
            md: '3',
            lg: '3'
          },
          class: 'green--text subtitle-1',
          valor: (item) => { return item.cirpFechasalida },
          show: (item) => { return item ? true : false }
        },
        {
          titulo: 'IDENT.',
          tamaño: {
            cols: '12',
            sm: '12',
            md: '3',
            lg: '3'
          },
          class: 'primary--text',
          valor: (item) => { return `${item.paciente.tipoidentificacion.comaNombrecorto}-${item.paciente.copeIdentificacion}` },
          show: (item) => { return item ? true : false }
        },
        {
          titulo: 'SEXO',
          tamaño: {
            cols: '12',
            sm: '12',
            md: '4',
            lg: '4'
          },
          class: 'primary--text',
          valor: (item) => { return item.paciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].sexo.comaNombrelargo },
          show: (item) => { return item ? true : false }
        },
        {
          titulo: 'EDAD',
          tamaño: {
            cols: '12',
            sm: '12',
            md: '4',
            lg: '4'
          },
          class: 'primary--text',
          valor: (item) => { return this.calcularEdad(item.paciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppFechanacimiento) },
          show: (item) => { return item ? true : false }
        },
        {
          titulo: 'TELEFONO',
          tamaño: {
            cols: '12',
            sm: '12',
            md: '4',
            lg: '4'
          },
          class: 'primary--text',
          valor: (item) => { return item.copeTelefono + '-' + item.copeTelalternativo },
          show: (item) => { return item ? true : false }
        },

        ],
        segundarios: [
        {
            titulo: 'TIPO',
            tamaño: {
              cols: '12',
              sm: '12',
              md: '12',
              lg: '12'
            },
            class: 'primary--text',
            valor: (item) => {
              return `${item.cirpTipo}`
            },
            show: (item) => { return item ? true : false }
          },
          {
            titulo: 'ALERTA',
            tamaño: {
              cols: '12',
              sm: '12',
              md: '12',
              lg: '12'
            },
            class: 'primary--text',
            valor: (item) => {
              return `${item.ruta ? item.ruta.comaNombrelargo:''}`
            },
            show: (item) => { return item ? true : false }
          },
        ],
        expanItem: [],
        expanMenu: [],
        menu: [
          {
            title: 'Finalizar',
            icon: 'mdi-block-helper',
            color: 'error',
            tipeAction: 'funcion',
            action: (item) => { return this.finalizar(item) },
            show: (item) => { return item ? (item.cirpFechafinal == null) : false }
          },
          {
            title: 'Eliminar',
            icon: 'delete',
            color: 'error',
            tipeAction: 'funcion',
            action: (item) => { this.eliminar(item.cirpId) },
            show: (item) => { return item ? (this.getPermiso('eliminarDemanda')) || this.user.role.indexOf('ADMIN') > -1 : false }
          },
        ]
      },
      filtros: [{
        campo: "cirpFecha",
        text: "Fecha",
        value: null,
        tipo: "fecha",
        menu: false,
        searchNormal: true,
        isLoading: false,
        /*si es tipo lista descomentar esta parte*/
        /* lista: [],
        listatext: "nombre del campo que se mostrara",
        listavalue: "nombre del valor que se mandara"*/
      },
      {
        campo: "cirpRuta",
        text: "Ruta",
        value: null,
        tipo: "text",
        menu: false,
        searchNormal: true,
        isLoading: false,
        /*si es tipo lista descomentar esta parte*/
        /* lista: [],
        listatext: "nombre del campo que se mostrara",
        listavalue: "nombre del valor que se mandara"*/
      }, {
        campo: "coppId",
        text: "Paciente",
        value: null,
        tipo: "lista",
        /*si es tipo lista descomentar esta parte*/
        lista: [],
        listatext: "copeIdentificacion",
        listavalue: "copeId",
        buscar: 'paciente',
        isLoading: false,
        search: null,
        //filter: (item, queryText, itemText) => (item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + item.tblConfPersonasnaturales[0].copnApellido2).indexOf(queryText.toUpperCase()) > -1 || item.copeIdentificacion.indexOf(queryText) > -1,
        default: true,
        defaultselect: (data) => {
          return data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2
        },
        defaultbody: (data) => {
          return {
            title: data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2,
            subtitle: data.item.copeIdentificacion
          }
        },
      },
      {
        campo: "cirpFecharango",
        text: "Rango de fecha",
        value: [],
        tipo: "rango",
        menu: false,
        searchNormal: true,
        isLoading: false,
        /*si es tipo lista descomentar esta parte*/
        /* lista: [],
        listatext: "nombre del campo que se mostrara",
        listavalue: "nombre del valor que se mandara"*/
      },

      ],
      pacientes: {
        search: null,
        isLoading: false,
        pacientes: []
      },
      diagnosticos: {
        search: null,
        isLoading: false,
        diagnosticos: []
      },
      tiporutas: [],
      tipoeventos: []
    };
  },
  computed: {
    formTitle() {
      return this.isNew === true ? "Nuevo" : "Actualizar";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created: async function () {
    let {
      tiporutas,
      tipoeventos
    } = await this.$apiService.index("cita/demanda/generales").then(data => data);
    this.tiporutas = tiporutas;
    this.tipoeventos = tipoeventos;
    await this.search(this.filtros);
  },
  methods: {
    async search(filtros) {
      this.lista = this.listaCopia = [];
      this.loadingTable = true;
      if (this.coppId) {
        filtros[2].value = this.coppId;
      }
      const data = await this.$apiService.index(`cita/demanda/buscar?${this.filtrar(filtros, this.page)}`).then(data => data);
      if (data) {
        this.lista = this.listaCopia = data.rows;
        this.Count = data.count;
        this.pageCount = Math.ceil((data.count) / 10);
      }
      this.loadingTable = false;
    },
    async searchPaciente() {
      this.pacientes.isLoading = true;
      if (this.pacientes.search.length > 0 && this.pacientes.search.toString().replace(/ /g, "") != "") {
        this.pacientes.pacientes = await this.$apiService.index("sistema/personas/buscar2/pacientes/" + this.pacientes.search.toUpperCase()).then(data => data);
      }
      this.pacientes.isLoading = false;
    },
    async searchCIE10() {
      this.diagnosticos.isLoading = true;
      if (this.diagnosticos.search.length > 0 && this.diagnosticos.search.toString().replace(/ /g, "") != "") {
        this.diagnosticos.diagnosticos = await this.$apiService.index(`sistema/maestra/buscar3/TBL_TIPOSCIE10?comaNombrecorto=${this.diagnosticos.search}&comaNombrelargo=${this.diagnosticos.search}`).then(data => data);
      }
      this.diagnosticos.isLoading = false;
    },
    finalizar(item) {
      this.form = item;
      this.form.cirpFechasalida = new Date().yyyymmdd();
      this.isNew = false;
      this.dialog2 = true;
    },
    async agregar() {
      try {
        this.errores = [];
        this.loadingGuardar = true;
        if (this.coppId) {
          this.form.coppId = this.coppId;
        }
        let data = (this.isNew) ? await this.$apiService.create(`${this.url}create`, this.form).then((data) => data) :
          await this.$apiService.update(`${this.url}update/${this.form.cirpId}`, this.form).then((data) => data);
        if (data) {
          this.$swal.fire({
            title: "Completado!",
            text: "Registro exitoso",
            icon: "success",
            confirmButtonText: "Ok"
          });
          this.close();
          await this.search(this.filtros);
        }
      } catch (error) {
        if (error.response && 422 == error.response.status) {
          this.errores = error.response.data.errors;
        } else {
          if (error.response && 403 == error.response.status) {
            this.$router.push('/403');
          } else {
            if (error.response && error.response.data.errores) {
              this.$swal.fire({
                title: "Error en el excel datos invalidos",
                text: error.response.data.errores,
                icon: "error"
              });
            } else {
              console.log(error)
              this.$swal.fire({
                title: "Algo sucedio!",
                text: "contacta con el administrador",
                icon: "error"
              });
            }
          }
        }
      }
      this.loadingGuardar = false;
    },
    async eliminar(id) {
      try {
        var result = await this.$swal.fire({
          title: "Estas seguro?",
          text: "No podras revertir esto!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, eliminar!"
        }).then(result => result.value);
        if (result) {
          let data = await this.$apiService.delete(this.url + "delete/" + id + "/").then(data => data);
          if (data) {
            await this.search(this.filtros);
            this.$swal.fire(
              "Eliminado!",
              "ha sido eliminado con exito.",
              "success"
            );
          }
        }
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
    },
    close() {
      this.isNew = true;
      this.dialog = false;
      this.dialog2 = false;
      this.form = {
        cirpId: null,
        cirpFechainicio: new Date().yyyymmdd(),
        cirpRuta: null,
        coppId: null,
        createdBy: this.user.id
      };
    }
  },

};

</script>
 
<style></style>
 