<template>
<v-container id="cont" lighten-5>
    <v-row>
        <v-col cols="auto" md="12">
            <v-form @submit.prevent="agregar">
                <v-card class="mb-12">
                    <v-card-title>
                        <span class="headline">Laboratorios</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn color="red darken-1" text :to="{ name: 'Laboratoriopanel',params: { id: $route.params.id} }">Cancelar</v-btn>
                        <v-btn color="primary darken-1" dark :loading="loading" @click="agregar">Guardar</v-btn>
                    </v-card-actions>
                    <v-divider></v-divider>
                    <v-container>
                        <v-row class="white">
                            <v-col cols="auto" md="12">
                                <v-row justify="space-around">
                                    <v-col cols="auto" md="12">
                                        <v-toolbar flat>
                                            <v-toolbar-title>Ordenes</v-toolbar-title>
                                            <v-divider class="mx-4" inset vertical></v-divider>
                                            <v-btn text @click="agregarOrden">Agregar nuevo</v-btn>
                                            <v-divider class="mx-4" inset vertical></v-divider>

                                            <div class="flex-grow-1"></div>
                                        </v-toolbar>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <template v-for="(items,index) in formOrdenes">
                                    <v-row :key="items.ciorId" v-if="formOrdenes.length > 0">
                                        <v-col cols="12" sm="12" md="12">
                                            <v-autocomplete dense outlined required :error="$v.formOrdenes.$each[index].cocuId.$error" v-model="items.cocuId" :loading="items.cups.isLoading" :search-input.sync="items.cups.search" hide-no-data hide-selected no-filter :items="items.cups.cups" @keypress.enter="searchCup(items.cups)" item-text="cocuNombre" item-value="cocuId" item-color="primary" label="Cup" clearable></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <v-textarea dense outlined v-on:keyup="items.ciorObservacion = $event.target.value.toUpperCase()" required v-model="items.ciorObservacion" label="Observacion"></v-textarea>
                                        </v-col>
                                        <!-- <v-col cols="12" sm="12" md="4">
                                            <v-autocomplete dense outlined :error="$v.formOrdenes.$each[index].ciorTipoOrden.$error" required v-model="items.ciorTipoOrden" :items="tipoordenes" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de Orden" clearable></v-autocomplete>
                                        </v-col> -->
                                        
                                        <v-col cols="auto" md="1">
                                            <v-btn text icon color="error" @click="eliminarOrden(items)">
                                                <v-icon>delete</v-icon>
                                            </v-btn>

                                        </v-col>
                                    </v-row>
                                </template>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-form>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import axios from 'axios';
import {
    required
}
from 'vuelidate/lib/validators'
export default {
    data() {
        return {
            isNewOrden: true,
            user: this.$cookies.get("user"),
            url: "sistema/historiasfacturaciones/",
            token: this.$cookies.get("token"),
            
            formOrdenes: [],
            tipoordenes: [],
            loading:false,
        }
    },
    validations: {
        formOrdenes: {
            $each: {
                ciorTipoOrden: {
                    required,
                },
                cocuId: {
                    required,
                },
            }
        },
    },
    created: async function () {
        
        
        this.$store.commit('setCargaDatos', true);
        let [
            tipoordenes,
            tipoprocedimiento,
        ] = await this.$apiService.indexAll([

            axios.get("sistema/maestra/index/TBL_TIPOSORDENES", {
                headers: {
                    token: this.$cookies.get("token")
                }
            }),

            axios.get("sistema/maestra/index/TBL_TIPOSPROCEDIMIENTOS", {
                headers: {
                    token: this.$cookies.get("token")
                }
            }),

        ]);

        this.tipoordenes = tipoordenes.data;
        this.tipoprocedimiento = tipoprocedimiento.data;
        this.formOrdenes = await this.$apiService.index("cita/asistenciales/atender/index/" + this.$route.params.id + "/orden?tipo=AUTORIZACION").then(data => data);
        if (this.formOrdenes.length > 0) {
            for (var i = 0; i < this.formOrdenes.length; i++) {
                var r = this.formOrdenes[i];
                r.cups = {
                    search: null,
                    isLoading: false,
                    cups: [r.tblConfCups[0]]
                };
            }
            this.isNewOrden = false;
        } else {
            this.isNewOrden = true;
        }
        this.$store.commit('setCargaDatos', false);
    },
    methods: {
        async searchCup(cups) {
            cups.isLoading = true;
            if (cups.search.length > 0 && cups.search.toString().replace(/ /g, "") != "") {
                this.$apiService.index("sistema/cups/buscar/" + cups.search.toUpperCase()).then(data => {
                    cups.isLoading = false;
                    cups.cups = data;

                });
            }

        },
        async agregar() {
            this.$v.formOrdenes.$touch();
            this.loading = true;
            if (!this.$v.formOrdenes.$error) {
                try {
                    if (this.isNewOrden) {
                            let data = await this.$apiService.create("cita/asistenciales/atender/create/orden" + "?tipo=AUTORIZACION", this.formOrdenes).then(data => data);
                            if (data) {
                                this.$swal.fire({
                                    title: "Completado!",
                                    text: "Registro exitoso",
                                    icon: "success",
                                    confirmButtonText: "Ok"
                                });
                                this.$router.push({ name: 'Laboratoriopanel',params: { id: this.$route.params.id} });
                            }
                    } else {
                            let data = await this.$apiService.update("cita/asistenciales/atender/update/orden/" + this.$route.params.id + "?tipo=AUTORIZACION", this.formOrdenes).then(data => data);
                            if (data) {

                                this.$swal.fire({
                                    title: "Completado!",
                                    text: "Registro exitoso",
                                    icon: "success",
                                    confirmButtonText: "Ok"
                                });
                                this.$router.push({ name: 'Laboratoriopanel',params: { id: this.$route.params.id} });
                            }
                    }
                } catch (error) {
                    this.loading = false;
                    this.$swal.fire({
                        title: "Algo sucedio!",
                        text: "contacta con el administrador",
                        icon: "error"
                    });
                }
            }
            this.loading = false;
        },
        agregarOrden() {
            this.formOrdenes.push({
                ciorTipoOrden: this.tipoordenes.find(e => e.comaNombrecorto == 'TP_ORD_LAB').comaId,
                ciorTipoProcedimiento: this.tipoprocedimiento.find(e => e.comaNombrecorto == 'AUTORIZACION').comaId,
                ciorObservacion: null,
                cifaId: this.$route.params.id,
                cocuId: null,
                cups: {
                    search: null,
                    isLoading: false,
                    cups: []
                },
            });

        },
        async eliminarOrden(item) {
            let result = await this.$swal.fire({
                title: "Estas seguro?",
                text: "Esta seguro de que desea eliminar!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si, eliminar!"
            }).then(result => result.value);
            if (result) {
                this.formOrdenes = this.formOrdenes.filter(
                    e =>
                    this.formOrdenes.indexOf(e) !=
                    this.formOrdenes.indexOf(item)
                );
            }
        },
    },
}
</script>

<style>

</style>
