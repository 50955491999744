<template>
<div>
    <v-alert text prominent dense type="error" v-if="mensaje">
        {{mensaje}}
    </v-alert>
    <v-card class="pa-2 " color="grey lighten-2" outlined tile v-else>
        <v-container>
            <v-row justify="space-around">
                <v-col cols="12" sm="12" md="12" style="min-heigth:80px" v-for="(item, i) in recetarios" :key="i">
                    <v-sheet min-height="100%" width="100%">
                        <v-container fluid class="pa-0">
                            <v-row align="center" no-gutters class="text-center" style="min-height:80px">

                                <v-col cols="12" sm="12" md="5">
                                    <p>
                                        {{item.tblMediMedicamento.mediNombre}}
                                    </p>
                                </v-col>
                                <v-divider light class="mx-1" vertical></v-divider>
                                <v-col cols="12" sm="12" md="2">

                                    <p>Frecuencia de horas {{item.cireFrecuenciahoras}}</p>
                                </v-col>
                                <v-divider light class="mx-1" vertical></v-divider>
                                <v-col cols="12" sm="12" md="2">

                                    <p>Duracion en dias {{item.cireDuraciondias}} </p>
                                </v-col>
                                <v-divider light class="mx-1" vertical></v-divider>
                                <v-col cols="12" sm="12" md="2">

                                    <p>Cantidad {{item.cireCantidadprescrita}} </p>
                                </v-col>

                                <v-col cols="12" sm="12" md="12">
                                    <v-divider light class="mx-1"></v-divider>
                                    <p>{{item.cireDetalles}} </p>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</div>
</template>

<script>
export default {
    props: {
        recetarios: Array,
    },
    data() {
        return {
            mensaje: null,
        };
    },
    created: async function () {
        console.log(this.recetarios)
        if (this.recetarios.length == 0) {
            this.mensaje = "No se ah registrado ningun recetario"
        }
    },
};
</script>

<style>

</style>
