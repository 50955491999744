<template>
    <v-container lighten-5>
        <v-row>
            <v-col cols="12" sm="12" md="12">
                <span class="mx-2 secondary--text display-2 float-left">Atenciones anteriores</span>
            </v-col>
            <v-col cols="12" sm="12" md="12">
                <v-btn class="mx-2 float-right" fab small dark color="accent" @click="verFiltros=!verFiltros">
                    <v-icon dark>filter_list</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="12" sm="12" md="12" v-if="tabla">
                <v-card elevation="0" class="rounded-xl">
                    <v-data-iterator :items="lista" :page.sync="page" :loading="loadingTable" :item-key="tabla.itemKey" :items-per-page="10" single-expand hide-default-footer :sort-by="tabla.orderBy" :sort-desc="tabla.order">
                        <template v-slot:header>
                            <Dfiltros v-if="verFiltros" :onBuscar="search" v-bind:filtros="filtros" />
                            <v-toolbar dark class="mb-1">
                            </v-toolbar>
                        </template>
                        <template v-slot:default="{ items, isExpanded, expand }">
                            <v-container class="pt-0">
                                <v-row align="center" justify="center" :class="($vuetify.theme.dark)?'grey darken-4':'grey lighten-3'">
                                    <v-col v-for="item in items" :key="item.comaId" cols="12" sm="12" md="10" lg="10">
                                        <v-card elevation="0" class="rounded-xl">
                                            <v-card-title :class="`${($vuetify.breakpoint.mdAndUp)?'subtitle-1':'subtitle-2'}  font-weight-bold`">
                                                <v-row no-gutters>
                                                    <v-col cols="10" sm="10" md="10" lg="10">
                                                        <v-row no-gutters>
                                                            <template v-for="(p,i) in tabla.primarios">
                                                                <v-col v-if="p.show(item)" :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                                    {{p.titulo}} <span :class="p.class">
                                                                        {{ p.valor(item) }}
                                                                    </span>
                                                                </v-col>
                                                            </template>
                                                        </v-row>
                                                    </v-col>
                                                    <v-col cols="2" sm="2" md="2" lg="2">
                                                        <v-menu bottom left>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn icon outlined v-bind="attrs" v-on="on">
                                                                    <v-icon class="material-icons-two-tone">more_vert</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <v-list>
                                                                <template v-for="(m, i) in tabla.menu">
                                                                    <template v-if="m.show(item)">
                                                                        <v-list-item v-if="m.tipeAction=='link'" :key="i" :to="m.action(item)">
                                                                            <v-list-item-icon>
                                                                                <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                            </v-list-item-icon>
                                                                            <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                        </v-list-item>
                                                                        <v-list-item v-else :key="i" @click="m.action(item)">
                                                                            <v-list-item-icon>
                                                                                <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                            </v-list-item-icon>
                                                                            <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                        </v-list-item>
                                                                    </template>
                                                                </template>
                                                            </v-list>
                                                        </v-menu>
                                                    </v-col>
                                                </v-row>
                                            </v-card-title>
                                            <v-divider></v-divider>
                                            <v-card-text>
                                                <v-row no-gutters>
                                                    <template v-for="(p,i) in tabla.segundarios">
                                                        <v-col v-if="p.show(item)" :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                            {{p.titulo}} <span :class="p.class">
                                                                {{ p.valor(item) }}
                                                            </span>
                                                        </v-col>
                                                    </template>
                                                    <v-col cols="12" sm="12" md="12" lg="12" v-if="tabla.expandible">
                                                        <v-row>
                                                            <v-col cols="2" sm="2" md="1" lg="1">
                                                                <v-btn color="grey" icon outlined @click="() => expand(item, !isExpanded(item))">
                                                                    <v-icon v-if="!isExpanded(item)">mdi-chevron-down</v-icon>
                                                                    <v-icon v-else>mdi-chevron-up</v-icon>
                                                                </v-btn>
                                                            </v-col>
                                                            <v-col cols="10" sm="10" md="11" lg="11">
                                                                <span class="headline primary--text">{{tabla.expandibleTitle(item)}}</span>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                            <template v-if="tabla.expandible">
                                                <v-divider v-if="isExpanded(item)"></v-divider>
                                                <v-card-text v-if="isExpanded(item)">
                                                    <v-container>
                                                        <template v-for="items in tabla.expandibleItems(item) ">
                                                            <v-sheet min-height="100%" width="100%" :key="items[tabla.expandibleKey]">
                                                                <v-row no-gutters :class="`${($vuetify.theme.dark)?'grey darken-4':'grey lighten-3'} mb-5 py-2 px-2`">
                                                                    <template v-for="(p,i) in tabla.expanItem">
                                                                        <v-col :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                                            {{p.titulo}} <span :class="p.class">
                                                                                {{ p.valor(items) }}
                                                                            </span>
                                                                        </v-col>
                                                                    </template>
                                                                    <v-col cols="2" sm="2" md="2" lg="2" v-if="tabla.expanMenu.length > 0">
                                                                        <v-menu bottom left>
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon outlined v-bind="attrs" v-on="on">
                                                                                    <v-icon class="material-icons-two-tone">more_vert</v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <v-list>
                                                                                <template v-for="(m, i) in tabla.expanMenu">
                                                                                    <template v-if="m.show(items)">
                                                                                        <v-list-item v-if="m.tipeAction=='link'" :key="i" :to="m.action(items)">
                                                                                            <v-list-item-icon>
                                                                                                <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                                            </v-list-item-icon>
                                                                                            <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                                        </v-list-item>
                                                                                        <v-list-item v-else :key="i" @click="m.action(items)">
                                                                                            <v-list-item-icon>
                                                                                                <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                                            </v-list-item-icon>
                                                                                            <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                                        </v-list-item>
                                                                                    </template>
                                                                                </template>
                                                                            </v-list>
                                                                        </v-menu>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-sheet>
                                                        </template>
                                                    </v-container>
                                                </v-card-text>
                                            </template>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                        <template v-slot:no-data>
                            <v-row class="mt-2" align="center" justify="center">
                                <v-col cols="10" sm="10" md="10" lg="10">
                                    <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                                        No se encontro nada.
                                    </v-alert>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:loading>
                            <v-row class="mt-2" align="center" justify="center">
                                <v-col cols="12" sm="12" md="12" lg="12">
                                    <Loader />
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:footer>
                            <Paginacion v-on:cambiarPagina="page = $event" :onSearch="search" v-bind:propiedades="{filtros,page,pageCount,Count}" />
                        </template>
                    </v-data-iterator>
                </v-card>
            </v-col>
            <v-dialog v-model="dialog2" width="1200px" max-width="1200px">
                <v-card>
                    <v-card-title class="text-h5 primary">HISTORIA</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <template v-if="pdfObj">
                                        <PdfHistoria v-bind.sync="pdfObj" />
                                    </template>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="dialog2 = false"> Cerrar </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>
<script>
import generales from '../mixins/generales.js';
export default {
  components: {
    PdfHistoria: () => import("./PdfHistoria"),
  },
  props:{
    coppId:Number,
    coseId:Number,
  },
  mixins: [generales],
  data() {
    return {
      loadingCita: false,
      nuevo: false,
      menu: false,
      LoadingExp: false,
      tipo: this.$cookies.get("DEFAULT"),
      loading: false,
      dialog2: false,
      dialog3: false,
      pdfObj: null,
      ingreso: null,
      pdfh: null,
      isNew: true,
      copeId: null,
      isLoading: false,
      cambio: null,
      searchP: null,
      url: "cita/citas/",
      empleados: [],
      pacientes: [],
      servicios: [],
      servicios2: [],
      tipojornada: [],
      tipoturno: [],
      tipoestado: [],
      tiposala: [],
      dispo: [],
      tipohoras: [],
      paciente: null,
      tabla: {
        itemKey: 'citaId',
        orderBy: 'citaFecha',
        order:true,
        expandible: false,
        expandibleKey: 'citaId',
        expandibleTitle: () => {
          return ``
        },
        expandibleItems: () => {
          return []
        },
        primarios: [{
            titulo: 'PACIENTE',
            tamaño: {
              cols: '12',
              sm: '12',
              md: '12',
              lg: '12'
            },
            class: 'primary--text',
            valor: (item) => {
              return `${item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnNombre1} ${item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido1}`
            },
            show: (item) => {
              return item ? true : false
            }
          }, {
            titulo: 'FECHA',
            tamaño: {
              cols: '12',
              sm: '12',
              md: '4',
              lg: '4'
            },
            class: 'green--text',
            valor: (item) => {
              return item.citaFecha
            },
            show: (item) => {
              return item ? true : false
            }
          },
          {
            titulo: 'IDENT.',
            tamaño: {
              cols: '12',
              sm: '12',
              md: '4',
              lg: '4'
            },
            class: 'primary--text',
            valor: (item) => {
              return item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion
            },
            show: (item) => {
              return item ? true : false
            }
          },
          {
            titulo: 'EDAD',
            tamaño: {
              cols: '12',
              sm: '12',
              md: '4',
              lg: '4'
            },
            class: 'primary--text',
            valor: (item) => {
              return this.calcularEdad(item.tblConfPersonaspacientes[0].coppFechanacimiento)
            },
            show: (item) => {
              return item ? true : false
            }
          },
          {
            titulo: 'TELEFONO',
            tamaño: {
              cols: '12',
              sm: '12',
              md: '4',
              lg: '4'
            },
            class: 'primary--text',
            valor: (item) => {
              return item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeTelefono
            },
            show: (item) => {
              return item ? true : false
            }
          },

        ],
        segundarios: [{
            titulo: 'EPS',
            tamaño: {
              cols: '12',
              sm: '12',
              md: '6',
              lg: '6'
            },
            class: 'primary--text',
            valor: (item) => {
              if (item.tblConfBasedatospacientes.length > 0) {
                return (item.tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemPrincipal == 1) ? 'PARTICULAR' : item.tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemNombre
              } else if (item.cocoId) {
                return (item.tblConfContrato.tblConfPersonasempresas[0].coemPrincipal == 1) ? 'PARTICULAR' : item.tblConfContrato.tblConfPersonasempresas[0].coemNombre
              }
            },
            show: (item) => {
              return item ? true : false
            }
          },
          {
            titulo: 'EMPLEADO',
            tamaño: {
              cols: '12',
              sm: '12',
              md: '6',
              lg: '6'
            },
            class: 'primary--text',
            valor: (item) => {
              return `${item.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnNombre1 } ${item.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnApellido1 } - ${item.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion}`
            },
            show: (item) => {
              return item ? true : false
            }
          },
          {
            titulo: 'SERVICIO',
            tamaño: {
              cols: '12',
              sm: '12',
              md: '6',
              lg: '6'
            },
            class: 'primary--text',
            valor: (item) => {
              return item.tblConfContratosxservicios[0].tblConfServicios[0].coseNombre
            },
            show: (item) => {
              return item ? true : false
            }
          }
        ],
        expanItem: [],
        expanMenu: [],
        menu: [{
            title: 'Historia',
            icon: 'mdi-file-pdf-box',
            color: 'orange',
            tipeAction: 'funcion',
            action: (item) => {
              return this.pdf(item)
            },
            show: (item) => {
              return item ? (item.estado.comaNombrecorto == 'ASISTIDA') : false
            }
          },
        ]
      },
      filtros: [{
          campo: "citaFecha",
          text: "Fecha",
          value: null,
          tipo: "fecha",
          menu: false,
          searchNormal: true,
          isLoading: false,
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
          listatext: "nombre del campo que se mostrara",
          listavalue: "nombre del valor que se mandara"*/
        },
        {
          campo: "coprId",
          text: "Empleado",
          value: null,
          tipo: "lista",
          /*si es tipo lista descomentar esta parte*/
          searchNormal: true,
          isLoading: false,
          lista: [],
          listatext: "tblConfPersonasnaturales[0].copnNombre1",
          listavalue: "tblConfPersonasnaturales[0].tblConfPersonasempleados[0].coprId",
          filter: (item, queryText) => (item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + item.tblConfPersonasnaturales[0].copnApellido2).indexOf(queryText.toUpperCase()) > -1 || item.copeIdentificacion.indexOf(queryText) > -1,
          default: true,
          defaultselect: (data) => {
            return data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2
          },
          defaultbody: (data) => {
            return {
              title: data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2,
              subtitle: data.item.copeIdentificacion
            }
          },
        }, {
          campo: "coseId",
          text: "Servicio",
          value: null,
          tipo: "lista",
          /*si es tipo lista descomentar esta parte*/
          lista: [],
          listatext: "coseNombre",
          listavalue: "coseId",
        },
        {
          campo: "citaFecharango",
          text: "Rango de fecha",
          value: [],
          tipo: "rango",
          menu: false,
          searchNormal: true,
          isLoading: false,
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
          listatext: "nombre del campo que se mostrara",
          listavalue: "nombre del valor que se mandara"*/
        },
      ],
      secciones: [],
      campos: [],
      Historia: [],
      historial: [],
      diagnosticos: [],
      ordenes: [],
      recetarios: [],
      remisiones: [],
      contratos: [],
      empresas: [],
      dientesplaca: [],
      primerOdontograma: [],
      dientesodontograma: [],
      historiaData: null,
      loadingEmail: false

    };
  },

  created: async function() {
    this.$store.commit('setCargaDatos', true);
    this.tipoestado = await this.$apiService.index("sistema/maestra/index/ESTADOS_CITAS").then(data => data);
    this.filtros[1].lista = await this.$apiService.index("sistema/personas/index/empleados").then(data => data);
    this.filtros[2].lista = await this.$apiService.index("sistema/servicios/index/").then(data => data);
    this.filtros[2].value = this.coseId;
    await this.search(this.filtros);
    this.$store.commit('setCargaDatos', false);
  },

  methods: {

    async search(filtros) {
      this.lista = this.listaCopia = [];
      this.loadingTable = true;
      const data = await this.$apiService.index(`cita/citas/buscar/0?${this.filtrar(filtros,this.page)}&coppId=${this.coppId}&citaTipoEstado=${this.tipoestado.find(e => e.comaNombrecorto == 'ASISTIDA').comaId}`).then(data => data);
      if (data) {
        this.lista = this.listaCopia = data.rows;
        this.Count = data.count;
        this.pageCount = Math.ceil((data.count) / 10);
      }
      this.loadingTable = false;
    },
    async dibujarH(item) {
      try {
        this.pdfObj = null;
        this.$nextTick().then(() => {
          item.historico = false;
          this.pdfObj = {
            item: item
          };
        });
        console.log("entro")
        this.dialog2 = true;
      } catch (error) {
        this.loading = false;
        console.log(error)
        this.$swal.fire({
          title: "Error!",
          text: "contacte con el administrador",
          icon: "error"
        });
      }
    },
    async pdf(item) {
      if (this.getPermiso("verHistorias")) {
        await this.dibujarH(item);
      }
    },
  }
};

</script>

<style>

</style>
