<template>
<div>
    <v-tabs v-model="tab" background-color="grey darken-3 accent-4" centered dark icons-and-text>
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#tab-1">
            Asignados
            <v-icon>mdi-account-box</v-icon>
        </v-tab>
   </v-tabs>
    <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1" >
            <Procedimiento />
        </v-tab-item>
    </v-tabs-items>

</div>
</template>

<script>
import Procedimiento from "./Procedimiento";


export default {
    components: {
        Procedimiento,
    },
    data() {
        return {
            tab: "tab-1",
            apiService: null
        }
    },
    created: async function () {
        
        
        if (this.$cookies.get('user').role == 'PACIENTE') {

            if (this.$route.params.tab) {
                this.tab = this.$route.params.tab;
            }

        }

    },
}
</script>

<style>

</style>