<style lang="css" scoped>
</style>
<template>
    <div>
         <v-col cols="auto" md="12">
                    <v-data-iterator :items="lista" :page.sync="page" :items-per-page="10" hide-default-footer sort-desc sort-by="item.tblCitaFacturaciones[0].cifaFecha" class="elevation-0">
                        <template v-slot:header>
                            <Dfiltros :onBuscar="buscar" v-bind:filtros="filtros" />
                        </template>
                        <template v-slot:default="props">
                            <v-row align="center" justify="center">
                                <v-col v-for="item in props.items" :key="item.citaId" cols="12" sm="12" md="12" lg="12">
                                    <v-card>
                                        <v-card-title class="subtitle-1 font-weight-bold">
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="12" lg="12">
                                                    <v-row no-gutters>
                                                        <v-col cols="12" sm="12" md="3" lg="3">
                                                            FECHA: <span class="">
                                                                {{ item.tblCitaFacturaciones[0].cifaFecha}}
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="12" sm="12" md="9" lg="9">
                                                            SERVICIO: <span class="">
                                                                {{ item.tblConfContratosxservicios[0].tblConfServicios[0].coseNombre}}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-card-title>
                                        <v-divider></v-divider>
                                        <v-card-text>
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" :md="items.tblConfHistoriaseccione.hiseCol" :lg="items.tblConfHistoriaseccione.hiseCol" v-for="items in item.tblCitaFacturaciones[0].tblCitaHistoriasfacturaciones" :key="items.hiseId+'_factura'" >
                                                    <span class="primary--text"> {{ items.tblConfHistoriaseccione.hiseNombre }} </span>
                                                    <span class="">{{items.cihfValor}}</span>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:no-data>
                            <v-row class="mt-2" align="center" justify="center">
                                <v-col cols="10" sm="10" md="10" lg="10">
                                    <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                                        No se encontro nada.
                                    </v-alert>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:footer>
                            <v-row class="mt-2 pb-5" align="center" justify="center">
                                <span class="mr-4 grey--text">
                                    Pagina {{ page }} de {{ pageCount }}, Total resultados: {{(Count)}}
                                </span>
                                <v-pagination circle v-model="page" @input="buscar(filtros)" @next="buscar(filtros)" @previous="buscar(filtros)" :total-visible="10" :length="pageCount"></v-pagination>

                            </v-row>
                        </template>
                    </v-data-iterator>
                </v-col>
    </div>
</template>
<script>
export default {
    name: 'HistoricoSeccion',
    props:{
        hiseId: Number,
        coppId: Number,
    },
    data() {
        return {
            url: "sistema/historiasecciones/",
            user: this.$cookies.get("user"),
            token: this.$cookies.get("token"),  
            Count: 0,
            page: 1,
            pageCount: 0,
            filtros: [
                {
                    text: "Rango de fecha",
                    value: [],
                    tipo: "rango",
                    menu: false,
                    searchNormal: true,
                    isLoading: false,
                    /*si es tipo lista descomentar esta parte*/
                    /* lista: [],
                    listatext: "nombre del campo que se mostrara",
                    listavalue: "nombre del valor que se mandara"*/
                },
            ],
            lista: [],
            listaCopia: [],
        };
    },
    created: async function () { 
            this.filtros[0].value = [ new Date(new Date().setMonth((new Date().getMonth()-6))).yyyymmdd() ,new Date().yyyymmdd()];
            console.log(this.filtros[0].value);
            await this.buscar(this.filtros);
    },  
    methods: {
        async search(searchP) {
            this.$store.commit("setCargaDatos", true);
            const data = await this.$apiService.index(`${this.url}historico?${searchP}`).then(data => data);
            if (data) {
                this.lista = this.listaCopia = data.rows;
                this.Count = data.count;
                this.pageCount = Math.ceil((data.count) / 10);
            }
            this.$store.commit("setCargaDatos", false);
        },
        async buscar(filtros) {
            this.filtros = filtros;
            this.lista = this.listaCopia;
            var search = `offset=${(this.page-1)*10}&`;
            search = search + `hiseId=${this.hiseId}&coppId=${this.coppId}&`;  
            if (this.filtros[0].value != null && this.filtros[0].value.length > 1) {
                search = search + `cifaFecharango=${this.filtros[0].value[0]},${this.filtros[0].value[1]}&`;
                this.filtros[0].value = [];
            }
            if (search != "") {
                await this.search(search);
            }
        }
    }
}
</script>
